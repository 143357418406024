import get from 'lodash/get'
import { cloneDeep, keyBy } from 'lodash'
import { SortOrder } from './SortOrder'

export type Product = APIResponse.HeroProduct
export type TransformedProduct = Product & {
  sortableData: Record<string, string | number | unknown>
}

export interface ProductSorterOptions {
  by: string
  order?: SortOrder
}

export default class ProductSorter {
  private readonly products: TransformedProduct[]

  constructor(products: Product[]) {
    this.products = this.transform(products)
  }

  private transform(products: Product[]) {
    return products.map((product) => {
      const sortableScoredAttributes = keyBy(
        product.scoredAttributes,
        'attributeName'
      )
      return {
        ...product,
        sortableData: {
          ...sortableScoredAttributes,
          rating: product.rating,
          price: product.pricing.current,
          reviewCount: product.reviewCount,
        },
      }
    })
  }

  sort({ by, order = SortOrder.Asc }: ProductSorterOptions) {
    const p = cloneDeep(this.products)

    if (order === SortOrder.Desc) {
      return this.descending(p, { by })
    }

    return this.ascending(p, { by })
  }

  private ascending(products: Product[], { by }: ProductSorterOptions) {
    return products.sort((a, b) => {
      const valueA = get(a, by, Number.POSITIVE_INFINITY)
      const valueB = get(b, by, Number.POSITIVE_INFINITY)

      if (valueA < valueB) {
        return -1
      }

      if (valueA > valueB) {
        return 1
      }

      return 0
    })
  }

  private descending(products: Product[], { by }: ProductSorterOptions) {
    return products.sort((a, b) => {
      const valueA = get(a, by, Number.NEGATIVE_INFINITY)
      const valueB = get(b, by, Number.NEGATIVE_INFINITY)

      if (valueA > valueB) {
        return -1
      }

      if (valueA < valueB) {
        return 1
      }

      return 0
    })
  }
}
