import { Factory } from 'fishery'
import faker from 'faker'
import orderItemFactory from './dmeOrderItemFactory'
import memberCardFactory from './memberCardFactory'

interface OrderFactoryTransientParams {
  withOrderItems: number
}

export default Factory.define<
  APIResponse.DME.Order,
  OrderFactoryTransientParams
>(({ transientParams: { withOrderItems = 0 } }) => {
  const orderItems = Array(withOrderItems)
    .fill(undefined)
    .map(() => orderItemFactory.build())

  return {
    id: faker.random.alphaNumeric(),
    cancelledAt: null,
    createdAt: faker.date.past().toISOString(),
    customerEmail: faker.internet.email(),
    customerFirstName: faker.name.firstName(),
    deliveredAt: null,
    estimatedShippingDate: faker.date.future().toISOString(),
    awaitingFinalInsuranceCall: false,
    insuranceShippingEligibilityDays: faker.datatype.number(),
    insuranceStatus: 'valid',
    insuranceUpdatedAt: faker.date.past().toISOString(),
    prescriptionMissing: false,
    prescriptionRequired: false,
    prescriptionInvalid: false,
    prescriptionInvalidReasons: [],
    prescriptionUpdatedAt: faker.date.past().toISOString(),
    providerMissing: false,
    providerFaxMissing: false,
    providerRequired: false,
    providerUpdatedAt: faker.date.past().toISOString(),
    reference: faker.random.alphaNumeric(),
    status: 'ordered',
    shippedAt: null,
    orderItems,
    orderTotal: faker.commerce.price(),
    orderSubtotal: faker.commerce.price(),
    taxAmount: faker.commerce.price(),
    shippingAmount: faker.commerce.price(),
    memberCardFront: memberCardFactory.build(),
    memberCardBack: memberCardFactory.build(),
    disableInsuranceCardUpload: false,
    hasNikoHealthOrder: false,
  }
})
