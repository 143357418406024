import { useState, useEffect } from 'react'
import fetch from 'lib/fetch'
import Cookies from 'js-cookie'
import { apiV3BLLaunchDarklyFlagPath } from 'lib/urls'
import { isPrerenderUserAgent } from 'lib/isPrerenderUserAgent'
import useCurrentUser from '../useCurrentUser/useCurrentUser'
import { useQuery } from '@tanstack/react-query'

interface FeatureFlagOptions {
  registryId?: number | string
  /**
   * Indicates whether to log the feature flag usage by making an API request.
   * If true, an API call will be made to log the usage of the feature flag.
   * This is important for feature flags that are attached to experiments.
   * The default value is false.
   */
  logUsage?: boolean
}

// This hook can only be used within ReactQueryClientProvider
// This hook will use the babylist API as a proxy and will not use the LD client
const useFeatureFlag = (
  flagName: string,
  defaultValue: boolean | string,
  options: FeatureFlagOptions = {}
) => {
  // Registry guest app gets unmounted and remounted
  // This helps us avoid setting state on an unmounted component
  const [mounted, setMounted] = useState(true)

  const { registryId, logUsage } = options
  // When waiting for value from API (registry flags)
  const [isReady, setIsReady] = useState(Object.keys(options).length === 0)

  // Set initial flag value to null when registryId is provided
  // This will ensure developers do not use the flag value before it is fetched
  const initialFlagValue = registryId !== undefined ? null : defaultValue
  const [flagValue, setFlagValue] = useState(initialFlagValue)
  const [currentUser] = useCurrentUser()
  const blLdFlags = Cookies.get('bl_ld_flags_v2') || Cookies.get('bl_ld_flags')

  let flagsFromCurrentUser = {}
  if (currentUser?.ldFlagsJson) {
    try {
      flagsFromCurrentUser = JSON.parse(currentUser.ldFlagsJson)
    } catch (e) {
      console.error('Error parsing currentUser.ldFlagsJson' + e)
    }
  }

  const flagsFromCookie = blLdFlags ? JSON.parse(blLdFlags) : {}
  const ldFlags: { [k: string]: boolean | string } = {
    ...flagsFromCookie,
    ...flagsFromCurrentUser,
  }

  useEffect(() => {
    if (registryId !== undefined) {
      return // Registry flags will be fetched in a separate useEffect
    }
    if (typeof window === 'undefined' || isPrerenderUserAgent()) {
      setFlagValue(defaultValue)
      return
    }

    const value = ldFlags.hasOwnProperty(flagName)
      ? ldFlags[flagName]
      : defaultValue

    setFlagValue(value)
  }, [flagName, defaultValue, currentUser])

  // Calling the proxy API to register the flag usage
  // We don't need to wait for the response
  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      isPrerenderUserAgent() ||
      !ldFlags.hasOwnProperty(flagName) ||
      (!currentUser && !blLdFlags) ||
      registryId !== undefined || // Registry flags do not need to make this proxy API call
      !logUsage
    ) {
      return
    }

    const path = `${apiV3BLLaunchDarklyFlagPath}?flag_name=${flagName}`
    fetch(path, { method: 'GET' })
  }, [flagName, currentUser])

  // When registry ID is provided, we need to fetch the flag value from the API
  const path = `${apiV3BLLaunchDarklyFlagPath}?flag_name=${flagName}&registry_id=${registryId}`
  const result = useQuery({
    queryKey: [`getFeatureFlag-${flagName}`, registryId],
    queryFn: () => fetch(path, { method: 'GET' }),
    enabled: !!registryId,
    retry: false,
  })

  // This effect will set the flag value when the API call is successful
  useEffect(() => {
    if (!mounted || result.isLoading) {
      return
    }
    setIsReady(true)
    if (result.isError) {
      console.error('Error fetching feature flag', result.error)
      setFlagValue(defaultValue)
      return
    }
    setFlagValue(result.data.flagValue)
  }, [result.isLoading])

  // This effect is to handle the unmounting of the SSR component
  useEffect(() => {
    return () => {
      // Called when the component is unmounted
      setMounted(false)
    }
  }, [])

  return { flagValue, isReady }
}

export default useFeatureFlag
