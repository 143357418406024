import * as React from 'react'

type DialogType = 'aob' | 'terms'
const otpRequiredActionType = 'SET_OTP_REQUIRED'
const completedEmailVerificationActionType = 'COMPLETED_EMAIL_VERIFICATION'

enum DialogStatus {
  Close = 'CLOSE',
  Open = 'OPEN',
  ToggleAOB = 'TOGGLE_AOB',
  ToggleTermsAndConditions = 'TOGGLE_TERMS_AND_CONDITIONS',
}

type HookAction =
  | { type: DialogStatus.Close }
  | { type: DialogStatus.Open; dialog: DialogType }
  | {
      type: DialogStatus.ToggleTermsAndConditions | DialogStatus.ToggleAOB
      agreed: boolean
    }
  | {
      type: typeof otpRequiredActionType
      value: boolean
    }
  | {
      type: typeof completedEmailVerificationActionType
    }

interface HookState {
  agreedToAOB: boolean
  agreedToTermsAndConditions: boolean
  dialogOpen: string | null
  otpRequired: boolean
  completedEmailVerification: boolean
}

function reducer(state: HookState, action: HookAction) {
  switch (action.type) {
    case DialogStatus.Close:
      return { ...state, dialogOpen: null }
    case DialogStatus.Open:
      return { ...state, dialogOpen: action.dialog }
    case DialogStatus.ToggleTermsAndConditions:
      return {
        ...state,
        agreedToTermsAndConditions: action.agreed,
        dialogOpen: null,
      }
    case DialogStatus.ToggleAOB:
      return { ...state, agreedToAOB: action.agreed, dialogOpen: null }
    case otpRequiredActionType:
      return { ...state, otpRequired: action.value }
    case completedEmailVerificationActionType:
      return { ...state, completedEmailVerification: true }
    default:
      return {
        ...state,
        agreedToAOB: false,
        agreedToTermsAndConditions: false,
        dialogOpen: null,
      }
  }
}

const initialState = {
  agreedToAOB: false,
  agreedToTermsAndConditions: false,
  dialogOpen: null,
  otpRequired: false,
  completedEmailVerification: false,
}

export default function useCustomerInformationForm() {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  function openDialog(name: 'aob' | 'terms') {
    dispatch({ type: DialogStatus.Open, dialog: name })
  }

  function handleTermsAndConditionsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    dispatch({
      type: DialogStatus.ToggleTermsAndConditions,
      agreed: event.target.checked,
    })
  }

  function handleAOBAcknowledgmentChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    dispatch({ type: DialogStatus.ToggleAOB, agreed: event.target.checked })
  }

  function onAgreeToTermsAndConditions() {
    dispatch({ type: DialogStatus.ToggleTermsAndConditions, agreed: true })
  }

  function onDisagreeToTermsAndConditions() {
    dispatch({ type: DialogStatus.ToggleTermsAndConditions, agreed: false })
  }

  function onAgreeToAOB() {
    dispatch({ type: DialogStatus.ToggleAOB, agreed: true })
  }

  function onDisagreeToAOB() {
    dispatch({ type: DialogStatus.ToggleAOB, agreed: false })
  }

  function setOTPRequired(value: boolean) {
    dispatch({ type: otpRequiredActionType, value })
  }

  function setEmailVerificationCompleted() {
    dispatch({ type: completedEmailVerificationActionType })
  }

  return {
    agreedToAOB: state.agreedToAOB,
    agreedToTermsAndConditions: state.agreedToTermsAndConditions,
    handleAOBAcknowledgmentChange,
    handleTermsAndConditionsChange,
    onAgreeToAOB,
    onAgreeToTermsAndConditions,
    onDisagreeToAOB,
    onDisagreeToTermsAndConditions,
    openDialog,
    showAOBDialog: state.dialogOpen === 'aob',
    showTermsAndConditionsDialog: state.dialogOpen === 'terms',
    submitAllowed: state.agreedToTermsAndConditions && state.agreedToAOB,
    setOTPRequired,
    otpRequired: state.otpRequired,
    completedEmailVerification: state.completedEmailVerification,
    setEmailVerificationCompleted,
  }
}
