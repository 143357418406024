import useStyles from './styles'
import { Link, Typography } from '@material-ui/core'
import { HEALTH_CONTACT_EMAIL } from 'bl-health/constants'

interface SimpleOrderBlockerProps {
  title: string
  content: string
}

export const SimpleOrderBlocker = ({
  title,
  content,
}: SimpleOrderBlockerProps) => {
  const classes = useStyles()
  return (
    <>
      <Typography
        className={classes.title}
        color="primary"
        component="h1"
        variant="h4"
      >
        {title}
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        {content}
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        Still have questions? Email{' '}
        <Link
          className={classes.emailLink}
          href={`mailto:${HEALTH_CONTACT_EMAIL}`}
        >
          {HEALTH_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  )
}
