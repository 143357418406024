import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Typography,
} from '@material-ui/core'
import { FilterItem, FilterTrigger } from 'baby-ui/types'
import * as React from 'react'
import { isCurrentFilterItemDisabled } from 'baby-ui/utils/isCurrentFilterItemDisabled'
import { FilterOperation } from 'baby-ui/lib/FilterOperation'
import { createControlledFilterItemsState } from 'baby-ui/utils/createControlledFilterItemsState'
import classNames from 'classnames'
import useStyles from './FilterMenu.styles'

export interface FilterMenuProps extends FilterTrigger {
  description: string
  children: React.ReactNode
}

const FilterMenu = ({
  indexName,
  filters,
  allIndexes,
  initialProducts,
  onCheck,
  items,
  children,
  description,
  inputType = 'checkbox',
  operationType = FilterOperation.Union,
}: FilterMenuProps) => {
  const handleOnCheck = (
    item: FilterItem,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updates = createControlledFilterItemsState(items, item, inputType)
    onCheck(updates, { checkedItem: item }, e)
  }

  const isDisabled = (label: string) => {
    let disabled = false

    if (
      indexName &&
      allIndexes &&
      filters &&
      initialProducts &&
      inputType === 'checkbox'
    ) {
      disabled = isCurrentFilterItemDisabled({
        label,
        excludeIndex:
          operationType === FilterOperation.Union ? indexName : undefined,
        indexes: allIndexes,
        filters,
        products: initialProducts,
      })
    }

    return disabled
  }

  const classes = useStyles()

  return (
    <List aria-label={description} component="nav">
      <ListItem>
        <Typography>
          <b>{children}</b>
        </Typography>
      </ListItem>
      {items.map(({ name, label, checked }) => {
        const disabled = isDisabled(label)

        return (
          <ListItem key={name}>
            <ListItemText
              primary={
                <Typography
                  className={classNames({
                    [classes.labelTextDisabled]: disabled,
                  })}
                  component="label"
                  htmlFor={name}
                  style={{ display: 'inline-block', width: '100%' }}
                >
                  {label}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              {inputType === 'checkbox' ? (
                <Checkbox
                  checked={checked}
                  color="primary"
                  disabled={disabled}
                  edge="end"
                  id={name}
                  inputProps={{ 'aria-labelledby': name }}
                  name={name}
                  onChange={(e, isChecked) => {
                    handleOnCheck({ name, label, checked: isChecked }, e)
                  }}
                />
              ) : (
                <Radio
                  checked={checked}
                  color="primary"
                  edge="end"
                  id={name}
                  inputProps={{ 'aria-labelledby': name }}
                  name={name}
                  onChange={(e, isChecked) => {
                    handleOnCheck({ name, label, checked: isChecked }, e)
                  }}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  )
}

export default FilterMenu
