import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import { format } from 'date-fns'
import useStyles from './styles'
import { Line } from './Line'
import { ErrorIcon } from '../../icons'

interface StepProps {
  date: Date | null
  pending?: boolean
  extra?: React.ReactNode
  first?: boolean
  icon: React.ReactNode
  last?: boolean
  name: string
  nextStepPending?: boolean
  prevStepPending?: boolean
  id: string
}

export const Step = (props: StepProps) => {
  const { first, last, pending, nextStepPending, prevStepPending } = props
  return (
    <>
      {!first && <Line pending={pending || prevStepPending} />}
      <StepContent {...props} />
      {!last && <Line pending={pending || nextStepPending} />}
    </>
  )
}

const StepContent = ({
  icon,
  name,
  date,
  first,
  last,
  extra,
  pending,
  nextStepPending,
  prevStepPending,
  id,
}: StepProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dateFormat = isMobile ? 'MMM d' : 'MMMM d'
  const formattedDate = date ? format(date, dateFormat) : ''
  const pendingModifier = pending ? classes.pending : undefined

  return (
    <Grid item className={classes.step} data-testid={`step-${id}`}>
      <Grid
        container
        alignItems={isMobile ? 'center' : undefined}
        direction={isMobile ? 'row' : 'column'}
      >
        <Grid item>
          <Grid container direction={isMobile ? 'column' : 'row'}>
            {!isMobile && (
              <Line hidden={first} pending={pending || prevStepPending} />
            )}
            <Grid item className={classes.icon}>
              {icon}
            </Grid>
            {!isMobile && (
              <Line hidden={last} pending={pending || nextStepPending} />
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.name}>
          <Typography
            className={`${classes.nameText} ${pendingModifier}`}
            variant="body2"
          >
            {name}
          </Typography>
        </Grid>
        <Grid item className={`${classes.date} ${pendingModifier}`}>
          <Typography variant="caption">{formattedDate}</Typography>
        </Grid>
        <Grid item className={pendingModifier}>
          {extra}
        </Grid>
      </Grid>
    </Grid>
  )
}

interface TagProps {
  variant: 'success' | 'warn' | 'info'
}

export function Tag({ variant, children }: React.PropsWithChildren<TagProps>) {
  const classes = useStyles()

  if (variant === 'warn') {
    return (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <ErrorIcon className={classes.tagIcon} />
        <Typography
          className={`${classes.tag} ${classes.tagWarn}`}
          data-testid="step-tag-warn"
        >
          {children}
        </Typography>
      </Grid>
    )
  }

  if (variant === 'success') {
    return (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <CheckIcon className={classes.tagIcon} />
        <Typography className={classes.tag} data-testid="step-tag-success">
          {children}
        </Typography>
      </Grid>
    )
  }

  return (
    <Typography className={classes.tag} data-testid="step-tag-info">
      {children}
    </Typography>
  )
}
