import * as React from 'react'

export const useIsPinned = (ref: React.RefObject<HTMLElement>) => {
  const [isPinned, setIsPinned] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setIsPinned(true)
        } else {
          setIsPinned(false)
        }
      },
      {
        threshold: 1,
      }
    )

    if (ref?.current) {
      observer.observe(ref?.current)
      ref.current.style.position = 'sticky'
      ref.current.style.top = '-1px'
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isPinned
}
