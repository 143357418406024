import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormGroup,
  Typography,
} from '@material-ui/core'
import Image from 'components/image'
import LoadingButton from '../../../baby-ui/compounds/LoadingButton'
import { useDMEStore } from '../../store'
import useStyles from './styles'
import React from 'react'

interface PreCheckoutMilkBagWidgetProps {
  open: boolean
  onConfirm: () => void
  finalCheckoutLoading: boolean
  milkBagsCount?: number
  onShow: () => void
}

const PreCheckoutMilkBagWidget = ({
  open,
  onConfirm,
  finalCheckoutLoading,
  milkBagsCount,
  onShow,
}: PreCheckoutMilkBagWidgetProps) => {
  const classes = useStyles()
  const store = useDMEStore()

  React.useEffect(() => {
    if (open) {
      onShow()
    }
  }, [open])

  return (
    <Dialog
      fullScreen
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      open={open}
    >
      <div className={classes.dialogContent}>
        <div>
          <Box
            className={classes.detailBox}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              gutterBottom
              className={classes.headerText}
              color="primary"
              variant="h6"
            >
              Your insurance covers milk storage bags at no cost to you!
            </Typography>
            <br />
            <Image
              className={classes.headerImage}
              height={45}
              src="//images.babylist.com/image/upload/v1693322129/DME/check-mark.svg"
              width={60}
            />

            <br />
            <Box alignItems="flex-start" display="flex">
              <FormControl>
                <FormGroup style={{ marginTop: -4 }}>
                  <Checkbox
                    id="milkBags"
                    name="milkBags"
                    value={store.cart.includeMilkBags}
                    onChange={(_, checked) => {
                      store.cart.setIncludeMilkBags(checked)
                    }}
                  />
                </FormGroup>
              </FormControl>
              <Typography
                gutterBottom
                className={classes.subHeaderText}
                color="primary"
                style={{ textAlign: 'left' }}
                variant="h5"
              >
                Select the box to add {milkBagsCount} breast milk storage bags
                to your order.
              </Typography>
            </Box>
          </Box>
          <LoadingButton
            fullWidth
            className={classes.checkoutBttn}
            color="primary"
            loading={finalCheckoutLoading}
            variant="contained"
            onClick={onConfirm}
          >
            Continue to checkout
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  )
}

export default PreCheckoutMilkBagWidget
