import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { OTP_CREATION_ENDPOINT } from './constants'

export interface OTPCreationPayload {
  email?: string
  customerId?: number
}

const createOTP = (payload: OTPCreationPayload): Promise<unknown> =>
  fetch(OTP_CREATION_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })

export default createOTP
