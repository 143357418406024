import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      borderRadius: 16,
      border: '0.5px solid #E2D6D6',
    },
    cardContent: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(3, 3, 3, 6),
      flexGrow: 1,
      minWidth: '65%',
    },
    image: {
      [theme.breakpoints.down(840)]: {
        display: 'none',
      },
    },
    imageFormContainer: {
      width: '100%',
    },
  })
)
