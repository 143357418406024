import HeroCTAWidget from 'bl-health/widgets/HeroCTAWidget/HeroCTAWidget'
import { Box, Link, Typography } from '@material-ui/core'
import IllustratedSteps from 'baby-ui/compounds/IllustratedSteps'
import {
  THREE_STEPS_PARACHUTE_URL_IMG_URL,
  THREE_STEPS_PORTRAIT_URL_IMG_URL,
  THREE_STEPS_PUMP_IMG_URL,
} from 'bl-health/views/constants'
import EducationHeroSection from 'bl-health/components/EducationHeroSection/EducationHeroSection'
import {
  DME_REPLACEMENT_PARTS_TERMS_URL,
  PARTS_HOME_PATH,
} from 'bl-health/constants'
import useStyles from './styles'

const disclaimer = (
  <Typography color="textPrimary" variant="body2">
    *Exclusions apply.{' '}
    <Link
      href={DME_REPLACEMENT_PARTS_TERMS_URL}
      style={{ color: 'inherit' }}
      target="_blank"
      underline="always"
    >
      See details/terms
    </Link>
  </Typography>
)

export default function PumpsHomeView() {
  const classes = useStyles()

  return (
    <>
      <HeroCTAWidget />
      <EducationHeroSection
        buttonText="Select my breast pump"
        disclaimer={disclaimer}
        imageSrc="//images.babylist.com/image/upload/v1706907064/DME/dme-feeding-baby-with-gradient-shapes.png"
        title="We'll be with you every step of the way"
        infoCard={{
          content:
            "If you're already pumping, we can help you get free replacement parts through insurance.",
          linkText: 'Check your eligibility',
          link: PARTS_HOME_PATH,
          variant: 'content',
        }}
      >
        <ul>
          <Typography gutterBottom component="li">
            We take care of contacting your provider for a prescription and
            billing your insurance
          </Typography>
          <Typography gutterBottom component="li">
            We'll remind you when it's time to replace your pump parts and help
            you get them for <b>FREE</b> through insurance*
          </Typography>
          <Typography gutterBottom component="li">
            We help you make the most of your benefits so you can take advantage
            of perks like getting breast milk storage bags for <b>FREE</b>*
          </Typography>
        </ul>
      </EducationHeroSection>

      <Box alignContent="center" className={classes.illustratedStepsWrapper}>
        <IllustratedSteps
          steps={[
            {
              content: 'Provide some insurance info & select your pump',
              imageUrl: THREE_STEPS_PUMP_IMG_URL,
              alt: 'Breast pump',
            },
            {
              content:
                "We'll verify your coverage and handle all the paperwork",
              imageUrl: THREE_STEPS_PORTRAIT_URL_IMG_URL,
              alt: 'Prescription',
            },
            {
              content: 'Sit back and wait for your pump to arrive',
              imageUrl: THREE_STEPS_PARACHUTE_URL_IMG_URL,
              alt: 'Product delivery',
            },
          ]}
          subtitle="in 3 easy steps!"
          title="Get Your Breast Pump"
        />
      </Box>
    </>
  )
}
