import { Grid } from '@material-ui/core'
import TextField from 'baby-ui/foundation/TextField'
import { Control, Controller, FieldError } from 'react-hook-form'

interface FirstNameFieldProps {
  control: Control<{
    firstName: string
  }>
  error?: FieldError | null
  helperText?: string
}

// TODO: This is duplicated from CustomerInformationForm.tsx. Should we centralize it?

const FirstNameField = ({
  control,
  error,
  helperText,
}: FirstNameFieldProps) => (
  <Grid item xs={12}>
    <Controller
      fullWidth
      as={TextField}
      control={control}
      error={!!error}
      helperText={helperText}
      label="Patient's First Name"
      name="firstName"
      type="text"
    />
  </Grid>
)

export default FirstNameField
