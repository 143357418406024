import { useState, useRef, useEffect } from 'react'
import MaskedDigitTextField from 'baby-ui/foundation/MaskedDigitTextField'
import { FormHelperText, Grid } from '@material-ui/core'
import { TextFieldProps } from 'baby-ui/foundation/TextField'
import { removeAllNonDigitChar } from 'baby-ui/utils'
import DateField from './DateField'
import useStyles from './styles'

enum ErasableInputs {
  Year,
  Month,
  Day,
}

export interface DateTextFieldProps {
  day?: TextFieldProps
  month?: TextFieldProps
  year?: TextFieldProps
  onChange?: (value: string, dateField: DateField) => void
  error?: boolean
  onBlur?: () => void
  helperText?: string
  required?: boolean
  value?: string
  disabled?: boolean
}

const DateTextField = ({
  day = {},
  month = {},
  year = {},
  onChange,
  error = false,
  onBlur,
  helperText,
  required = false,
  value,
  disabled = false,
}: DateTextFieldProps) => {
  const [initialYear, initialMonth, initialDay] = value ? value.split('-') : []
  const [monthState, setMonthState] = useState('')
  const [dayState, setDayState] = useState('')
  const [yearState, setYearState] = useState('')
  const dayRef = useRef<HTMLDivElement>(null)
  const yearRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const [erasedInput, setErasedInput] = useState<ErasableInputs>()
  const normalizedInputData = {
    year: yearState || initialYear,
    month: monthState || initialMonth,
    day: dayState || initialDay,
  }

  useEffect(() => {
    const year =
      erasedInput === ErasableInputs.Year ? yearState : normalizedInputData.year
    const month =
      erasedInput === ErasableInputs.Month
        ? monthState
        : normalizedInputData.month
    const day =
      erasedInput === ErasableInputs.Day ? dayState : normalizedInputData.day
    const dateField = new DateField(year, month, day)
    onChange?.(dateField.toString(), dateField)
    setErasedInput(undefined)
  }, [yearState, monthState, dayState])

  return (
    <>
      <Grid item xs={4}>
        <MaskedDigitTextField
          {...month}
          disabled={disabled}
          error={error}
          length={2}
          required={required}
          value={normalizedInputData.month}
          onBlur={onBlur}
          onChange={(e) => {
            const value = removeAllNonDigitChar(e.target.value)

            if (value.length >= 2) {
              dayRef.current?.focus()
            }

            if (value.length < monthState.length) {
              setErasedInput(ErasableInputs.Month)
            }

            setMonthState(value)
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MaskedDigitTextField
          {...day}
          disabled={disabled}
          error={error}
          length={2}
          ref={dayRef}
          required={required}
          value={normalizedInputData.day}
          onBlur={onBlur}
          onChange={(e) => {
            const value = removeAllNonDigitChar(e.target.value)

            if (value.length >= 2) {
              yearRef.current?.focus()
            }

            if (value.length < dayState.length) {
              setErasedInput(ErasableInputs.Day)
            }

            setDayState(value)
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MaskedDigitTextField
          {...year}
          disabled={disabled}
          error={error}
          length={4}
          ref={yearRef}
          required={required}
          value={normalizedInputData.year}
          onBlur={onBlur}
          onChange={(e) => {
            const value = removeAllNonDigitChar(e.target.value)

            if (value.length < yearState.length) {
              setErasedInput(ErasableInputs.Year)
            }

            setYearState(value)
          }}
        />
      </Grid>
      {helperText && (
        <Grid item className={classes.dateErrorWrapper} xs={12}>
          <FormHelperText error>{helperText}</FormHelperText>
        </Grid>
      )}
    </>
  )
}
export default DateTextField
