import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { UPDATE_DUE_DATE_ENDPOINT } from './constants'
interface UpdateDueDatePayload {
  id: string
  dueDate: string
  customerId: number | null
}

export default function patchDueDate(
  payload: UpdateDueDatePayload
): Promise<APIResponse.DME.Order> {
  return fetch(UPDATE_DUE_DATE_ENDPOINT(payload.id), {
    method: RequestMethod.PATCH,
    body: JSON.stringify(payload),
  })
}
