import { Typography, Box } from '@material-ui/core'
import Link from 'baby-ui/foundation/Link/Link'

export interface InfoCardProps {
  content: string
  linkText: string
  link: string
  variant?: 'heading' | 'content'
}

export const DMEInfoCard = ({
  content,
  linkText,
  link,
  variant = 'heading',
}: InfoCardProps) => {
  const align = variant === 'heading' ? 'center' : 'left'

  return (
    <Box
      p={1}
      borderRadius={4}
      border="1px solid #E2D6D6"
      bgcolor="#F0EAEA"
      textAlign={align}
    >
      <Box pb={1}>
        <Typography variant="body2">{content}</Typography>
      </Box>
      <Link underline="hover" href={link} variant="chevronIcon">
        <Typography variant="body2">{linkText}</Typography>
      </Link>
    </Box>
  )
}
