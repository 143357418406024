export const formattedEstimate = (days: number): string => {
  if (days === 0) {
    return 'on'
  }

  const absDays = Math.abs(days)
  const daysStr = absDays === 1 ? 'day' : 'days'

  return `${absDays} ${daysStr} ${days < 0 ? 'after' : 'prior to'}`
}
