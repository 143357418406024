import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import ProductDetailsSection from 'baby-ui/compounds/ProductDetailsSection'
import Carousel from 'baby-ui/compounds/Carousel'
import PriceDisplay from 'baby-ui/compounds/PriceDisplay'
import { observer } from 'mobx-react-lite'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { useMutation } from '@tanstack/react-query'

import { addedToCartEvent, pdpViewedEvent } from 'bl-health/events'
import { useDMEStore } from '../../store'
import ProductReviewsWidget from './ProductReviewsWidget'
import { STORE_NAME } from '../../constants'
import { addToCart } from '../../requests'
import ShippingInfoWidget from './ShippingInfoWidget'

export interface PDPProps {
  pdpProps: APIResponse.GenericProduct[]
}

export default observer(({ pdpProps }: PDPProps) => {
  const store = useDMEStore()
  const tracker = useTracking()

  const props = pdpProps.length && pdpProps[0]
  const { activeProductId, brand, details, name, price, products } =
    props as APIResponse.GenericProduct
  const turntoSku = products[activeProductId].turntoSku!
  const { mutate: addToCartMutation, isLoading } = useMutation({
    mutationFn: addToCart,
    onSuccess: addToCartSucceeded,
    onError: addToCartFailed,
  })

  React.useEffect(() => {
    pdpViewedEvent(tracker)({
      productId: activeProductId,
      productPrice: price.current,
    })
  }, [])

  function getImageData() {
    const { images } = products[activeProductId]
    const data = images.map((image) => ({
      style: { width: '100%' },
      alt: 'product-image',
      src: image.url,
    }))
    return data.slice(0, 8)
  }

  function addToCartFailed(e: any) {
    tracker.trackEvent({
      event: track.errorThrown,
      eventLocation: track.EventLocation.NONE,
      storeName: STORE_NAME,
      error: JSON.stringify(e),
    })
    store.ui.globalComponents.Snackbar.show(e.message)
  }

  function addToCartSucceeded(response: { cart: APIResponse.Cart }) {
    store.cart.setLastItemAdded(response.cart, activeProductId)
    fireProductAddedToCartEvent()
    navigateToCustomerInformationView()
  }

  async function handleOnAddToCartClick() {
    addToCartMutation({
      cartItem: { productId: activeProductId, quantity: 1 },
    })
  }

  function fireProductAddedToCartEvent() {
    addedToCartEvent(tracker)({
      location: track.EventLocation.PDP,
      productId: activeProductId,
      price: price.current,
    })
  }

  function navigateToCustomerInformationView() {
    window.location.href = '/health/dme-customer-information'
  }

  return (
    <>
      <Container>
        <Box py={3}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Box sx={{ maxWidth: 500, margin: 'auto' }}>
                <Carousel
                  component={(props) => <img {...props} />}
                  data={getImageData()}
                  every={({ src }, i) => ({ key: `${i}:${src}` })}
                />
              </Box>
            </Grid>

            <Grid container item md={4} xs={12}>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1">
                  {brand}
                </Typography>

                <Typography component="h2" variant="h5">
                  <strong>{name}</strong>
                </Typography>

                <div id="tt-teaser-widget" />

                <Box py={3}>
                  <Box sx={{ display: 'flex' }}>
                    {store.insurance.shippingEligibilityDays === null ? (
                      <Typography gutterBottom variant="body2">
                        Your insurance allows us to ship this quickly!
                      </Typography>
                    ) : (
                      <Typography gutterBottom variant="body2">
                        {store.insurance.shippingDateMessage}
                      </Typography>
                    )}
                    <ShippingInfoWidget
                      shippingEligibilityDays={
                        store.insurance.shippingEligibilityDays
                      }
                    />
                  </Box>

                  <Typography variant="body2">
                    This item is a medical grade product and cannot be returned
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <PriceDisplay
                    label="What You Pay"
                    price={price.current}
                    variant="h5"
                  />
                </Box>

                <Box py={2}>
                  <LoadingButton
                    fullWidth
                    color="secondary"
                    loading={isLoading}
                    size="large"
                    variant="contained"
                    onClick={handleOnAddToCartClick}
                  >
                    Add to cart
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box py={4} sx={{ bgcolor: 'background.contrast' }}>
        <Container>
          <ProductDetailsSection
            about={details.summary}
            description={details.description}
            specs={details.specs}
            whatWeLove={details.highlight}
          />

          <Container maxWidth="md">
            <div id="product-reviews">
              <ProductReviewsWidget sku={turntoSku} />
            </div>
          </Container>
        </Container>
      </Box>
    </>
  )
})
