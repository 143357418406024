import AlertDialog from 'baby-ui/compounds/AlertDialog'
import PhysicianPhoneNumberForm from './PhysicianPhoneNumberForm'

interface PhysicianPhoneNumberModal {
  open: boolean
  nextAction: () => void
  onClose: () => void
}

const PhysicianPhoneNumberModal = ({
  open,
  nextAction,
  onClose,
}: PhysicianPhoneNumberModal) => (
  <AlertDialog open={open} title={"You're almost there!"} onClose={onClose}>
    <PhysicianPhoneNumberForm nextAction={nextAction} />
  </AlertDialog>
)

export default PhysicianPhoneNumberModal
