export const createProductFilterMatrix = (...vectors: string[][]) => {
  const matrix: string[][] = []
  vectors.forEach((vector) => {
    if (vector && vector.length > 0) {
      matrix.push(vector)
    }
  })

  return matrix
}
