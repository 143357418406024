import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    standardInfo: {
      background: '#f8e9dd',
      color: theme.palette.primary.main,
      '&:hover path, path': {
        fill: theme.palette.primary.main,
      },
    },
  })
)
