import React from 'react'
import { Card } from '../components'
import {
  Delivered,
  PreShipNotice,
  Shipped,
} from 'bl-health/views/OrderView/cards/index'
interface ShippingCardProps {
  order: APIResponse.DME.Order
}

const ShippingCard: React.FC<ShippingCardProps> = ({
  order,
}: ShippingCardProps) => {
  const {
    hasNikoHealthOrder,
    insuranceStatus,
    prescriptionRequired,
    prescriptionMissing,
    prescriptionInvalid,
    providerRequired,
    providerMissing,
    status,
  } = order

  const shippingCardContent = () => {
    if (status === 'shipped') {
      return <Shipped />
    } else if (status === 'delivered') {
      return <Delivered />
    } else if (
      insuranceStatus === 'valid' && // valid insurance
      hasNikoHealthOrder && // the order exists in niko
      !(prescriptionRequired && prescriptionMissing) && // rx is not required or not missing
      !(prescriptionRequired && prescriptionInvalid) && // rx is not required or not invalid
      !(providerRequired && providerMissing) // provider is not required or requirement is satisfied
    ) {
      return <PreShipNotice />
    } else {
      return null
    }
  }

  if (shippingCardContent() === null) return null

  return <Card testId="shipping-card">{shippingCardContent()}</Card>
}

export default ShippingCard
