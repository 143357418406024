import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '1.85em',
      lineHeight: '1.33',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING / 2),
      fontWeight: 500,
    },
    submitButton: {
      marginTop: theme.spacing(ThemeConstants.BASE_SPACING),
    },
  })
)
