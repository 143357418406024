import {
  Box,
  Container,
  Link,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from 'baby-ui/foundation/Button/Button'
import { ReactElement } from 'react'
import * as React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useIsPinned } from 'baby-ui/hooks/useIsPinned'
import classNames from 'classnames'
import CountIndicatorBadge from 'baby-ui/foundation/CountIndicatorBadge/CountIndicatorBadge'
import useStyles from './FilterBar.styles'
import { createClearFilterDisplayText } from './FilterBar.utils'

export interface FilterBarProps {
  children: React.ReactNode
  clearableCount: number
  onClear: () => void
  onSmallScreenClick: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  end?: ReactElement
  currentFilterLabel: string
}
const FilterBar = ({
  onClear,
  children,
  clearableCount,
  onSmallScreenClick,
  end,
  currentFilterLabel,
}: FilterBarProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const containerRef = React.useRef<HTMLDivElement>(null)
  const isPinned = useIsPinned(containerRef)

  return (
    <>
      {/* Applying a padding on top of Container using this div so that it will disappear when the Container gets pinned to its sticky position */}
      <Box mt={2} />
      <Container
        className={classNames(classes.root, {
          [classes.stylesWhenPinned]: isPinned,
        })}
        ref={containerRef}
      >
        {isSmallScreen ? (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Button
              color="primary"
              variant={clearableCount > 0 ? 'contained' : 'outlined'}
              onClick={onSmallScreenClick}
            >
              {createClearFilterDisplayText('Filter', clearableCount)}
            </Button>

            <Button
              color="primary"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={onSmallScreenClick}
            >
              {currentFilterLabel}
            </Button>
          </Box>
        ) : (
          <>
            {children}
            {clearableCount > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                aria-label="Clear all filters"
                component="button"
                variant="body2"
                onClick={onClear}
              >
                Clear filters{' '}
                <CountIndicatorBadge>{clearableCount}</CountIndicatorBadge>
              </Link>
            )}
            {end && <Box marginLeft="auto">{end}</Box>}
          </>
        )}
      </Container>
    </>
  )
}

export default FilterBar
