import get from 'lodash/get'
import { Error } from '../../utils/apiErrorResponseAdapter'

export type FormError = {
  message?: string | undefined
  dueDate?: string | undefined
}

const getErrorMessage = (error: Error): string | undefined => {
  const message = get(error, 'error.message')
  const title = get(error, 'error.title')
  const altTitle = get(error, 'errors[0].title')

  return message || title || altTitle
}

export default function parseApiErrorResponse(error: Error): FormError {
  const dueDate = get(error, 'error.fields.dueDate[0]')

  return {
    dueDate: dueDate ? `Due day ${dueDate}` : undefined,
    message: getErrorMessage(error),
  }
}
