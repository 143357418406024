import { useState } from 'react'
import { Collapse, Grid, Typography } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import useStyles from './styles'

const PLPBannerWidget = () => {
  const classes = useStyles()
  const [showHowItWorks, setShowHowItWorks] = useState(false)

  const toggleHowItWorks = () => {
    setShowHowItWorks(!showHowItWorks)
  }

  return (
    <div className={classes.bannerContainer}>
      <Typography className={classes.heading} component="h2" variant="h4">
        Let's find you the perfect pump!
      </Typography>
      <div className={classes.heart}>
        <FavoriteIcon color="secondary" fontSize="inherit" />
      </div>
      <Typography className={classes.subText}>
        Before you check out, we will verify your insurance plan and make sure
        you are good to go.
      </Typography>
      <div className={classes.dropdownContainer} onClick={toggleHowItWorks}>
        <Typography className={classes.dropdownPrompt}>
          Still not sure how this works?
        </Typography>
        {showHowItWorks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      <Collapse in={showHowItWorks}>
        <div className={classes.howItWorksContainer}>
          <Grid container className={classes.howItWorksGrid} spacing={4}>
            <Grid item className={classes.howItWorksBox} xs={4}>
              <Typography className={classes.howItWorksText}>
                The price you see is the price you pay
              </Typography>
            </Grid>
            <Grid item className={classes.howItWorksBox} xs={4}>
              <Typography className={classes.howItWorksText}>
                Insurance covers the rest of the cost
              </Typography>
            </Grid>
            <Grid item className={classes.howItWorksBox} xs={4}>
              <Typography className={classes.howItWorksText}>
                All you have to do is select your pump
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Collapse>
    </div>
  )
}

export default PLPBannerWidget
