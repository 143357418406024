import * as React from 'react'
import { Typography } from '@material-ui/core'
import classnames from 'classnames'
import useStyles from './styles'

export interface PhysicianCardProps {
  addressLine1: string
  addressLine2: string
  className?: string
  component?: React.ElementType
  name: string
  isDefault?: boolean
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function PhysicianCard({
  addressLine1,
  addressLine2,
  className,
  component,
  name,
  isDefault,
  onClick,
  ...rest
}: PhysicianCardProps) {
  const classes = useStyles()
  const C = component || 'div'

  return (
    <C
      {...rest}
      className={classnames(classes.doctorCardContainer, className)}
      onClick={onClick}
    >
      <Typography
        gutterBottom
        className={isDefault ? classes.defaultDoctorName : classes.doctorName}
        color="inherit"
        component="p"
        variant="body2"
      >
        {name}
      </Typography>
      <Typography color="inherit" component="p" variant="body2">
        {addressLine1}
      </Typography>
      <Typography color="inherit" component="p" variant="body2">
        {addressLine2}
      </Typography>
    </C>
  )
}
