import AlertDialog from 'baby-ui/compounds/AlertDialog'
import PhysicianDetailsForm from './PhysicianDetailsForm'

interface PhysicianDetailsModalProps {
  open: boolean
  nextAction: () => void
  onClose: () => void
}

const PhysicianDetailsModal = ({
  open,
  nextAction,
  onClose,
}: PhysicianDetailsModalProps) => (
  <AlertDialog open={open} title="You're almost there!" onClose={onClose}>
    <PhysicianDetailsForm nextAction={nextAction} />
  </AlertDialog>
)

export default PhysicianDetailsModal
