import { InvalidPartsOrderCopyProps } from './types'
import { SimpleOrderBlocker } from './SimpleOrderBlocker'

export const InsuranceDisabledCopy = (_props: InvalidPartsOrderCopyProps) => {
  return (
    <SimpleOrderBlocker
      title="We're working hard to support your insurance for the replacement parts
        program, but we're not quite there yet."
      content="It's likely your insurance covers replacement parts! Please contact them
        to discuss your options."
    />
  )
}
