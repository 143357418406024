import { Box, Typography } from '@material-ui/core'
import Rating from 'baby-ui/foundation/Rating'

export interface RatedReviewsProps {
  /**
   * The product rating
   */
  rating?: number
  /**
   * The number of reviews on the product
   */
  reviewCount?: number
}

export default function RatedReviews({
  rating,
  reviewCount,
}: RatedReviewsProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Rating value={rating} />
      <Typography color="textSecondary" component="span" variant="caption">
        &nbsp;{reviewCount || 0}
      </Typography>
    </Box>
  )
}
