import { Link } from '@material-ui/core'
import { PRESCRIPTION_FORM } from '../../constants'
import { ContentBlock, ContactLink } from '../components'

const PrescriptionFaxing = () => (
  <ContentBlock title="Rx next steps" variant="warn">
    We are faxing your provider to request a prescription for your breast pump
    order. To help speed up the process, you can bring our{' '}
    <Link download href={PRESCRIPTION_FORM} style={{ fontWeight: 'bold' }}>
      prescription form
    </Link>{' '}
    to an upcoming appointment. You can send the completed form to{' '}
    <ContactLink variant="rx" />.
  </ContentBlock>
)

export default PrescriptionFaxing
