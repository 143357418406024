import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import useStyles from './styles'
import { UI } from '../../../global'
import { ORDER_PATH } from '../../constants'
import Header from './Header'
import TitleBar from './TitleBar'
import Footer from './Footer'
import {
  CancelledCard,
  HelpCard,
  OtherPumpCard,
  ShippingInfoCard,
  ShippingUpdatesCard,
  PrescriptionNextStepsCard,
  ShippingCard,
  PrescriptionInvalidCard,
  ProviderMissingCard,
  InsuranceUploadCard,
  UpdateDueDateCard,
  OrderProgressCard,
} from './cards'
import { OrderSummary } from './OrderSummary'
import { OrderSummaryBar } from './OrderSummaryBar'
import { configureView } from '../../utils/ViewConfiguration'

export interface OrderViewProps extends UI.LifeCycle {
  order: APIResponse.DME.Order
}

const OrderView: UI.ViewComponent<OrderViewProps> = observer(
  ({ order: initialOrder, onComponentDidMount }: OrderViewProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [order, setOrder] = useState<APIResponse.DME.Order>(initialOrder)

    const onSuccessfulOrderUpdate = useCallback(
      (_order: APIResponse.DME.Order) => {
        setOrder(_order)
      },
      [setOrder]
    )

    useEffect(() => {
      onComponentDidMount()
    }, [])

    const cancelled = order.status === 'cancelled'
    const shipped = order.status === 'shipped'
    const delivered = order.status === 'delivered'
    const ordered = order.status === 'ordered'

    return (
      <div className={classes.root}>
        <Grid container direction="row" style={{ flexGrow: 1 }}>
          <Grid container className={classes.orderStatus} direction="column">
            <Header />
            {isMobile && <OrderSummaryBar order={order} />}
            <Grid container direction="column" style={{ flex: 1 }}>
              <Box mx={{ xs: 2, md: 0 }}>
                <TitleBar
                  cancelled={cancelled}
                  customerFirstName={order.customerFirstName}
                  reference={order.reference}
                />
                <Box ml={{ xs: 0, md: 8 }}>
                  {cancelled ? (
                    <>
                      <CancelledCard cancelledAt={order.cancelledAt} />
                      <OtherPumpCard />
                    </>
                  ) : (
                    <>
                      <OrderProgressCard order={order} />
                      <InsuranceUploadCard order={order} />
                      <ProviderMissingCard order={order} />
                      <PrescriptionInvalidCard order={order} />
                      <PrescriptionNextStepsCard order={order} />
                      <ShippingCard order={order} />
                      {shipped || delivered ? (
                        <ShippingUpdatesCard />
                      ) : (
                        <ShippingInfoCard
                          daysFromDueDate={
                            order.insuranceShippingEligibilityDays
                          }
                        />
                      )}
                      {ordered && (
                        <UpdateDueDateCard
                          email={order.customerEmail}
                          orderId={order.id}
                          onSuccess={onSuccessfulOrderUpdate}
                        />
                      )}
                      <HelpCard />
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Footer />
          </Grid>
          {!isMobile && (
            <Grid item className={classes.orderDetails}>
              <OrderSummary
                orderItems={order.orderItems}
                shippingAmount={order.shippingAmount}
                subtotal={order.orderSubtotal}
                taxAmount={order.taxAmount}
                total={order.orderTotal}
              />
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
)

export default configureView(OrderView)({ pathname: ORDER_PATH })
