import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    checkedItem: {
      backgroundColor: theme.palette.background.contrast,
    },
    disabledItem: {
      '&:hover': {
        background: 'none',
      },
    },
  })
)
