import { Box, Chip } from '@material-ui/core'
import useStyles from './EnhancedProductCard.styles'

const INCLUDED_FEATURES = ['Wearable', 'Cordless', 'App']

interface FeatureProps {
  features: string[]
}

export const Features = ({ features }: FeatureProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.featureContainer}>
      {features
        .filter((feature) => INCLUDED_FEATURES.includes(feature))
        .map((feature) => (
          <Chip
            className={classes.feature}
            id={feature}
            label={feature}
            size="small"
            key={feature}
          />
        ))}
    </Box>
  )
}

export default Features
