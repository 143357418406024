import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    verticalMargin: { margin: theme.spacing(ThemeConstants.BASE_SPACING, 0) },
    textCenter: { textAlign: 'center' },
    termAndConditionsCopyWrapper: {
      fontSize: theme.typography.pxToRem(14),
      display: 'flex',
      alignContent: 'baseline',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '& button': {
          marginTop: '-5px',
        },
      },
    },
    dateErrorWrapper: {
      marginTop: -theme.spacing(ThemeConstants.BASE_SPACING),
      '& $dateErrorText': {
        marginLeft: theme.spacing(ThemeConstants.BASE_SPACING),
      },
    },
    dateErrorText: {},
  })
)
