import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Container } from '@material-ui/core'
import { useTracking } from 'lib/babylistHealthAnalytics'
import browserHistory from 'bl-health/utils/browserHistory'
import { createNameSpacedPath } from 'bl-health/utils'

import { ApplicationStrategy } from 'bl-health/types'
import { UI } from '../../../global'
import { PHYSICIAN_RESULT_STEP, SEARCH_PHYSICIAN_PATH } from '../../constants'
import { useDMEStore } from '../../store'
import PhysicianSelectorWidget from './PhysicianSelectorWidget/PhysicianSelectorWidget'
import { physicianResultViewedEvent } from '../../events'
import useStyles from './styles'
import CheckoutJourneyStepsWidget from '../../widgets/CheckoutJourneyStepsWidget'
import CheckoutJourneyWrapperWidget from '../../widgets/CheckoutJourneyWrapperWidget'

const PhysicianResultView: UI.ViewComponent = observer(({ router }) => {
  const store = useDMEStore()

  if (!store.physician.all) {
    browserHistory.push(
      createNameSpacedPath(
        SEARCH_PHYSICIAN_PATH,
        store.context?.strategy as ApplicationStrategy
      )
    )
    return null
  }

  const tracker = useTracking()

  useEffect(() => {
    window.scrollTo(0, 0)
    physicianResultViewedEvent(tracker)()
  }, [])

  const classes = useStyles()

  return (
    <Container className={classes.viewContainer}>
      <CheckoutJourneyWrapperWidget>
        <CheckoutJourneyStepsWidget currentStep={PHYSICIAN_RESULT_STEP} />
        <PhysicianSelectorWidget goToPreviousPage={router.goBack} />
      </CheckoutJourneyWrapperWidget>
    </Container>
  )
})

export default PhysicianResultView
