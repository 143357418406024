import * as yup from 'yup'
import { removeAllNonDigitChar } from 'baby-ui/utils'
import { MAXIMUM_PATIENT_AGE, MINIMUM_PATIENT_AGE } from 'shared/constants'
import { isBirthdayWithinRange } from './helper'
import { date } from '../CommonValidationSchemas/DateValidationSchema'

const maskedPhoneNumberRegex = /^\([1-9]\d{2}\) \d{3}-\d{4}$/
const unmaskedPhoneNumberRegex = /^\d{10,11}$/

export const CustomerInformationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email address.'),
  phoneNumber: yup.string().test({
    name: 'valid-phone-number',
    test(phoneNumber, ctx) {
      if (
        unmaskedPhoneNumberRegex.test(phoneNumber || '') ||
        maskedPhoneNumberRegex.test(phoneNumber || '')
      )
        return true
      return ctx.createError({
        message: 'Enter a valid phone number.',
      })
    },
  }),
  birthDate: date.test({
    name: 'is-birth-date-in-range',
    test(value, ctx) {
      if (!removeAllNonDigitChar(value || '')) {
        return ctx.createError({ message: 'Date of birth is required' })
      }
      if (!isBirthdayWithinRange(value || '')) {
        return ctx.createError({
          message: `Must be between ${MINIMUM_PATIENT_AGE} and ${MAXIMUM_PATIENT_AGE} years ago`,
        })
      }

      return true
    },
  }),
  dueDate: date.test({
    name: 'is-due-date-valid',
    test(value, ctx) {
      if (!removeAllNonDigitChar(value || '')) {
        return ctx.createError({ message: 'Due date is required' })
      }

      return true
    },
  }),
})
