import useStyles from './styles'
import PartsHeroCTAWidget from 'bl-health/widgets/PartsHeroCTAWidget/PartsHeroCTAWidget'
import IllustratedSteps from 'baby-ui/compounds/IllustratedSteps'
import {
  THREE_STEPS_PARACHUTE_URL_IMG_URL,
  THREE_STEPS_PORTRAIT_URL_IMG_URL,
  THREE_STEPS_PUMP_BASE_IMG_URL,
} from 'bl-health/views/constants'
import { Box, Typography } from '@material-ui/core'
import EducationHeroSection from 'bl-health/components/EducationHeroSection/EducationHeroSection'

export default function ReplacementPartsHomeView() {
  const classes = useStyles()

  return (
    <>
      <PartsHeroCTAWidget />
      <EducationHeroSection
        title="No matter where you got your pump, we've got you covered."
        buttonText="Select my free pump parts"
        imageSrc="//images.babylist.com/image/upload/v1707229412/DME/dme_breast_pump_with_gradient_shapes_cropped.png"
      >
        <Typography gutterBottom>
          Replacing your parts can enhance the safety, efficiency and
          reliability of your pump–and if you've been pumping regularly, it's
          probably time for an upgrade.
        </Typography>
      </EducationHeroSection>
      <Box className={classes.illustratedStepsWrapper}>
        <IllustratedSteps
          title="Get your free replacement parts"
          subtitle="in 3 easy steps!"
          steps={[
            {
              content:
                'Provide some insurance info & tell us which pump you have',
              imageUrl: THREE_STEPS_PUMP_BASE_IMG_URL,
              alt: 'Replacement parts',
            },
            {
              content:
                "We'll verify your coverage and handle all the paperwork",
              imageUrl: THREE_STEPS_PORTRAIT_URL_IMG_URL,
              alt: 'Prescription',
            },
            {
              content: 'Pay nothing and receive parts right to your door',
              imageUrl: THREE_STEPS_PARACHUTE_URL_IMG_URL,
              alt: 'Product delivery',
            },
          ]}
        />
      </Box>
    </>
  )
}
