import { Factory } from 'fishery'
import { heroProductFactory } from 'factories'

interface CatalogCategoryFactoryTransientParams {
  inStock: boolean
  count: number
}

export default Factory.define<
  APIResponse.CatalogCategory,
  CatalogCategoryFactoryTransientParams
>(({ transientParams }) => ({
  data: {
    heroProducts: heroProductFactory.buildList(transientParams.count ?? 1, {
      isAvailableOnBabylist: transientParams.inStock ?? true,
    }),
  },
}))
