import { Badge, Box, Typography, styled, useTheme } from '@material-ui/core'
import { formatAsUSD } from 'baby-ui/utils'

interface OrderItemProps {
  orderItem: APIResponse.DME.OrderItem
}

const OrderItem = ({ orderItem }: OrderItemProps) => {
  const theme = useTheme()

  return (
    <>
      <Box mr={3}>
        <QuantityBadge badgeContent={orderItem.quantity}>
          <img
            alt={orderItem.name}
            src={orderItem.imageUrl}
            style={{
              border: `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 8,
            }}
            width={64}
          />
        </QuantityBadge>
      </Box>
      <Box flex={1} flexGrow={1} mr={2}>
        <Typography variant="subtitle2">{orderItem.name}</Typography>
      </Box>
      <Box textAlign="right" width={90}>
        <Typography variant="subtitle2">
          {displayPrice(orderItem.price)}
        </Typography>
      </Box>
    </>
  )
}

export default OrderItem

const QuantityBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
}))

const displayPrice = (value: APIResponse.DME.OrderItem['price']): string => {
  if (value === null) {
    return ''
  }
  const floatValue = parseFloat(value)
  return floatValue === 0 ? '' : formatAsUSD(floatValue)
}
