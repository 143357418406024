import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useForm, Controller } from 'react-hook-form'
import { Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import TextField from 'baby-ui/foundation/TextField'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import sendSubscriptionLink from 'bl-health/requests/sendSubscriptionLink'
import { useMutation } from '@tanstack/react-query'
import { ThemeConstants } from 'bl-health/styles'

interface SubscriptionLinkRequestValues {
  email: string
}
interface SubscriptionLinkRequestProps {
  title: string
  subtitle: string
  body: string
  onSuccess(email: string): void
}

/*
 * Submitting this form will send an email to the user with a link to their subscription
 */
const SubscriptionLinkRequestForm = observer(
  ({ title, subtitle, body, onSuccess }: SubscriptionLinkRequestProps) => {
    const { handleSubmit, control, watch } =
      useForm<SubscriptionLinkRequestValues>({
        defaultValues: {
          email: '',
        },
      })
    const watchEmail = watch('email')
    const [formErrorMessage, setFormErrorMessage] = useState('')

    const errorHandler = () =>
      setFormErrorMessage('An unexpected error occurred.')

    const successHandler = () => onSuccess(watchEmail)

    const { mutate: sendLink, isLoading } = useMutation({
      mutationFn: sendSubscriptionLink,
      onSuccess: successHandler,
      onError: errorHandler,
    })

    const submitHandler = (data: SubscriptionLinkRequestValues) => {
      const { email } = data
      if (email) {
        sendLink({ email })
      }
    }

    return (
      <>
        <Typography
          color="primary"
          component="h1"
          variant="h4"
          style={{ marginBottom: 12 }}
        >
          {title}
        </Typography>
        <Typography
          color="primary"
          variant="body1"
          style={{ marginBottom: 24 }}
        >
          {subtitle}
        </Typography>
        <Typography variant="body2" style={{ marginBottom: 16 }}>
          {body}
        </Typography>

        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid
            container
            spacing={ThemeConstants.BASE_SPACING}
            style={{ marginBottom: 16 }}
          >
            {formErrorMessage && (
              <Grid item xs={12}>
                <Alert severity="error" variant="standard">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formErrorMessage,
                    }}
                  />
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={TextField}
                control={control}
                autoComplete="email"
                label="Email"
                name="email"
                rules={{ required: true }}
                type="email"
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            color="secondary"
            type="submit"
            variant="contained"
            disabled={!watchEmail || isLoading}
            style={{ marginTop: 16 }}
          >
            Continue
          </LoadingButton>
        </form>
      </>
    )
  }
)

export default SubscriptionLinkRequestForm
