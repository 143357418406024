import { Link, Typography } from '@material-ui/core'
import useStyles from './styles'
import { InvalidPartsOrderCopyProps } from './types'
import { HEALTH_CONTACT_EMAIL } from '../../../constants'

export const UnknownErrorCopy = (_props: InvalidPartsOrderCopyProps) => {
  const classes = useStyles()
  return (
    <>
      <Typography
        className={classes.title}
        color="primary"
        component="h1"
        variant="h4"
      >
        We're sorry! An unexpected error has occurred.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        You can try clicking on the link again from your email.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        If you need additional help please email{' '}
        <Link
          className={classes.emailLink}
          href={`mailto:${HEALTH_CONTACT_EMAIL}`}
        >
          {HEALTH_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  )
}
