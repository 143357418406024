import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const SuccessIcon = <T extends SvgIconProps>(props: T) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.6734 9.45379C18.8687 9.64905 18.8687 9.96563 18.6734 10.1609L11.4776 17.3567C11.2851 17.5492 10.974 17.5523 10.7777 17.3638L6.28798 13.0524C6.08881 12.8611 6.08239 12.5446 6.27366 12.3454L7.65894 10.9028C7.85021 10.7037 8.16673 10.6973 8.3659 10.8885L10.7352 13.1637C10.9315 13.3522 11.2426 13.3491 11.4351 13.1566L16.5521 8.03958C16.7474 7.84431 17.0639 7.84431 17.2592 8.03958L18.6734 9.45379Z"
      fill="#426B69"
      fillRule="evenodd"
    />
  </svg>
)

export const FailureIcon = <T extends SvgIconProps>(props: T) => (
  <SvgIcon fill="none" height="16" viewBox="0 0 19 16" width="19" {...props}>
    <path
      clipRule="evenodd"
      d="M11 2C7.68629 2 5 4.68629 5 8H8L4 12L0 8H3C3 3.58172 6.58172 0 11 0C15.4183 0 19 3.58172 19 8C19 12.4183 15.4183 16 11 16C9.59546 16 8 15.5 6.75 14.75L8.2 13.3C9.06007 13.7773 9.9436 14 11 14C14.3137 14 17 11.3137 17 8C17 4.68629 14.3137 2 11 2Z"
      fill="#E05F31"
      fillRule="evenodd"
    />
  </SvgIcon>
)
