import {
  Drawer,
  DrawerProps,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import Button from 'baby-ui/foundation/Button'
import classNames from 'classnames'
import useStyles from './FilterPanel.styles'
import CountIndicatorBadge from 'baby-ui/foundation/CountIndicatorBadge/CountIndicatorBadge'

export interface FilterPanelProps extends Pick<DrawerProps, 'anchor' | 'open'> {
  title: string
  onClose?: () => void
  children: React.ReactNode
  onClear: () => void
  clearableCount: number
  onApply: () => void
}

const FilterPanel = ({
  children,
  anchor,
  open,
  title,
  onClose,
  onClear,
  clearableCount,
  onApply,
}: FilterPanelProps) => {
  const classes = useStyles()

  return (
    <Drawer anchor={anchor} open={open}>
      <div className={classes.filterPanelHeader}>
        <div className={classes.filterPanelHeaderInnerWrapper}>
          <Typography component="h2" variant="h6">
            {title}
          </Typography>
          {onClose && (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </div>

      {children}

      <div className={classes.filterPanelFooter}>
        <Link
          aria-label="Clear all filters"
          className={classNames({
            [classes.linkDisabled]: clearableCount === 0,
          })}
          variant="body2"
          onClick={onClear}
        >
          Clear filters{' '}
          {clearableCount > 0 && (
            <CountIndicatorBadge>{clearableCount}</CountIndicatorBadge>
          )}
        </Link>

        <Button color="primary" variant="contained" onClick={onApply}>
          Apply
        </Button>
      </div>
    </Drawer>
  )
}

export default FilterPanel
