import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { observer } from 'mobx-react-lite'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { PlainProductStateModelType, useDMEStore } from '../../store'
import { STORE_NAME } from '../../constants'
import { addToCart } from '../../requests'
import { addedToCartEvent } from '../../events'

const DEFAULT_CTA = 'Add To Cart'

export interface CardActionWidgetProps {
  product: PlainProductStateModelType
  cta?: string
}

export default observer(
  ({ product, cta = DEFAULT_CTA }: CardActionWidgetProps) => {
    const store = useDMEStore()
    const tracker = useTracking()
    const { mutate: addToCartMutation } = useMutation({
      mutationFn: addToCart,
      onSuccess: addToCartSucceeded,
      onError: addToCartFailed,
    })
    const [isLoading, setIsLoading] = React.useState(false)

    function addToCartSucceeded(response: { cart: APIResponse.Cart }) {
      store.cart.setLastItemAdded(response.cart, product.id!)
      fireAddedToCartEvent()
      window.location.href = store.context.nextPath as string
    }

    function addToCartFailed(e: any) {
      setIsLoading(false)
      store.ui.globalComponents.Snackbar.show(e.message)
      tracker.trackEvent({
        event: track.errorThrown,
        eventLocation: track.EventLocation.NONE,
        storeName: STORE_NAME,
        error: JSON.stringify(e),
      })
    }

    const addProductToCart = () => {
      setIsLoading(true)
      store.cart.product.update({ id: product.id!, price: product.price! })
      addToCartMutation({ cartItem: { productId: product.id!, quantity: 1 } })
    }

    function fireAddedToCartEvent() {
      addedToCartEvent(tracker)({
        location: track.EventLocation.PLP,
        productId: product.id as number,
        price: product.price as number,
      })
    }

    return (
      <LoadingButton
        fullWidth
        color="primary"
        disabled={isLoading}
        loading={isLoading && store.cart.product.id === product.id}
        size="small"
        variant="contained"
        onClick={addProductToCart}
      >
        {cta}
      </LoadingButton>
    )
  }
)
