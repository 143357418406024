import { useRef, useState } from 'react'

/**
 *
 * @param minWaitMs [number] - Minimum number of milliseconds to wait before `doneFn` may be called.
 * @param startFn [Function] - Function to call when the timer starts
 * @param doneFn [Function] - Function to call when we're done waiting
 * @returns retval [Object] - An object with two functions: `start` and `wait`
 * @returns retval.start [Function] - Call this function to start the timer
 * @returns retval.wait [Function] - Call this function to register a callback that will be called immediately
 * @returns retval.cancel [Function] - Call this function to cancel the timer
 * if the timer has ended, otherwise will be called when the timer completes.
 */
const useMinWaitTimer = (
  minWaitMs: number,
  startFn: () => void,
  doneFn: () => void
) => {
  const [submitStart, setSubmitStart] = useState<number>()
  const timerRef = useRef<ReturnType<typeof setInterval>>()

  // TODO: This doesn't consider multiple `wait` calls, which is fine for our current use cases but could be improved.
  const wait = (callback: () => void) => {
    const remainingTime = getRemainingTime(submitStart, minWaitMs)
    timerRef.current = setTimeout(() => {
      doneFn()
      callback()
    }, remainingTime)
  }

  const start = () => {
    setSubmitStart(new Date().getTime())
    startFn()
  }

  const cancel = () => {
    clearTimeout(timerRef.current)
  }

  return {
    start,
    wait,
    cancel,
  }
}

const getRemainingTime = (
  startTimestamp: number | undefined,
  waitTime: number
): number => {
  if (startTimestamp === undefined) {
    return 0
  }
  const timeElapsed = new Date().getTime() - startTimestamp
  const remainingTime = waitTime - timeElapsed
  return remainingTime > 0 ? remainingTime : 0
}

export default useMinWaitTimer
