import { ContentBlock } from '../components'

const PreShipNotice = () => (
  <ContentBlock testId="order-pre-ship-notice" title="You're all set!">
    We have everything we need to ship your pump and we will submit your claim
    to insurance at that time.
  </ContentBlock>
)

export default PreShipNotice
