import TextField, { TextFieldProps } from 'baby-ui/foundation/TextField'
import React from 'react'

const PhysicianFirstNameTextField = React.forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ onBlur, onFocus, ...rest }, ref) => {
  const [showHelperText, setShowHelperText] = React.useState(false)

  return (
    <TextField
      {...rest}
      helperText={
        showHelperText
          ? "Search for any provider's name if you receive care at a group practice"
          : undefined
      }
      ref={ref}
      onBlur={(e) => {
        setShowHelperText(false)
        onBlur?.(e)
      }}
      onFocus={(e) => {
        setShowHelperText(true)
        onFocus?.(e)
      }}
    />
  )
})

export default PhysicianFirstNameTextField
