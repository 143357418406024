import React from 'react'
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core'
import classnames from 'classnames'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useStyles from 'baby-ui/foundation/Link/Link.styles'

export interface LinkProps extends Omit<MuiLinkProps, 'variant'> {
  variant?: MuiLinkProps['variant'] | 'chevronIcon'
  component?: React.ElementType
}

const Link = ({ variant, className, children, ...rest }: LinkProps) => {
  const classes = useStyles()

  if (variant === 'chevronIcon') {
    return (
      <MuiLink
        className={classnames(className, classes.chevronIconVariantRoot)}
        {...rest}
      >
        {children}
        <ChevronRightIcon />
      </MuiLink>
    )
  }

  return (
    <MuiLink className={className} variant={variant} {...rest}>
      {children}
    </MuiLink>
  )
}

export default Link
