import { format, parseISO } from 'date-fns'
import { Card, ContactLink, ContentBlock } from '../components'

interface Props {
  cancelledAt: string | null
}

export default function CancelledCard({ cancelledAt }: Props) {
  const formattedDate = cancelledAt
    ? format(parseISO(cancelledAt), 'MMMM d')
    : null

  return (
    <Card>
      <ContentBlock title="Your order has been canceled">
        {formattedDate ? `Your order was canceled on ${formattedDate}. ` : null}
        Reach out to <ContactLink /> if you have any questions.
      </ContentBlock>
    </Card>
  )
}
