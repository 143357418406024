import MaskedInput from 'react-text-mask'

interface DigitInputMaskProps {
  inputRef: (ref: HTMLElement | null) => void
  length: number
}

export default function DigitInputMask({
  inputRef,
  length,
  ...rest
}: DigitInputMaskProps) {
  const mask = new Array(length).fill(/\d/)
  return (
    <MaskedInput
      {...rest}
      mask={mask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
    />
  )
}
