import fetch from 'lib/fetch'
import { CUSTOMER_PHYSICIAN_DETAILS_ENDPOINT } from './constants'
import { RequestMethod } from './RequestMethod'

export interface PhysicianDetailsPayload {
  firstName: string
  lastName: string
  addressState: string
  addressCity: string
  phoneNumber?: string
}

export default function createCustomerPhysician(
  payload: PhysicianDetailsPayload
): Promise<Response> {
  return fetch(CUSTOMER_PHYSICIAN_DETAILS_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
