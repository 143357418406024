import { Factory } from 'fishery'
import faker from 'faker'

export default Factory.define<APIResponse.Insurance>(({ sequence }) => ({
  company: faker.company.companyName(),
  eligible: true,
  insuranceStateId: sequence,
  prescriptionRequired: true,
  upgradeEligible: true,
  id: sequence,
  catalogCategoryId: sequence,
  shippingEligibilityDays: faker.datatype.number(),
  billingProvider: 'babylist',
  promoted: false,
  enabled: true,
  manualReviewRequired: false,
}))
