import { forwardRef } from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core'

export interface CheckboxProps
  extends Omit<MuiCheckboxProps, 'onChange'>,
    Pick<FormControlLabelProps, 'label'> {
  onChange?: (checked: boolean) => void
}

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ label, onChange, disabled, value, checked = false, ...rest }, ref) => (
    <FormControlLabel
      checked={checked}
      control={
        <MuiCheckbox
          checked={checked}
          disabled={disabled}
          ref={ref}
          onChange={(_, isChecked) => {
            onChange?.(isChecked)
          }}
          {...rest}
        />
      }
      disabled={disabled}
      label={label}
    />
  )
)

export default Checkbox
