import { createStyles, makeStyles } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme) =>
  createStyles({
    productCardSkeletonButton: {
      width: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    descriptionText: {
      textAlign: 'center',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
    },
    productFilterSelect: {
      minWidth: 150,
    },
  })
)
