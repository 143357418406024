import * as React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import Button from 'baby-ui/foundation/Button'

interface Clause {
  /**
   * Appears before the list.
   */
  title: React.ReactNode
  /**
   * List of terms.
   */
  terms: (string | string[])[]
  /**
   * Whether or not the list should be bulleted or numbered.
   */
  type: 'ordered' | 'unordered'
}

export interface TermsAndConditionsProps
  extends Pick<DialogProps, 'open' | 'onClose'> {
  /**
   * Callback fired when the agree button is clicked.
   * @param e
   */
  onAgree(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void

  /**
   * Callback fired when the disagree button is clicked.
   * @param e
   */
  onDisagree(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void

  /**
   * Appears at the top of the dialog.
   */
  title: string
  /**
   * The terms and conditions.
   */
  clauses: Clause[]
  /**
   * The dialog content
   * Directly supply the content
   */
  dialogContent?: React.ReactNode
}

export default function TermsAndConditions({
  onAgree,
  onClose,
  onDisagree,
  open,
  title,
  clauses,
  dialogContent,
}: TermsAndConditionsProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="terms-and-conditions-dialog-title"
      aria-describedby="terms-and-conditions-dialog-description"
    >
      <DialogTitle id="terms-and-conditions-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="terms-and-conditions-dialog-description">
          {dialogContent && dialogContent}
          {clauses.map(({ terms, type, title }, i) => {
            const L = type === 'unordered' ? 'ul' : 'ol'
            return (
              // eslint-disable-next-line react/no-array-index-key
              <section key={`${title}-${i}`}>
                <Typography gutterBottom>{title}</Typography>
                <L>
                  {terms.map((term, j) => {
                    const isSublist = Array.isArray(term)

                    if (isSublist) {
                      const root = (term as string[]).shift()

                      return (
                        <>
                          {/* eslint-disable-next-line react/no-array-index-key */}
                          <li key={`${title}-${i}-${j}`}>{root}</li>
                          <L>
                            {(term as string[]).map((subTerm, k) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <li key={`${title}-${i}-${j}-${k}`}>{subTerm}</li>
                            ))}
                          </L>
                        </>
                      )
                    }

                    // eslint-disable-next-line react/no-array-index-key
                    return <li key={`${title}-${i}-${j}`}>{term}</li>
                  })}
                </L>
              </section>
            )
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onDisagree} color="primary">
          Disagree
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
