import { Box } from '@material-ui/core'
import { Card, ContentBlock } from '../components'
import { formattedEstimate } from '../../ShippingEstimate.utils'

interface ShippingInfoCardProps {
  daysFromDueDate: number | null
}

export default function ShippingInfoCard({
  daysFromDueDate,
}: ShippingInfoCardProps) {
  return daysFromDueDate ? (
    <Card>
      <ContentBlock title="When will my pump ship?">
        <Box mb={2}>
          Your pump will ship {formattedEstimate(daysFromDueDate)} your due
          date.
        </Box>
        <Box mb={2}>
          Your insurance plan determines when we can ship your pump and shipping
          is always FREE.
        </Box>
        You'll get shipping and delivery updates by email.
      </ContentBlock>
    </Card>
  ) : (
    <Card>
      <ContentBlock title="When will my pump ship?">
        <Box mb={2}>
          Your pump will ship within 2 business days of us receiving your
          prescription from your provider.
        </Box>
        You'll get shipping and delivery updates by email.
      </ContentBlock>
    </Card>
  )
}
