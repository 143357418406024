export const SUBSCRIPTION_ERROR_TYPES: APIResponse.DME.SubscriptionErrorType[] =
  [
    'INSURANCE_VERIFICATION_FAILED_INACTIVE',
    'INSURANCE_VERIFICATION_FAILED_INVALID',
    'INSURANCE_UNSUPPORTED',
    'ORDER_IN_PROGRESS',
    'AWAITING_PAID_INVOICE',
    'ORDER_PLACED_WITHIN_INTERVAL',
    'SUBSCRIPTION_ENDED',
    'UNKNOWN_ERROR',
  ]
