export const createClearFilterDisplayText = (text: string, count: number) => {
  if (count === 0) {
    return text
  }

  if (count === 1) {
    return `${text} (${count})`
  }

  return `${text}s (${count})`
}
