import { createStyles, makeStyles } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
    },
    paper: {
      margin: theme.spacing(ThemeConstants.BASE_SPACING),
      maxWidth: '375px',
    },
    icon: {
      padding: 0,
    },
    bottomPadding: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
    },
  })
)
