import { createStyles, makeStyles } from '@material-ui/core'
import { ThemeConstants } from 'bl-health/styles'

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      margin: 'auto',
      maxWidth: 1200,
      paddingLeft: theme.spacing(ThemeConstants.BASE_SPACING),
      paddingRight: theme.spacing(ThemeConstants.BASE_SPACING),
      backgroundColor: theme.palette.background.contrast,
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        marginRight: theme.spacing(ThemeConstants.BASE_SPACING),
        height: 75,
        minWidth: 75,
        width: 75,
        [theme.breakpoints.up('sm')]: {
          marginRight: 0,
          marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
          height: 115,
          width: 115,
        },
      },
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        textAlign: 'center',
        marginTop: theme.spacing(ThemeConstants.BASE_SPACING),
        borderRight: `solid 1px ${theme.palette.primary.main}`,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    stepContent: {
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        maxWidth: 250,
        margin: 'auto',
        textAlign: 'center',
      },
    },
    stepNumber: {
      display: 'none',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
      fontWeight: 'bold',
      textFillColor: 'white',
      textStrokeWidth: '1px',
      textStrokeColor: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    title: {
      marginBottom: theme.spacing(4),
    },
  })
)
