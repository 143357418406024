import * as fns from 'date-fns'

export default class DateField {
  private readonly year: string

  private readonly month: string

  private readonly day: string

  constructor(year: string, month: string, day: string) {
    this.year = year
    this.month = month
    this.day = day
  }

  get isValid() {
    const parsedDate = fns.parseISO(this.toString())
    return fns.isValid(parsedDate)
  }

  toString() {
    return [this.year, this.month, this.day].join('-')
  }
}
