import { types } from 'mobx-state-tree'
import { ApplicationStrategy } from 'bl-health/types'

const ContextStateModel = types
  .model({
    strategy: types.optional(
      types.enumeration(Object.values(ApplicationStrategy)),
      ApplicationStrategy.Pumps
    ),
    formAction: types.maybeNull(types.string),
    nextPath: types.maybeNull(types.string),
    upgradeFee: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get inPumpFlow() {
      return self.strategy === ApplicationStrategy.Pumps
    },
    get inPartsFlow() {
      return self.strategy === ApplicationStrategy.Parts
    },
  }))

export default ContextStateModel
