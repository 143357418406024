import { FilterItem } from 'baby-ui/types'

export const createControlledFilterItemsState = (
  allItems: FilterItem[],
  currentItem: FilterItem,
  inputType: 'checkbox' | 'radio'
) => {
  return allItems.map((item) => {
    if (item.name === currentItem.name) {
      return { ...item, checked: currentItem.checked }
    }

    if (inputType === 'radio') {
      return { ...item, checked: false }
    }

    return item
  })
}
