import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classnames from 'classnames'
import React from 'react'
import useStyles from './AlertDialogTitle.styles'

export interface AlertDialogTitleProps extends DialogTitleProps {
  onClose?: React.MouseEventHandler<HTMLElement>
  disableCloseButtonAllowance?: boolean
}

export default function AlertDialogTitle({
  children,
  onClose,
  disableCloseButtonAllowance = false,
  className,
  ...rest
}: AlertDialogTitleProps) {
  const classes = useStyles()

  return (
    <DialogTitle
      disableTypography
      className={classnames(classes.root, className)}
      {...rest}
    >
      <Typography
        className={classnames({
          [classes.closeButtonAllowance]:
            !!onClose && !disableCloseButtonAllowance,
        })}
        variant="h6"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
