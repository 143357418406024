import { createStyles, makeStyles } from '@material-ui/core'

const CARD_WIDTH_LEFT = '53%'
const CARD_WIDTH_RIGHT = '47%'

export default makeStyles((theme) =>
  createStyles({
    featureIndicatorPopper: { zIndex: 10 },
    featureIndicatorDismissButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      pointerEvents: 'auto',
    },
    featureIndicatorTooltip: {
      background: theme.palette.secondary.main,
      maxWidth: 220,
      padding: theme.spacing(1),
    },
    featureIndicatorArrow: {
      color: theme.palette.secondary.main,
    },
    featureIndicatorMargin: {
      margin: theme.spacing(1, 'auto'),
    },
    addToCart: {
      alignSelf: 'center',
      margin: '0 auto',
      width: '100%',
      padding: theme.spacing(1, 1, 1, 2),
    },
    alert: {
      backgroundColor: '#E2D6D6FA !important',
      color: '#000000DE !important',
      width: '100%',
    },
    attributeName: {
      verticalAlign: 'middle',
    },
    attributeText: {
      letterSpacing: '1px',
      textTransform: 'uppercase',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    card: {
      border: '0.5px solid #E2D6D6',
      borderRadius: '0',
    },
    cardFooter: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(1),
    },
    cardWidth: {
      display: 'flex',
      maxWidth: CARD_WIDTH_LEFT,
      flexBasis: CARD_WIDTH_LEFT,
    },
    feature: {
      backgroundColor: '#F8F8F8',
      color: '#757070',
      margin: '4px 4px 0 0',
      cursor: 'pointer !important',
    },
    featureContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(1),
    },
    actionArea: {
      '&:hover $focusHighlight': {
        opacity: 0,
      },
    },
    focusHighlight: {},
    image: {
      backgroundColor: theme.palette.grey[50],
      maxWidth: CARD_WIDTH_RIGHT,
      flexBasis: CARD_WIDTH_RIGHT,
    },
    imageRatingsContainer: {
      flexWrap: 'nowrap',
      borderBottom: `thin solid ${theme.palette.grey[50]}`,
    },
    infoIcon: {
      color: '#C3B2B2',
      verticalAlign: 'middle',
      padding: 1,
      margin: '0 0 1px 4px',
    },
    nameFeatures: {
      padding: theme.spacing(3, 2),
    },
    attributeMeter: {
      width: '100%',
      height: '8px !important',
      margin: theme.spacing(0, 4, 0, 0),
      backgroundColor: '#F8F8F8 !important',
      borderRadius: 5,
    },
    attributeMeterBar: {
      borderRadius: 5,
      backgroundColor: '#C3B2B2 !important',
    },
    attributeMeterContainer: {
      alignItems: 'center',
    },
    priceContainer: {
      textAlign: 'center',
      paddingRight: theme.spacing(1),
    },
    productName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 3,
      boxOrient: 'vertical',
    },
    ratings: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${theme.spacing(1)}px !important`,
    },
    scoredAttributes: {
      padding: theme.spacing(1, 0),
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 14,
    },
    tooltipArrow: {
      color: theme.palette.primary.main,
    },
    tooltipPlacement: {
      margin: '4px 0',
    },
  })
)
