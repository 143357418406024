import { ReactElement } from 'react'
import {
  Container,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Image from 'components/image'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { useDMEStore } from 'bl-health/store'
import {
  DMEInfoCard,
  InfoCardProps,
} from 'bl-health/components/InfoCard/DMEInfoCard'

interface EducationHeroSectionProps {
  title?: string
  imageSrc: string
  buttonText: string
  infoCard?: InfoCardProps
  children: ReactElement | null
  disclaimer?: ReactElement
}

const EducationHeroSection = ({
  title,
  imageSrc,
  buttonText,
  disclaimer,
  infoCard,
  children,
}: EducationHeroSectionProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const store = useDMEStore()

  const onClick = () => {
    store.ui.globalComponents.Snackbar.show(
      'Select your shipping state and insurance carrier to get started.',
      'info'
    )
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const image = (
    <Grid item sm={6} xs={12} style={{ maxWidth: 532, padding: 24 }}>
      <Image src={imageSrc} style={{ width: '100%' }} />
    </Grid>
  )

  return (
    <Container style={{ width: '100%' }}>
      <Grid
        container
        alignItems="center"
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        style={{
          padding: '48px 0 48px 0',
          justifyContent: 'space-evenly',
        }}
      >
        {isMobile && image}
        <Grid item sm={6} xs={12} style={{ maxWidth: 476, padding: 16 }}>
          <Box pb={3} textAlign={isMobile ? 'center' : 'left'}>
            <Typography color="primary" variant="h4">
              {title}
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            direction="column"
            style={{ textAlign: 'left' }}
          >
            <Grid item>{children}</Grid>
            <Grid item>
              <Box maxWidth="396px">
                <LoadingButton
                  fullWidth
                  style={{ maxWidth: '396px' }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={onClick}
                >
                  {buttonText}
                </LoadingButton>
              </Box>
            </Grid>
            {infoCard && (
              <Grid item>
                <Box maxWidth="396px">
                  <DMEInfoCard {...infoCard} />
                </Box>
              </Grid>
            )}
            {disclaimer && <Grid item>{disclaimer}</Grid>}
          </Grid>
        </Grid>
        {!isMobile && image}
      </Grid>
    </Container>
  )
}

export default EducationHeroSection
