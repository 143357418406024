import { forwardRef } from 'react'
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core'

export type TextFieldProps = MuiTextFieldProps

const TextField = forwardRef<HTMLInputElement, MuiTextFieldProps>(
  // eslint-disable-next-line react/prop-types
  ({ name, InputLabelProps, ...otherProps }, ref) => (
    <MuiTextField
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      name={name}
      ref={ref}
      size="small"
      variant="outlined"
      {...otherProps}
    />
  )
)

export default TextField
