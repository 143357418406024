import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import React from 'react'
import { ThemeConstants } from '../../styles'
import useStyles from './PhysicianForm.styles'
import {
  PhysicianFormMode,
  PhysicianFormValues,
  usePhysicianForm,
} from './utils'

export interface PhysicianFormProps {
  defaultValues?: PhysicianFormValues
  loading?: boolean
  mode: PhysicianFormMode
  onSubmit: (data: PhysicianFormValues) => void
}

export default function PhysicianForm({
  defaultValues = {},
  loading = false,
  mode,
  onSubmit,
}: PhysicianFormProps) {
  const { handleSubmit, control } = useForm<PhysicianFormValues>({
    mode: 'onChange',
    defaultValues,
  })
  const { submitButton, formInputs } = usePhysicianForm(mode, { defaultValues })
  const classes = useStyles()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={ThemeConstants.BASE_SPACING}>
        {formInputs.map(({ props, meta }) => {
          // eslint-disable-next-line react/prop-types
          const { name, input, required, type, ...attrs } = props
          const Wrapper = type === 'hidden' ? React.Fragment : Grid
          return (
            <Wrapper item key={name} xs={meta.colSize}>
              <Controller
                as={input}
                control={control}
                disabled={loading}
                name={name}
                required={required}
                rules={{ required }}
                type={type}
                {...(attrs as any)}
              />
            </Wrapper>
          )
        })}
      </Grid>
      <LoadingButton
        className={classes.submitButton}
        loading={loading}
        {...submitButton.props}
      />
    </form>
  )
}
