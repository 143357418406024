import { Box, Grid, Typography } from '@material-ui/core'
import { OrderStatusIcon } from './components'

interface TitleBarProps
  extends Pick<APIResponse.DME.Order, 'customerFirstName' | 'reference'> {
  cancelled: boolean
}

const TitleBar = ({
  reference,
  cancelled,
  customerFirstName,
}: TitleBarProps) => (
  <Box mb={2}>
    <Grid container direction="row">
      <OrderStatusIcon variant={cancelled ? 'cancel' : 'check'} />
      <Box ml={2}>
        {reference !== null && (
          <Typography
            color="textSecondary"
            data-testid="title-bar-order-reference"
            variant="body2"
          >
            Order {reference}
          </Typography>
        )}
        <Typography color="textPrimary" variant="h5">
          {cancelled ? 'Order canceled' : `Thank you ${customerFirstName}!`}
        </Typography>
      </Box>
    </Grid>
  </Box>
)

export default TitleBar
