import { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as yup from 'yup'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import useStyles from './styles'
import {
  createCustomerInsurance as createCustomerInsuranceRequest,
  getInsuranceCatalog,
} from '../../requests'
import { ThemeConstants } from '../../styles'
import InsuranceStateField from './fields/InsuranceStateField'
import InsuranceProviderField from './fields/InsuranceProviderField'
import InsuranceMemberIdField from './fields/InsuranceMemberIdField'
import LastNameField from './fields/LastNameField'
import FirstNameField from './fields/FirstNameField'
import getCurrentCustomerRequest from '../../requests/getCurrentCustomer'
import createOTPRequest from '../../requests/createOTP'
import EmailVerificationWidget from '../../widgets/EmailVerificationWidget/EmailVerificationWidget'
import useMinWaitTimer from '../../shared/useMinWaitTimer'
import { useDMEStore } from '../../store'
// TODO: We should move this code to a shared location rather
// than reaching over into another form module for them.
import {
  findInsuranceProviders,
  processInsuranceStates,
} from '../InsurancePickerForm/InsurancePickerForm.utils'
import apiErrorResponseAdapter from '../../utils/apiErrorResponseAdapter'
import {
  UnsupportedInsuranceDialog,
  useUnsupportedInsuranceDialog,
} from '../../widgets/UnsupportedInsuranceDialog'
import AOBField from './fields/AOBField'
import { formSubmittedEvent } from '../../events/formSubmittedEvent'
import { STORE_NAME } from '../../constants'

const validationSchema = yup.object({
  insuranceState: yup.string().required('Please select your shipping state.'),
  insuranceProvider: yup
    .string()
    .required('Please select your insurance carrier.'),
  insuranceMemberId: yup
    .string()
    .required('Please enter your insurance member ID.'),
})

interface FormValues {
  insuranceState: string
  insuranceProvider: string
  insuranceMemberId: string
  aobAcknowledged: boolean
  firstName: string
  lastName: string
}

export type InsuranceFormSuccessCallback = (
  data: APIResponse.CustomerInsurance
) => void

interface InsuranceFormProps {
  // The customer ID we are meant to be creating the insurance for.
  customerId: number
  subscriptionId: string
  onSuccess: InsuranceFormSuccessCallback
}

export type SelectableInsuranceOption = Pick<
  APIResponse.Insurance,
  'company' | 'enabled'
> & {
  id?: number
}

type FormError = null | ReturnType<typeof apiErrorResponseAdapter>

const InsuranceForm = ({
  customerId,
  subscriptionId,
  onSuccess,
}: InsuranceFormProps) => {
  const classes = useStyles()
  const { handleSubmit, control, setValue, getValues, errors } =
    useForm<FormValues>({
      validationSchema,
    })
  const [insuranceState, setInsuranceState] = useState<string | null>(null)
  const [insuranceProvider, setInsuranceProvider] =
    useState<SelectableInsuranceOption | null>(null)
  const { data: insuranceStateData } = useQuery({
    queryKey: ['insurancePicker'],
    queryFn: getInsuranceCatalog,
    refetchOnWindowFocus: false,
  })
  const [showOTP, setShowOTP] = useState(false)
  const [formErrors, setFormErrors] = useState<FormError>()
  const { data: currentCustomer } = useQuery({
    queryKey: ['currentCustomer'],
    queryFn: getCurrentCustomerRequest,
    refetchOnWindowFocus: false,
  })
  const store = useDMEStore()
  const timedOverlay = useMinWaitTimer(
    5000,
    () => {
      store.ui.globalComponents.Overlay.show('insuranceVerification')
    },
    () => {
      store.ui.globalComponents.Overlay.close('insuranceVerification')
    }
  )
  const unsupportedInsuranceDialog = useUnsupportedInsuranceDialog()
  const tracker = useTracking()

  const allInsuranceStates = processInsuranceStates(insuranceStateData || [])
  const { allInsurances: allInsuranceProviders, promotedInsurances } =
    findInsuranceProviders(insuranceState || '', insuranceStateData || [])

  const onInsuranceStateChange = (value: string | null) => {
    if (
      value &&
      unsupportedInsuranceDialog.isUnsupportedState(value, insuranceStateData!)
    ) {
      unsupportedInsuranceDialog.showUnsupportedStateDialog(value)
      return
    }

    setInsuranceState(value)
    setValue('insuranceState', value || '')
    onInsuranceProviderChange(null)
  }

  const onInsuranceProviderChange = (
    value: SelectableInsuranceOption | null
  ) => {
    if (value && unsupportedInsuranceDialog.isUnsupportedProvider(value)) {
      setInsuranceProvider(null)
      unsupportedInsuranceDialog.showUnsupportedProviderDialog()
      return
    }

    if (value && unsupportedInsuranceDialog.isDisabledProvider(value)) {
      setInsuranceProvider(null)
      unsupportedInsuranceDialog.showUnsupportedProviderDialog(value.company)
      return
    }

    setInsuranceProvider(value)
    setValue('insuranceProvider', value?.id?.toString() || '')
  }

  const submitForm = useCallback(
    (data: FormValues) => {
      timedOverlay.start()
      const selectedInsuranceProvider = allInsuranceProviders.find(
        (provider) => provider.id === Number(data.insuranceProvider)
      )

      if (!selectedInsuranceProvider) {
        // TODO: Handle edge case where an insurance provider ID was selected in the form,
        // but for some reason it doesn't exist in the `allInsuranceProviders` list.
        // Is this an impossible state?
        return
      }

      const payload = {
        customer: {
          id: customerId,
          aobAcknowledged: data.aobAcknowledged,
          firstName: data.firstName,
          lastName: data.lastName,
        },
        phiCustomerInsurance: {
          insuranceId: selectedInsuranceProvider.id,
          insuranceState: data.insuranceState,
          insuranceMemberId: data.insuranceMemberId,
        },
        subscription: {
          id: subscriptionId,
        },
      }
      createCustomerInsurance(payload)
    },
    [allInsuranceProviders.length]
  )

  const onSubmit = useCallback(
    (data: FormValues) => {
      if (currentCustomer?.id !== customerId) {
        requestOTP({ customerId })
        setShowOTP(true)
      } else {
        submitForm(data)
      }
    },
    [currentCustomer?.id, customerId, submitForm]
  )

  const {
    mutate: createCustomerInsurance,
    isLoading: createCustomerInsuranceIsLoading,
  } = useMutation({
    mutationFn: createCustomerInsuranceRequest,
    onSuccess: (response: APIResponse.CustomerInsurance) => {
      formSubmittedEvent(tracker)({
        formName: track.FormName.REPLACEMENT_PARTS_NEW_INSURANCE_FORM,
        status: track.Status.SUCCESS,
      })
      timedOverlay.wait(() => {
        onSuccess(response)
      })
    },
    onError: (error: APIResponse.StandardError) => {
      const formattedError = apiErrorResponseAdapter(error)
      formSubmittedEvent(tracker)({
        formName: track.FormName.REPLACEMENT_PARTS_NEW_INSURANCE_FORM,
        status: track.Status.FAIL,
        errorMessage: formattedError.message || JSON.stringify(error),
      })
      timedOverlay.wait(() => {
        setFormErrors(formattedError)
      })
    },
  })

  const { mutate: requestOTP } = useMutation({
    mutationFn: createOTPRequest,
    onSuccess: () => {
      console.log('success requesting otp')
    },
    onError: (error: unknown) => {
      // TODO: Handle error
      console.error('error requesting otp', error)
    },
  })

  const handleOTPSuccess = useCallback(
    ({ id }: APIResponse.CustomerIdentifier) => {
      if (id === customerId) {
        setShowOTP(false)
        submitForm(getValues())
      }
    },
    [customerId, submitForm]
  )

  const handleOTPClose = () => {
    setShowOTP(false)
  }

  const handleAOBChanged = (value: boolean) => {
    setValue('aobAcknowledged', value)
  }

  useEffect(() => {
    tracker.trackEvent({
      event: track.replacementPartsNewInsuranceFormViewed,
      storeName: STORE_NAME,
    })
  }, [])

  return (
    <>
      <EmailVerificationWidget
        customerId={customerId}
        open={showOTP}
        onClose={handleOTPClose}
        onFormSubmitted={handleOTPSuccess}
      />
      <UnsupportedInsuranceDialog
        onClose={() => unsupportedInsuranceDialog.hideDialog()}
        {...unsupportedInsuranceDialog.dialogProps}
      />
      <Box mb={6} mt={2}>
        <Typography
          className={classes.title}
          color="primary"
          component="h1"
          variant="h4"
        >
          Let's get your new insurance information.
        </Typography>
      </Box>
      <FormErrorMessage errorMessage={formErrors?.message} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={ThemeConstants.BASE_SPACING}>
          <FirstNameField
            control={control}
            error={errors?.firstName || formErrors?.firstName}
            helperText={errors.firstName?.message || formErrors?.firstName}
          />
          <LastNameField
            control={control}
            error={errors?.lastName || formErrors?.lastName}
            helperText={errors.lastName?.message || formErrors?.lastName}
          />
          <InsuranceStateField
            control={control}
            data={allInsuranceStates}
            error={errors?.insuranceState}
            selectedValue={insuranceState}
            onChange={onInsuranceStateChange}
          />
          <InsuranceProviderField
            control={control}
            data={allInsuranceProviders}
            disabled={!insuranceState}
            error={errors?.insuranceProvider}
            promotedOptions={promotedInsurances}
            selectedValue={insuranceProvider}
            onChange={onInsuranceProviderChange}
          />
          <InsuranceMemberIdField
            control={control}
            error={errors.insuranceMemberId}
          />
          <AOBField
            control={control}
            error={errors.aobAcknowledged}
            onChange={handleAOBChanged}
          />
          <LoadingButton
            fullWidth
            className={classes.submitButton}
            color="primary"
            disabled={createCustomerInsuranceIsLoading}
            loading={false}
            type="submit"
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Grid>
      </form>
    </>
  )
}

const FormErrorMessage = ({ errorMessage }: { errorMessage?: string }) =>
  errorMessage ? (
    <Box mb={4}>
      <Alert severity="error" variant="standard">
        {errorMessage}
      </Alert>
    </Box>
  ) : null

export default InsuranceForm
