import { InvalidPartsOrderCopyProps } from './types'
import { SimpleOrderBlocker } from './SimpleOrderBlocker'

export const RecentDueDateCopy = (_props: InvalidPartsOrderCopyProps) => {
  return (
    <SimpleOrderBlocker
      title="It's too soon to get free replacement parts!"
      content="Insurance does not allow us to ship your first set of free parts until
        30 days after your due date. We will email you when it's time to order."
    />
  )
}
