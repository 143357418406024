import { SortOrder } from 'baby-ui/lib/SortOrder'
import { SortVector } from './FilterSettings'

/**
 * The purpose of this class is to encapsulate the rules governing the sorting
 * of items. By design, it applies a default sorting category if none is
 * specified. Additionally, the class is built to ensure that providing an
 * unrecognized category name triggers an error, thereby enforcing the
 * deliberate and correct usage of sorting categories.
 */
export default class ProductFilterSortCategory {
  static readonly MOBILITY_SORT_PATH = 'sortableData.mobility.score'

  static readonly PRICE_SORT_PATH = 'sortableData.price'

  static readonly RATING_SORT_PATH = 'sortableData.rating'

  static readonly SUCTION_SORT_PATH = 'sortableData.suction.score'

  static readonly USER_REVIEW_COUNT_SORT_PATH = 'sortableData.reviewCount'

  static readonly ALL_SORT_PATHS = [
    ProductFilterSortCategory.MOBILITY_SORT_PATH,
    ProductFilterSortCategory.PRICE_SORT_PATH,
    ProductFilterSortCategory.RATING_SORT_PATH,
    ProductFilterSortCategory.SUCTION_SORT_PATH,
    ProductFilterSortCategory.USER_REVIEW_COUNT_SORT_PATH,
  ]

  static readonly BEST_MOBILITY_CATEGORY_NAME = 'Best mobility'

  static readonly BEST_SUCTION_CATEGORY_NAME = 'Best suction'

  static readonly HIGHEST_PRICE_CATEGORY_NAME = 'Highest price'

  static readonly HIGHEST_USER_RATING_CATEGORY_NAME = 'Highest user rating'

  static readonly LOWEST_PRICE_CATEGORY_NAME = 'Lowest price'

  static readonly MOST_REVIEWED_CATEGORY_NAME = 'Most reviewed'

  static readonly ALL_CATEGORY_NAMES = [
    ProductFilterSortCategory.LOWEST_PRICE_CATEGORY_NAME,
    ProductFilterSortCategory.HIGHEST_PRICE_CATEGORY_NAME,
    ProductFilterSortCategory.HIGHEST_USER_RATING_CATEGORY_NAME,
    ProductFilterSortCategory.MOST_REVIEWED_CATEGORY_NAME,
    ProductFilterSortCategory.BEST_MOBILITY_CATEGORY_NAME,
    ProductFilterSortCategory.BEST_SUCTION_CATEGORY_NAME,
  ]

  private readonly _name?: string

  constructor(categoryName?: string) {
    if (categoryName) {
      if (!this.categoryNameIsValid(categoryName)) {
        throw new Error(`Invalid category name: ${categoryName}`)
      }

      this._name = categoryName
    }
  }

  static get all() {
    return ProductFilterSortCategory.ALL_CATEGORY_NAMES.map(
      (name) => new ProductFilterSortCategory(name)
    )
  }

  get name() {
    if (this._name) {
      return this._name
    }

    return ProductFilterSortCategory.LOWEST_PRICE_CATEGORY_NAME
  }

  get properties() {
    switch (this.name) {
      case ProductFilterSortCategory.BEST_MOBILITY_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.MOBILITY_SORT_PATH,
          order: SortOrder.Desc,
        }
      case ProductFilterSortCategory.BEST_SUCTION_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.SUCTION_SORT_PATH,
          order: SortOrder.Desc,
        }
      case ProductFilterSortCategory.HIGHEST_PRICE_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.PRICE_SORT_PATH,
          order: SortOrder.Desc,
        }
      case ProductFilterSortCategory.HIGHEST_USER_RATING_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.RATING_SORT_PATH,
          order: SortOrder.Desc,
        }
      case ProductFilterSortCategory.LOWEST_PRICE_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.PRICE_SORT_PATH,
          order: SortOrder.Asc,
        }
      case ProductFilterSortCategory.MOST_REVIEWED_CATEGORY_NAME:
        return {
          path: ProductFilterSortCategory.USER_REVIEW_COUNT_SORT_PATH,
          order: SortOrder.Desc,
        }
      default:
        return {
          path: ProductFilterSortCategory.PRICE_SORT_PATH,
          order: SortOrder.Asc,
        }
    }
  }

  matches(sort: SortVector) {
    const [path, order] = sort
    return this.properties?.path === path && this.properties?.order === order
  }

  private categoryNameIsValid(categoryName: string) {
    return ProductFilterSortCategory.ALL_CATEGORY_NAMES.includes(categoryName)
  }
}
