import * as React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import AlertDialogTitle, {
  AlertDialogTitleProps,
} from 'baby-ui/foundation/AlertDialogTitle/AlertDialogTitle'
import useStyles from './AlertDialog.styles'

export type AlertDialogProps = {
  actions?: React.ReactNode
  children: React.ReactNode
  open: boolean
  title: React.ReactNode
  closeOnBackdropClick?: boolean
} & Pick<AlertDialogTitleProps, 'onClose' | 'disableCloseButtonAllowance'>

export default function AlertDialog({
  actions,
  children,
  disableCloseButtonAllowance,
  onClose,
  open,
  title,
  closeOnBackdropClick = false,
  ...rest
}: AlertDialogProps) {
  const classes = useStyles()

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={{ paper: classes.paper }}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && closeOnBackdropClick) {
          onClose?.(e as any)
        }
      }}
      {...rest}
    >
      <AlertDialogTitle
        disableCloseButtonAllowance={disableCloseButtonAllowance}
        onClose={onClose}
      >
        {title}
      </AlertDialogTitle>
      <DialogContent>
        <DialogContentText
          color="textPrimary"
          component="div"
          id="alert-dialog-description"
        >
          {children}
        </DialogContentText>
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
