import { forwardRef } from 'react'
import MuiSnackbar, {
  SnackbarCloseReason,
  SnackbarProps as MuiSnackbarProps,
} from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertProps } from '@material-ui/lab'
import useStyles from './styles'

export interface SnackbarProps extends MuiSnackbarProps {
  severity?: AlertProps['severity']
}

const Snackbar = forwardRef(
  ({ open, message, onClose, severity, ...rest }: SnackbarProps, ref) => {
    const classes = useStyles()
    const alertClasses = new Map<AlertProps['severity'], string>([
      ['info', classes.standardInfo],
    ])
    return (
      <MuiSnackbar
        action={
          onClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => onClose(e, 'close' as any)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )
        }
        message={severity ? undefined : message}
        open={open}
        ref={ref}
        onClose={onClose}
        {...rest}
      >
        {severity && (
          <Alert
            severity={severity}
            className={alertClasses.get(severity) ?? ''}
            onClose={(e) =>
              onClose && onClose(e, 'close' as SnackbarCloseReason)
            }
          >
            {message}
          </Alert>
        )}
      </MuiSnackbar>
    )
  }
)

export default Snackbar
