import { forwardRef } from 'react'
import TextField, { TextFieldProps } from 'baby-ui/foundation/TextField'
import DigitInputMask from './DigitInputMask'

export type MaskedDigitTextFieldProps = TextFieldProps & { length: number }

const MaskedDigitTextField = forwardRef<
  HTMLDivElement,
  MaskedDigitTextFieldProps
>(({ length, ...rest }, ref) => (
  <TextField
    {...(rest as any)}
    InputProps={{
      inputComponent: DigitInputMask as any,
      inputProps: { length, inputMode: 'numeric' },
    }}
    inputRef={ref}
    inputmode="numeric"
    pattern="[0-9]*"
  />
))

export default MaskedDigitTextField
