import { observer } from 'mobx-react-lite'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { useMutation } from '@tanstack/react-query'
import { useDMEStore } from '../../store'
import { searchPhysician } from '../../requests'
import { formSubmittedEvent, physicianSearchCompletedEvent } from '../../events'
import { apiErrorResponseAdapter } from '../../utils'
import { useStateOptions } from './useStateOptions'
import PhysicianForm, {
  PhysicianFormFactory,
  PhysicianFormValues,
} from '../PhysicianForm'

export interface PhysicianSearchFormProps {
  nextAction: () => void
}

export default observer(({ nextAction }: PhysicianSearchFormProps) => {
  const tracker = useTracking()
  const store = useDMEStore()

  const firePhysicianSearchCompletedEvent = (physicianResultCount: number) => {
    physicianSearchCompletedEvent(tracker)({
      resultCount: physicianResultCount,
    })
  }

  const fireFormSubmittedEvent = (status: string, error?: any) => {
    formSubmittedEvent(tracker)({
      formName: track.FormName.PHYSICIAN_SEARCH_FORM,
      status,
      errorMessage: error,
    })
  }

  const searchPhysicianSucceeded = (
    physicians: APIResponse.DME.Physician[]
  ) => {
    firePhysicianSearchCompletedEvent(physicians.length)
    fireFormSubmittedEvent(track.Status.SUCCESS)
    store.physician.setAll(physicians)
    nextAction()
  }

  const searchPhysicianFailed = (error: any) => {
    const adaptedError = apiErrorResponseAdapter(error)
    fireFormSubmittedEvent(track.Status.FAIL, adaptedError.message)
    store.ui.globalComponents.Snackbar.show(adaptedError.message)
  }

  const { mutate: searchPhysicianMutation, isLoading } = useMutation({
    mutationFn: searchPhysician,
    onSuccess: searchPhysicianSucceeded,
    onError: searchPhysicianFailed,
  })

  const { getStateAbbr } = useStateOptions()

  const onSubmit = (data: PhysicianFormValues) => {
    const { firstName, lastName, stateName, city } = data
    store.physician.setSearchInput({
      firstName: firstName!,
      lastName: lastName!,
      city: city!,
      stateName: stateName!,
    })
    searchPhysicianMutation({
      firstName: firstName!,
      lastName: lastName!,
      city: city!,
      stateAbbr: getStateAbbr(stateName!) || '',
    })
  }

  const defaultValues = PhysicianFormFactory.deriveDefaultValues(store)

  return (
    <PhysicianForm
      defaultValues={defaultValues}
      loading={isLoading}
      mode="search"
      onSubmit={onSubmit}
    />
  )
})
