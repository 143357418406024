import { Grid } from '@material-ui/core'
import TextField from 'baby-ui/foundation/TextField'
import { Control, Controller, FieldError } from 'react-hook-form'

interface InsuranceMemberIdFieldProps {
  control: Control<{
    insuranceMemberId: string
  }>
  error?: FieldError | null
}

// TODO: This is duplicated from CustomerInformationForm.tsx. Should we centralize it?
const MEMBER_ID_HELP_TEXT =
  'Please include all digits and letters in your Member ID. Do not include dashes or spaces.'

const InsuranceMemberIdField = ({
  control,
  error,
}: InsuranceMemberIdFieldProps) => (
  <Grid item xs={12}>
    <Controller
      fullWidth
      required
      as={TextField}
      control={control}
      error={!!error}
      helperText={error?.message || MEMBER_ID_HELP_TEXT}
      label="Member ID"
      name="insuranceMemberId"
      type="text"
    />
  </Grid>
)

export default InsuranceMemberIdField
