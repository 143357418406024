import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[100],
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: theme.spacing(2),
    },

    bar: {
      padding: theme.spacing(2),
    },

    summary: {
      borderTop: `2px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(2),
    },

    toggleButton: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
    },

    orderTotal: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
)
