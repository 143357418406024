import TextField, { TextFieldProps } from 'baby-ui/foundation/TextField'
import MaskedPhoneNumberTextField from 'baby-ui/foundation/MaskedPhoneNumberTextField'
import { ControllerProps } from 'react-hook-form'
import AutocompleteTextField from 'baby-ui/foundation/AutocompleteTextField/AutocompleteTextField'
import { US_STATE_NAMES } from 'shared/constants'
import PhysicianFirstNameTextField from '../PhysicianFirstNameTextField'

export const allFormFieldNamesInDisplayOrder = [
  'firstName',
  'lastName',
  'city',
  'stateName',
  'phoneNumber',
] as const

type AllFormFields = typeof allFormFieldNamesInDisplayOrder

export type InputName = AllFormFields[number]

export type InputSetting = {
  input: ControllerProps<any>['as']
  name: InputName
  options?: string[]
} & TextFieldProps

function withCommonSettings(inputSetting: InputSetting): InputSetting {
  return {
    ...inputSetting,
    required: true,
    fullWidth: true,
  }
}

export const firstNameInputSetting = withCommonSettings({
  input: PhysicianFirstNameTextField,
  label: "Provider's First Name",
  name: 'firstName',
  type: 'text',
})

export const lastNameInputSetting = withCommonSettings({
  input: TextField,
  label: "Provider's Last Name",
  name: 'lastName',
  type: 'text',
})

export const cityInputSetting = withCommonSettings({
  input: TextField,
  label: 'City',
  name: 'city',
  type: 'text',
})

export const phoneNumberInputSetting = withCommonSettings({
  input: MaskedPhoneNumberTextField,
  label: 'Healthcare Provider Phone Number',
  name: 'phoneNumber',
  type: 'tel',
})

export const stateInputSetting: InputSetting = {
  input: AutocompleteTextField,
  required: true,
  label: 'State',
  name: 'stateName',
  options: Object.values(US_STATE_NAMES),
}
