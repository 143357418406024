import useStyles from './styles'

interface LineProps {
  hidden?: boolean
  pending?: boolean
}

const Line = ({ hidden = false, pending = false }: LineProps) => {
  const classes = useStyles()
  const classNames = [
    classes.line,
    pending ? 'pending' : '',
    hidden ? 'hidden' : '',
  ].join(' ')

  return <div className={classNames} />
}

export default Line
