import React from 'react'
import { Card } from '../components'
import { ContentBlock, ContactLink } from '../components'

interface ProviderMissingCardProps {
  order: APIResponse.DME.Order
}
const ProviderMissingCard: React.FC<ProviderMissingCardProps> = ({
  order,
}: ProviderMissingCardProps) => {
  if (!order.providerRequired || !order.providerMissing) return null

  return (
    <Card testId="provider-missing-card">
      <ContentBlock title="Your action is required" variant="warn">
        We were unable to find your provider in our database and need one in
        order to bill insurance. Please email us at <ContactLink /> and include:
        <ul>
          <li>Provider's full name</li>
          <li>Provider's phone number</li>
          <li>City that your provider's office is located in</li>
          <li>State that your provider's office is located in</li>
          <li>A link to your provider's website</li>
        </ul>
      </ContentBlock>
    </Card>
  )
}

export default ProviderMissingCard
