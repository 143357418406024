import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import TextField, { TextFieldProps } from '../TextField'
import useStyles from './styles'

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  /**
   * Label for the input
   */
  label?: string
  variant?: 'default' | 'contrast' | 'contrastDisabled'
  textFieldProps?: Partial<TextFieldProps>
  required?: boolean
}

export default function Autocomplete<O = any>({
  label,
  variant = 'default',
  required = false,
  textFieldProps = {},
  ...rest
}: AutocompleteProps<O>) {
  const classes = useStyles()
  const { InputProps, inputProps, ...restOfTextFieldProps } = textFieldProps

  return (
    <MuiAutocomplete
      renderInput={({
        InputProps: ParamsInputProps,
        inputProps: paramsInputProps,
        ...restOfParams
      }) => (
        <TextField
          // @ts-ignore
          InputProps={{ ...ParamsInputProps, ...InputProps }}
          classes={{
            root: classNames({
              [classes.contrast]: variant === 'contrast',
              [classes.contrastDisabled]: variant === 'contrastDisabled',
            }),
          }}
          // @ts-ignore
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ ...paramsInputProps, ...inputProps }}
          required={required}
          label={label}
          {...restOfParams}
          {...(restOfTextFieldProps as any)}
        />
      )}
      size="small"
      {...rest}
    />
  )
}
