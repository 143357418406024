import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    step: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
    },

    icon: { width: 30, textAlign: 'center' },

    stepIcon: {
      display: 'block',
      margin: 'auto',
    },

    name: {
      [theme.breakpoints.down('sm')]: {
        width: 100,
      },
    },

    nameText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },

    pending: {
      color: theme.palette.grey[500],
    },

    date: {
      [theme.breakpoints.down('sm')]: {
        width: 100,
      },

      [theme.breakpoints.down('xs')]: {
        width: 60,
      },
    },

    tag: {
      fontSize: theme.spacing(1.25),
    },

    tagWarn: {
      color: theme.palette.secondary.main,
    },

    tagIcon: {
      width: 13,
      height: 13,
      marginRight: theme.spacing(0.5),
    },
  })
)
