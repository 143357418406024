import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { REQUEST_SUBSCRIPTION_LINK_ENDPOINT } from 'bl-health/requests/constants'

interface SendSubscriptionLinkPayload {
  email: string
}

export default function sendSubscriptionLink(
  payload: SendSubscriptionLinkPayload
): Promise<Response> {
  return fetch(REQUEST_SUBSCRIPTION_LINK_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
