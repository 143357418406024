import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    line: {
      height: 20,
      flexGrow: 1,
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        height: 10,
        width: 0,
      },

      '&::before': {
        border: `1px solid ${theme.palette.primary.main}`,
        content: '""',
        left: 0,
        position: 'absolute',
        top: '50%',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
          height: '100%',
          marginLeft: 14,
          top: 0,
        },
      },

      '&.hidden': {
        '&::before': {
          border: 'none',
        },
      },

      '&.pending': {
        '&::before': {
          borderColor: theme.palette.grey[500],
        },
      },
    },
  })
)
