import Link from 'baby-ui/foundation/Link/Link'
import useStyles from './styles'
import classnames from 'classnames'
import { LinkProps as MuiLinkProps } from '@material-ui/core/Link/Link'
import PhoneIcon from './PhoneIcon.svg'
import {
  SUPPORT_PHONE_NUMBER,
  FORMATTED_SUPPORT_PHONE_NUMBER,
} from 'bl-health/constants'

interface PhoneSupportProps
  extends Pick<MuiLinkProps, 'underline' | 'className'> {}
export default function PhoneSupport({
  className,
  underline = 'hover',
}: PhoneSupportProps) {
  const classes = useStyles()

  return (
    <Link
      aria-label="Call babylist health support"
      className={classnames(classes.phoneNumberRoot, className)}
      href={`tel:${SUPPORT_PHONE_NUMBER}`}
      rel="nofollow"
      underline={underline}
    >
      <PhoneIcon />
      <div className={classes.phoneNumber}>
        {FORMATTED_SUPPORT_PHONE_NUMBER}
      </div>
    </Link>
  )
}
