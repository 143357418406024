import * as React from 'react'
import { Link } from '@material-ui/core'
import { HEALTH_CONTACT_EMAIL, HEALTH_RX_EMAIL } from '../../../../constants'

interface Props {
  variant?: 'health' | 'rx'
}

const links = {
  health: HEALTH_CONTACT_EMAIL,
  rx: HEALTH_RX_EMAIL,
}

export function ContactLink({
  variant = 'health',
}: React.PropsWithChildren<Props>) {
  const link = links[variant]

  return (
    <Link href={`mailto:${link}`} style={{ fontWeight: 'bold' }}>
      {link}
    </Link>
  )
}
