import { Box, SvgIcon, useTheme } from '@material-ui/core'

interface Props {
  variant: 'cancel' | 'check'
}

export function OrderStatusIcon({ variant }: Props) {
  const theme = useTheme()

  return (
    <Box
      border={`2px solid ${theme.palette.primary.main}`}
      borderRadius={24}
      height={48}
      width={48}
    >
      {variant === 'cancel' && <CancelVariant />}
      {variant === 'check' && <CheckVariant />}
    </Box>
  )
}

const CheckVariant = () => {
  const theme = useTheme()

  return (
    <SvgIcon
      height={22}
      style={{ fill: 'none', marginLeft: 11, marginTop: 11 }}
      viewBox="0 0 22 15"
      width={22}
    >
      <path
        d="M1.69922 6.04688L8.50024 13.0005L20.5002 1.00049"
        stroke={theme.palette.primary.main}
        strokeWidth={2}
      />
    </SvgIcon>
  )
}

const CancelVariant = () => {
  const theme = useTheme()

  return (
    <SvgIcon
      height={22}
      style={{ marginLeft: 10, marginTop: 10 }}
      viewBox="0 0 22 22"
      width={22}
    >
      <path
        clipRule="evenodd"
        d="M12.2739 10.8038L21.7115 1.41811L20.3012 0L10.8558 9.3935L1.41039 0L7.44401e-05 1.41811L9.43766 10.8038L0 20.1896L1.41031 21.6077L10.8558 12.2142L20.3013 21.6077L21.7116 20.1896L12.2739 10.8038Z"
        fill={theme.palette.primary.main}
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}
