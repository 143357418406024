/* eslint-disable */
import { startSentry } from './dme/config/sentry'

if (
  !global._babelPolyfill &&
  (typeof window === 'undefined' || !window._babelPolyfill)
) {
  require('core-js/stable')
  require('regenerator-runtime/runtime')
}

const ns = typeof window === 'undefined' ? global : window
ns.React = require('react')
ns.ReactDOM = require('react-dom')
ns.ReactDOMServer = require('react-dom/server')

// global debounce for scroll-top
ns.deprecatedDebounce = require('lodash.debounce')

// Stub Polyfill for matchMedia which is used by enquire.js
// we don't actually care about properly polyfilling it, we just don't want
// an error thrown in IE 9 about it
ns.matchMedia = ns.matchMedia || require('shared/match-media-stub').default

// Add Babylist Health Tracking
if (typeof window !== 'undefined') {
  startSentry()
  ns.blAnalytics = require('./lib/babylistHealthAnalytics')
  ns.blAnalytics.initAnalytics({
    // Setting strict to false while we're ramping up our Avo setup.
    // Otherwise the errors would be loud and block rendering/tests, but
    // we just want to use the avo debugging tools.
    strict: false,
    env: window.__BL_ANALYTICS__.env,
    inspectorEnv: window.__BL_ANALYTICS__.inspector_env,
    branchId: window.__BL_ANALYTICS__.branch_id,
    appVersion: window.__BL_ANALYTICS__.app_version,
    noop: window.__BL_ANALYTICS__.env === window.__BL_ANALYTICS__.TEST,
  })
}

// global fetch
ns.fetch = ns.fetch || require('./lib/fetch').default

// global pubsub for times that it's needed
ns.PubSub = require('./lib/pubsub').shared()

ns.DMEApp = require('./dme/DMEApp').default
ns.DMENotFoundPage = require('./dme/DMENotFoundPage').default
ns.DMEHomePage = require('./dme/DMEHomePage').default
ns.DMEProductPage = require('./dme/DMEProductPage').default
ns.DMECatalogPage = require('./dme/DMECatalogPage').default
ns.ReactRailsUJS = require('react_ujs')

export {}
