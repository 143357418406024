import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { OTP_VERIFICATION_ENDPOINT } from './constants'

export interface OTPVerificationPayload {
  email?: string
  customerId?: number
  otp: string
}

const createOTPVerification = (
  payload: OTPVerificationPayload
): Promise<APIResponse.CustomerIdentifier> =>
  fetch(OTP_VERIFICATION_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })

export default createOTPVerification
