import MaskedInput from 'react-text-mask'

const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

interface PhoneNumberInputMaskProps {
  inputRef: (ref: HTMLElement | null) => void
}

export default function PhoneNumberInputMask({
  inputRef,
  ...rest
}: PhoneNumberInputMaskProps) {
  return (
    <MaskedInput
      {...rest}
      mask={phoneMask}
      placeholder="(123) 456-7890"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
    />
  )
}
