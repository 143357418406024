import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ThemeConstants } from '../../../dme/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    dateErrorWrapper: {
      marginTop: -theme.spacing(ThemeConstants.BASE_SPACING),
      '& $dateErrorText': {
        marginLeft: theme.spacing(ThemeConstants.BASE_SPACING),
      },
    },
    dateErrorText: {},
  })
)
