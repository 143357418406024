import {
  Box,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { HERO_IMAGE_URL } from 'bl-health/views/constants'
import InsurancePickerWidget from '../InsurancePickerWidget'
import StarburstBadge from '../../../baby-ui/compounds/StarburstBadge'
import useStyles from './styles'
import { HeroCopy, HeroImage } from '../../components'

const heroCopy = {
  title: ['Order your pump', 'through insurance!'],
  subtitle: [
    "We'll take care of contacting your provider",
    'and billing your insurance.',
  ],
}

export default function HeroBannerWidget() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <>
      <div>
        <HeroImage heroImageUrl={HERO_IMAGE_URL}>
          {isDesktop ? (
            <Container className={classes.heroContainer}>
              <Box
                className={classes.overlay}
                color="primary.contrastText"
                p={6}
                pl={8}
                pt={9}
              >
                <svg
                  className={classes.curvedText}
                  viewBox="0 0 120 120"
                  width="90"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M 35 150 a 60 -60 0 0 1 100 -100"
                    fill="none"
                    id="curve"
                  />
                  <text>
                    <textPath
                      fill="white"
                      fontSize="18"
                      fontWeight="bold"
                      href="#curve"
                      startOffset="54"
                      textLength="146"
                    >
                      SUPER PUMPED
                    </textPath>
                  </text>
                </svg>

                <Box pr={4}>
                  <HeroCopy {...heroCopy} />
                </Box>
                <Box py={3}>
                  <InsurancePickerWidget />
                </Box>
              </Box>
            </Container>
          ) : null}
        </HeroImage>

        <Container className={classes.starburstContainer}>
          <StarburstBadge
            className={classes.starburst}
            label={
              <Box color="primary.contrastText">
                <Typography
                  style={{ lineHeight: 1.1, fontSize: 24, fontWeight: 'bold' }}
                  variant="body1"
                >
                  YOU
                  <br />
                  GOT
                  <br />
                  THIS!
                </Typography>
              </Box>
            }
          />
        </Container>

        {!isDesktop && (
          <Container className={classes.mobileFormContainer}>
            <Box pt={4}>
              <HeroCopy {...heroCopy} />
            </Box>
            <Box pt={3}>
              <InsurancePickerWidget />
            </Box>
          </Container>
        )}
      </div>
    </>
  )
}
