import { createStyles, makeStyles } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

const ERROR_COLOR = '#D32F2F'

export default makeStyles((theme) =>
  createStyles({
    formContainer: {
      margin: 'auto',
      maxWidth: 400,
    },
    formTitle: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
      fontWeight: 500,
    },
    formSubtitle: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
    },
    selectInput: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
      marginTop: theme.spacing(ThemeConstants.BASE_SPACING * 2),
      '& #productId': {
        backgroundColor: 'white',
      },
      '& label.Mui-error': {
        color: ERROR_COLOR,
      },
      '& .MuiFormControl-root .MuiFormHelperText-root.Mui-error label.Mui-error':
        {
          color: ERROR_COLOR,
        },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: ERROR_COLOR,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: ERROR_COLOR,
      },
    },
    partsText: {
      color: '#757070',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING * 1.5),
      marginTop: theme.spacing(ThemeConstants.BASE_SPACING * 1.5),
    },
    checkbox: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING * 1.5),
      '& label > span:last-child': {
        color: '#757070',
        maxWidth: '450px',
      },
      '& button': {
        cursor: 'pointer',
        display: 'inline',
        fontWeight: 'normal',
        fontSize: '1.2rem',
        lineHeight: '1.5rem',
        position: 'relative',
        textAlign: 'left',
        top: '-2px',
        whiteSpace: 'normal',
      },
    },
  })
)
