import * as React from 'react'
import Button, { ButtonProps } from 'baby-ui/foundation/Button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
} from '@material-ui/core'
import { uniqueId } from 'lodash'
import classNames from 'classnames'
import { FilterItem, FilterTrigger } from 'baby-ui/types'
import { isCurrentFilterItemDisabled } from 'baby-ui/utils/isCurrentFilterItemDisabled'
import { FilterOperation } from 'baby-ui/lib/FilterOperation'
import { createControlledFilterItemsState } from 'baby-ui/utils/createControlledFilterItemsState'
import { createDisplayText } from './FilterButton.utils'
import useStyles from './FilterButton.styles'

export interface FilterButtonProps extends ButtonProps, FilterTrigger {}

const FilterButton = ({
  indexName,
  filters,
  allIndexes,
  initialProducts,
  children,
  items,
  onCheck,
  inputType = 'checkbox',
  operationType = FilterOperation.Union,
  ...rest
}: FilterButtonProps) => {
  const componentId = uniqueId(FilterButton.name)
  const checkedItems = items.filter(({ checked }) => checked)
  const classes = useStyles()
  const checkboxVariant = checkedItems.length ? 'contained' : 'outlined'
  const radioVariant = 'text'
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)

  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const isDisabled = (label: string) => {
    let disabled = false

    if (
      indexName &&
      allIndexes &&
      filters &&
      initialProducts &&
      inputType === 'checkbox'
    ) {
      disabled = isCurrentFilterItemDisabled({
        label,
        excludeIndex:
          operationType === FilterOperation.Union ? indexName : undefined,
        indexes: allIndexes,
        filters,
        products: initialProducts,
      })
    }

    return disabled
  }

  const handleOnCheck = (
    item: FilterItem,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updates = createControlledFilterItemsState(items, item, inputType)
    onCheck(updates, { checkedItem: item }, e)
  }

  return (
    <>
      <Button
        aria-haspopup
        aria-controls={open ? componentId : undefined}
        color="primary"
        endIcon={<KeyboardArrowDownIcon />}
        ref={anchorRef}
        tabIndex={0}
        variant={inputType === 'checkbox' ? checkboxVariant : radioVariant}
        onClick={handleToggle}
        {...rest}
      >
        {createDisplayText(children as string, checkedItems)}
      </Button>
      <Popper
        disablePortal
        transition
        anchorEl={anchorRef.current}
        modifiers={{
          // Modifier to keep popper within the boundaries.
          // We want to prevent the popper from overflowing past the edges of the browser.
          // More info: https://popper.js.org/docs/v1/#modifiers..preventOverflow
          preventOverflow: { boundariesElement: 'viewport' },
        }}
        open={open}
        role={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={componentId}
                  onKeyDown={handleListKeyDown}
                >
                  {items.map(({ label, name, checked }) => {
                    const disabled = isDisabled(label) && !checked

                    return (
                      <MenuItem
                        dense
                        className={classNames({
                          [classes.checkedItem]: checked,
                          [classes.disabledItem]: disabled,
                        })}
                        component="label"
                        disabled={disabled}
                        htmlFor={name}
                        key={name}
                      >
                        <FormControlLabel
                          control={
                            inputType === 'checkbox' ? (
                              <Checkbox
                                checked={checked}
                                color="primary"
                                disabled={disabled}
                                id={name}
                                inputProps={{ 'aria-labelledby': name }}
                                name={name}
                                size="small"
                                onChange={(e, isChecked) => {
                                  handleOnCheck(
                                    { name, label, checked: isChecked },
                                    e
                                  )
                                }}
                              />
                            ) : (
                              <Radio
                                checked={checked}
                                color="primary"
                                id={name}
                                inputProps={{ 'aria-labelledby': name }}
                                name={name}
                                size="small"
                                onChange={(e, isChecked) => {
                                  handleOnCheck(
                                    { name, label, checked: isChecked },
                                    e
                                  )
                                  setOpen(false)
                                }}
                              />
                            )
                          }
                          htmlFor={name}
                          label={label}
                          style={{ width: '100%' }}
                        />
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default FilterButton
