import { ProductReviews } from '../../../store/components/product-reviews'

export interface ProductReviewsWidgetProps {
  sku: string
}

export default function ProductReviewsWidget({
  sku,
}: ProductReviewsWidgetProps) {
  // @ts-ignore
  return <ProductReviews sku={sku} productSkus={[sku]} />
}
