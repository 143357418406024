import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    filterPanelHeader: {
      backgroundColor: theme.palette.background.default,
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.modal,
      borderBottom: `${theme.palette.divider} 1px solid`,
    },
    filterPanelHeaderInnerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
    filterPanelFooter: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.contrast,
      position: 'sticky',
      bottom: 0,
      borderTop: `${theme.palette.divider} 1px solid`,
    },
    linkDisabled: {
      color: theme.palette.text.secondary,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })
)
