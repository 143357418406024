const PhoneIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75063 1.5C2.46661 1.49966 1.53705 2.52521 1.70626 3.62728C2.08981 6.12543 3.00217 8.08585 4.41289 9.51712C5.82248 10.9472 7.78562 11.9059 10.3726 12.3032C11.474 12.4723 12.4989 11.5434 12.4989 10.2591L12.4989 9.53162C12.4989 9.41796 12.4222 9.31861 12.3123 9.28979L10.4122 8.79177C10.3509 8.7757 10.2858 8.78351 10.2301 8.81362L9.16278 9.38993C8.47235 9.76275 7.52531 9.88165 6.74114 9.33449C5.9722 8.79795 5.19984 8.01578 4.66959 7.24406C4.12848 6.45654 4.24738 5.50918 4.62498 4.81609L5.19547 3.76899C5.2252 3.71342 5.2333 3.64781 5.21732 3.58682L4.7193 1.6868C4.69048 1.57686 4.59113 1.50019 4.47746 1.50019L3.75063 1.5ZM0.223631 3.85492C-0.110135 1.68105 1.72073 -0.000531667 3.75103 1.26096e-07L4.47745 0.000190444C5.27298 0.000275917 5.96857 0.536928 6.17028 1.30648L6.6683 3.2065C6.78077 3.63559 6.7261 4.09137 6.51534 4.48169L6.51401 4.48416L5.94218 5.53373C5.75065 5.88527 5.7733 6.20164 5.90588 6.3946C6.33569 7.02013 6.98073 7.6726 7.59948 8.10434C7.78662 8.23492 8.09835 8.25999 8.45008 8.07006L9.51738 7.49375C9.9077 7.28299 10.3635 7.22832 10.7926 7.34079L12.6926 7.83881C13.4622 8.04053 13.9989 8.73602 13.9989 9.53163L13.9989 10.2591C13.9989 12.2886 12.3188 14.1196 10.1449 13.7858C7.30647 13.35 5.0246 12.2746 3.34459 10.5701C1.66571 8.86675 0.643872 6.59201 0.223631 3.85492Z"
      fill="#252324"
    />
  </svg>
)

export default PhoneIcon
