import { createStyles, makeStyles } from '@material-ui/core'

const DISABLED_COLOR = 'rgba(255,255,255, 0.3)'
const ENABLED_COLOR = 'rgba(255,255,255, 0.7)'

export default makeStyles(() =>
  createStyles({
    contrastDisabled: {
      '& .MuiInputLabel-root': {
        color: DISABLED_COLOR,
      },
      '& .MuiInputBase-input': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: DISABLED_COLOR,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: DISABLED_COLOR,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset, &.Mui-disabled fieldset':
          {
            borderColor: DISABLED_COLOR,
          },
      },
      '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
        color: DISABLED_COLOR,
      },
    },
    contrast: {
      '& .MuiInputLabel-root': {
        color: ENABLED_COLOR,
      },
      '& .MuiInputBase-input': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: ENABLED_COLOR,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: ENABLED_COLOR,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset, &:hover fieldset, &.Mui-focused fieldset, &.Mui-disabled fieldset':
          {
            borderColor: ENABLED_COLOR,
          },
      },
      '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
        color: ENABLED_COLOR,
      },
    },
  })
)
