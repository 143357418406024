import { useQuery } from '@tanstack/react-query'
import { getCartAddOns } from 'bl-health/requests'
import { useLayout } from 'bl-health/widgets/Layout'
import { useDMEStore } from 'bl-health/store'

export default function useMilkBagsCartAddOn() {
  const layout = useLayout()
  const store = useDMEStore()
  const enabled = store.context.inPumpFlow || layout?.milkBagsForPartsBuyers
  const { data } = useQuery({ queryFn: getCartAddOns, enabled })
  const addOn = data?.find(({ name }) => name === 'milk-bag')

  return {
    unitCount: addOn?.metadata.count,
    addOn,
  }
}
