import { TermsAndConditionsProps } from 'baby-ui/compounds/TermsAndConditions'
import { useDMEStore } from 'bl-health/store'
import BreastPumpAobDialog from 'bl-health/components/AobDialog/BreastPumpAobDialog'
import { ReplacementPartsAobDialog } from 'bl-health/components/AobDialog'

export type AoBDialogProps = Omit<TermsAndConditionsProps, 'title' | 'clauses'>

export default function AobDialog({
  open,
  onAgree,
  onDisagree,
}: AoBDialogProps) {
  const store = useDMEStore()

  if (store.context.inPumpFlow) {
    return (
      <BreastPumpAobDialog
        open={open}
        onAgree={onAgree}
        onDisagree={onDisagree}
      />
    )
  }

  return (
    <ReplacementPartsAobDialog
      open={open}
      onAgree={onAgree}
      onDisagree={onDisagree}
    />
  )
}
