import { catalogCategoryProductFactory } from 'factories'
import faker from 'faker'
import { Factory } from 'fishery'

export default Factory.define<APIResponse.HeroProduct>(({ sequence }) => {
  const imgUrl = faker.image.imageUrl()
  const priceNumerical = faker.datatype.number()
  const price = faker.commerce.price(priceNumerical)

  return {
    id: sequence,
    createdAt: faker.date.past().toISOString(),
    brand: faker.company.companyName(),
    category: faker.lorem.words(2),
    description: faker.lorem.sentences(1),
    genericProductId: sequence,
    heroPrice: price,
    heroUrl: faker.internet.url(),
    heroPath: '/gp/mockingbird-single-to-double-stroller/19982/417360',
    images: {
      thumbnailUrl: imgUrl,
      url: imgUrl,
    },
    maxPrice: price,
    minPrice: price,
    pricing: {
      msrp: priceNumerical,
      current: priceNumerical,
      min: priceNumerical,
      max: priceNumerical,
    },
    name: faker.lorem.sentences(1),
    onSale: false,
    options: {
      colors: {
        count: faker.datatype.number(),
        products: [
          { id: 417353, value: 'Sea/Windowpane Canopy With Black Leather' },
          { id: 417355, value: 'Sky/Windowpane Canopy With Black Leather' },
        ],
      },
      sizes: { count: 0, products: [] },
      styles: { count: 0, products: [] },
    },
    productOptionsCta: '+15 More Colors',
    productBadge: null,
    merchandisingMessage: '',
    showInStockSoon: false,
    rating: '4.8',
    reviewCount: 3121,
    shortName: 'Single-to-Double Stroller',
    isFavor: false,
    isCashfund: false,
    isCrowdfund: false,
    isElectronicGiftCard: false,
    isAvailableOnBabylist: true,
    availableProducts: catalogCategoryProductFactory.buildList(3),
    unavailableProducts: catalogCategoryProductFactory.buildList(2),
    features: [],
    scoredAttributes: [],
  }
})
