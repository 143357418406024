import { Container, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTracking } from 'lib/babylistHealthAnalytics'
import useStyles from './styles'
import GuidesWidget from '../../widgets/GuidesWidget'
import { Guide } from '../../../global'
import PumpsHomeView from '../PumpsHomeView'
import ReplacementPartsHomeView from '../ReplacementPartsHomeView'
import { useDMEStore } from 'bl-health/store'
import { homeViewedEvent } from 'bl-health/events'

export interface HomeViewProps {
  guides: Guide[]
}

export default observer(({ guides }: HomeViewProps) => {
  const classes = useStyles()
  const tracker = useTracking()
  const store = useDMEStore()
  const strategy = store.context.strategy ?? 'pumps'

  React.useEffect(() => {
    homeViewedEvent(tracker)()
  }, [])

  return (
    <div className={classes.homeViewWrapper}>
      {strategy === 'pumps' ? <PumpsHomeView /> : <ReplacementPartsHomeView />}

      {guides.length > 0 && (
        <Container className={classes.guideSection}>
          <Typography
            className={classes.heroText}
            color="primary"
            component="h2"
            id="guides"
            variant="h5"
          >
            <b>Breast Pump Guides</b>
          </Typography>

          <GuidesWidget openGuidesInNewTab guides={guides} numGuides={4} />
        </Container>
      )}
    </div>
  )
})
