export const DELETE_GENERIC_PRODUCT = 'DELETE_GENERIC_PRODUCT'
export const deleteGenericProduct = (genericProductId) => {
  return {
    type: DELETE_GENERIC_PRODUCT,
    genericProductId,
  }
}

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const deleteProduct = (product) => {
  return {
    type: DELETE_PRODUCT,
    product,
  }
}

export const DELETE_SINGLE_PRODUCT = 'DELETE_SINGLE_PRODUCT'
export const deleteSingleProduct = (productId) => {
  return {
    type: DELETE_SINGLE_PRODUCT,
    productId,
  }
}

export const DELETE_SINGLE_PRODUCT_OFFER = 'DELETE_SINGLE_PRODUCT_OFFER'
export const deleteSingleProductOffer = (productId, productOfferId) => {
  return {
    type: DELETE_SINGLE_PRODUCT_OFFER,
    productId,
    productOfferId,
  }
}

export const DELETE_PRODUCT_DOCUMENT = 'DELETE_PRODUCT_DOCUMENT'
export const deleteProductDocument = (genericProductId, productDocumentId) => {
  return {
    type: DELETE_PRODUCT_DOCUMENT,
    genericProductId,
    productDocumentId,
  }
}

export const DELETE_PRODUCT_OFFER = 'DELETE_PRODUCT_OFFER'
export const deleteProductOffer = (genericProductId, productOfferId) => {
  return {
    type: DELETE_PRODUCT_OFFER,
    genericProductId,
    productOfferId,
  }
}

export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const setActiveModal = (activeModal, modalProps = {}) => ({
  type: SET_ACTIVE_MODAL,
  activeModal,
  modalProps: activeModal ? modalProps : {},
}) // Clear ModalProps if clearing active modal

export const SET_BRANDS = 'SET_BRANDS'
export const setBrands = (brands) => {
  return {
    type: SET_BRANDS,
    brands,
  }
}

export const SET_BRAND = 'SET_BRAND'
export const setBrand = (brand) => {
  return {
    type: SET_BRAND,
    brand,
  }
}

export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND'
export const setSelectedBrand = (brand) => {
  return {
    type: SET_SELECTED_BRAND,
    brand,
  }
}

export const SET_CATALOG_CATEGORIES = 'SET_CATALOG_CATEGORIES'
export const setCatalogCategories = (catalogCategories) => {
  return {
    type: SET_CATALOG_CATEGORIES,
    catalogCategories,
  }
}

export const SET_GENERIC_PRODUCT = 'SET_GENERIC_PRODUCT'
export const setGenericProduct = (genericProduct) => {
  return {
    type: SET_GENERIC_PRODUCT,
    genericProduct,
  }
}

export const SET_INVENTORY_LOCATIONS = 'SET_INVENTORY_LOCATIONS'
export const setInventoryLocations = (inventoryLocations) => {
  return {
    type: SET_INVENTORY_LOCATIONS,
    inventoryLocations,
  }
}

export const SET_PRODUCT = 'SET_PRODUCT'
export const setProduct = (product) => {
  return {
    type: SET_PRODUCT,
    product,
  }
}

export const SET_PRODUCT_SEASONS = 'SET_PRODUCT_SEASONS'
export const setProductSeasons = (productSeasons) => {
  return {
    type: SET_PRODUCT_SEASONS,
    productSeasons,
  }
}

export const SET_SINGLE_PRODUCT = 'SET_SINGLE_PRODUCT'
export const setSingleProduct = (product) => {
  return {
    type: SET_SINGLE_PRODUCT,
    product,
  }
}

export const SET_SEARCH = 'SET_SEARCH'
export const setSearch = (search) => {
  return {
    type: SET_SEARCH,
    search,
  }
}

export const SET_GENERIC_PRODUCT_SEARCH_RESULTS =
  'SET_GENERIC_PRODUCT_SEARCH_RESULTS'
export const setGenericProductSearchResults = (genericProducts) => {
  return {
    type: SET_GENERIC_PRODUCT_SEARCH_RESULTS,
    genericProducts,
  }
}

export const SET_SINGLE_PRODUCT_SEARCH_RESULTS =
  'SET_SINGLE_PRODUCT_SEARCH_RESULT'
export const setSingleProductSearchResults = (products) => {
  return {
    type: SET_SINGLE_PRODUCT_SEARCH_RESULTS,
    products,
  }
}

export const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const setSuppliers = (suppliers) => {
  return {
    type: SET_SUPPLIERS,
    suppliers,
  }
}
