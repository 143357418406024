import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { MEMBER_CARD_CREATION_ENDPOINT } from './constants'

export interface MemberCardCreationPayload {
  memberCardFile: string | ArrayBuffer
  tag: string
  name: string | null
  orderId: string
}

export default async function createMemberCard(
  payload: MemberCardCreationPayload
): Promise<APIResponse.DME.MemberCard> {
  return fetch(MEMBER_CARD_CREATION_ENDPOINT(payload.orderId), {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
