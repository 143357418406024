import * as yup from 'yup'

const yearRegex = /^\d{4}$/
const monthRegex = /^(0?[1-9]|1[0-2])$/
const dayRegex = /^(0?[1-9]|[12][0-9]|3[01])$/

export const date = yup
  .string()
  .test({
    name: 'is-month-valid',
    test(value, ctx) {
      const month = value?.split('-')[1] || ''

      if (!monthRegex.test(month)) {
        return ctx.createError({
          message: 'Month must be between 1-12',
        })
      }

      return true
    },
  })
  .test({
    name: 'is-day-valid',
    test(value, ctx) {
      const day = value?.split('-')[2] || ''
      if (!dayRegex.test(day)) {
        return ctx.createError({
          message: 'Day must be between 1-31',
        })
      }

      return true
    },
  })
  .test({
    name: 'is-year-valid',
    test(value, ctx) {
      const year = value?.split('-')[0] || ''

      if (!yearRegex.test(year)) {
        return ctx.createError({
          message: 'Year is invalid',
        })
      }

      return true
    },
  })
  .test({
    name: 'is-day-valid-for-month',
    test(value, ctx) {
      const month = value?.split('-')[1] || ''
      const day = value?.split('-')[2] || ''

      if (month && day) {
        const parsedMonth = parseInt(month)
        const parsedDay = parseInt(day)

        if (parsedMonth === 2 && parsedDay > 29) {
          return ctx.createError({
            message: 'February cannot have more than 29 days',
          })
        }

        if ([4, 6, 9, 11].includes(parsedMonth) && parsedDay > 30) {
          return ctx.createError({
            message: 'This month cannot have more than 30 days',
          })
        }
      }

      return true
    },
  })
