import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@tanstack/react-query'
import useStyles from './styles'
import createCustomerPhysicianDetails from '../../requests/createCustomerPhysicianDetails'
import { useDMEStore } from '../../store'
import PhysicianForm, { PhysicianFormFactory } from '../../forms/PhysicianForm'

export interface PhysicianPhoneNumberFormProps {
  nextAction: () => void
}

const PhysicianPhoneNumberForm = observer(
  ({ nextAction }: PhysicianPhoneNumberFormProps) => {
    const classes = useStyles()

    const { mutate: createPhysicianDetailsMutation, isLoading } = useMutation({
      mutationFn: createCustomerPhysicianDetails,
      onSuccess: nextAction,
      onError: nextAction,
    })

    const store = useDMEStore()
    const defaultValues = PhysicianFormFactory.deriveDefaultValues(store)

    return (
      <>
        <Typography gutterBottom className={classes.disclaimer} variant="body1">
          Your healthcare provider's phone number (Doctor, doula, midwife, etc.)
          will help us reach out to your provider for a prescription.
        </Typography>
        <PhysicianForm
          defaultValues={defaultValues}
          loading={isLoading}
          mode="veiled"
          onSubmit={(values) => {
            createPhysicianDetailsMutation({
              firstName: values.firstName!,
              lastName: values.lastName!,
              addressState: values.stateName!,
              addressCity: values.city!,
              phoneNumber: values.phoneNumber!,
            })
          }}
        />
        <Typography
          className={classes.emailNotice}
          color="textSecondary"
          component="p"
          variant="caption"
        >
          We will email you if we need more information.
        </Typography>
      </>
    )
  }
)

export default PhysicianPhoneNumberForm
