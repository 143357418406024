import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { SUBSCRIPTION_PRODUCTS_ENDPOINT } from './constants'

const getSubscriptionProducts = async (
  subscriptionId: string
): Promise<APIResponse.HeroProduct[]> => {
  const response = await fetch(SUBSCRIPTION_PRODUCTS_ENDPOINT(subscriptionId), {
    method: RequestMethod.GET,
  })
  return response.data.heroProducts
}

export default getSubscriptionProducts
