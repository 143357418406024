import { useState } from 'react'

export interface ScoredAttributeControls {
  snackbar: {
    [key: string]: boolean
  }
  openSnackbar: (attribute: string) => void
  closeSnackbar: (attribute: string) => void
}

export const useScoredAttributes = () => {
  const [snackbar, setSnackbar] = useState({})

  const openSnackbar = (attribute: string) => {
    setSnackbar({ [attribute]: true })
  }

  const closeSnackbar = (attribute: string) => {
    setSnackbar({ ...snackbar, [attribute]: false })
  }

  return { snackbar, openSnackbar, closeSnackbar } as ScoredAttributeControls
}
