import { removeAllNonDigitChar } from 'baby-ui/utils'
import { DME } from '../../../form-types'

export interface CustomerInformationInputs
  extends DME.CustomerInformationFormInputs {
  aobAcknowledged: boolean
  gender: string
}

export default function customerInformationInputsToCustomerPayloadAdapter(
  customerInformation: CustomerInformationInputs
) {
  return {
    smsAllowed: customerInformation.smsAllowed,
    aobAcknowledged: customerInformation.aobAcknowledged,
    email: customerInformation.email,
    firstName: customerInformation.firstName,
    lastName: customerInformation.lastName,
    phoneNumber: removeAllNonDigitChar(customerInformation.phoneNumber),
    phiCustomerInsurance: {
      insuranceMemberId: customerInformation.memberId,
    },
    phiCustomer: {
      dueDate: customerInformation.dueDate,
      birthDate: customerInformation.birthDate,
      gender: customerInformation.gender,
    },
  }
}
