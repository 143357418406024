import { useState, useEffect } from 'react'

export interface UseIsScrollingConfig {
  delay?: number
  listenDelay?: number
}
export const useIsScrolling = ({
  delay = 500,
  listenDelay = 0,
}: UseIsScrollingConfig = {}) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [scrollStatus, setScrollStatus] = useState<
    'never' | 'pending' | 'stopped'
  >('never')

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const handleScroll = () => {
      clearTimeout(timeout)
      setIsScrolling(true)

      timeout = setTimeout(() => {
        setIsScrolling(false)
      }, delay)
    }

    setTimeout(() => {
      window.addEventListener('scroll', handleScroll)
    }, listenDelay)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isScrolling && scrollStatus === 'never') {
      setScrollStatus('pending')
    } else if (scrollStatus === 'pending') {
      setScrollStatus('stopped')
    }
  }, [isScrolling])

  return [isScrolling, { scrollStatus }] as const
}
