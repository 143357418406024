import {
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Card from 'baby-ui/foundation/Card'
import Rating from 'baby-ui/foundation/Rating'
import useStyles from './styles'

export default function ProductCardSkeleton() {
  const classes = useStyles()

  return (
    <Card>
      <CardActionArea>
        <Skeleton height={164} variant="rect" />
        <CardContent>
          <Typography color="textSecondary" variant="caption">
            <Skeleton />
          </Typography>
          <Typography noWrap variant="subtitle2">
            <Skeleton />
          </Typography>
          <Rating value={0} />
          <Typography component="p" variant="subtitle2">
            <Skeleton width={50} />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Skeleton
          className={classes.productCardSkeletonButton}
          height={31}
          variant="rect"
        />
      </CardActions>
    </Card>
  )
}
