import { ProductFilter } from 'baby-ui/lib/ProductFilter'
import { omit } from 'lodash'
import { createProductFilterGraph } from 'baby-ui/utils/createProductFilterGraph'
import { createProductFilterMatrix } from 'baby-ui/utils/createProductFilterMatrix'
import { BRAND_INDEX, FEATURES_INDEX } from 'baby-ui/shared/constants'

type Product = APIResponse.HeroProduct

interface CurrentFilterItemDisabledOptions {
  filters: Record<string, string[]>
  products: Product[]
  indexes: string[]
  excludeIndex?: string
  label: string
}
export const isCurrentFilterItemDisabled = ({
  filters,
  products,
  indexes,
  excludeIndex,
  label,
}: CurrentFilterItemDisabledOptions) => {
  const filtersToApply = excludeIndex ? omit(filters, excludeIndex) : filters
  const matrix = createProductFilterMatrix(
    filtersToApply[BRAND_INDEX] || [],
    /**
     * The "features" and "included" filter is a special case because instead of
     * showing all products that meet one of the criteria like with "brands" we
     * only want to show products that meet all of the checked criteria. Since the
     * filter intersection function will take the union of each criteria array, we
     * need to pass each individual criteria for features and included as a separate array.
     * Hence the map function and destructuring.
     * Example: [[brand1, brand2], [feature3, feature4]] => [[brand1, brand2], [feature3], [feature4]]
     */
    ...(filtersToApply[FEATURES_INDEX]?.map((f: string) => [f]) || []),
    ...(filtersToApply?.included?.map((f: string) => [f]) || [])
  )
  const productFilter = new ProductFilter(products, indexes)
  const results = matrix.length
    ? productFilter.intersection(matrix)
    : productFilter.products
  const graph = createProductFilterGraph(results, indexes)
  const count = graph.get(label)?.size || 0
  return count === 0
}
