import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface UnsupportedInsuranceProviderSelectedMetadata {
  insuranceName: string
}

export const unsupportedInsuranceProviderSelectedEvent =
  (tracker: any) =>
  ({ insuranceName }: UnsupportedInsuranceProviderSelectedMetadata) => {
    const payload = decorateEventPayload({
      event: track.unsupportedInsuranceProviderSelected,
      insuranceName,
    })
    tracker.trackEvent(payload)
  }
