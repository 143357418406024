import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { Controller, useForm } from 'react-hook-form'
import Checkbox from 'baby-ui/foundation/CheckBox'
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { ReplacementPartsAobDialog } from 'bl-health/components/AobDialog'
import { ReplacementPartsFormSchema } from './validationSchema'
import useStyles from './styles'
import { STORE_NAME } from '../../constants'

export interface ReplacementPartsFormProps {
  formErrorMessage: string
  heroProducts: APIResponse.HeroProduct[]
  loading: boolean
  onError: (error: APIResponse.DME.SubscriptionErrorType) => void
  onSubmit: (params: SubmittedFormValues) => void
}

interface SubmittedFormValues {
  productId: number
  aobAcknowledged: boolean
}

export interface ReplacementPartsFormValues {
  productId?: APIResponse.HeroProduct['id']
  aobAcknowledged: boolean
}

const ReplacementPartsForm = observer(
  ({
    formErrorMessage,
    heroProducts,
    loading,
    onSubmit,
  }: ReplacementPartsFormProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const tracker = useTracking()

    const productOptions = heroProducts
      .map(
        (heroProduct) => heroProduct.products || heroProduct.availableProducts
      )
      .flat()

    const { control, errors, handleSubmit, setValue, watch } =
      useForm<ReplacementPartsFormValues>({
        defaultValues: {
          aobAcknowledged: false,
          productId: undefined,
        },
        validationContext: { productOptions },
        validationSchema: ReplacementPartsFormSchema,
      })
    const [showAOBDialog, setShowAOBDialog] = useState<boolean>(false)

    const watchProductId = watch('productId')
    const watchAobAcknowledged = watch('aobAcknowledged')

    const onAgreeToAOB = () => {
      setValue('aobAcknowledged', true)
      setShowAOBDialog(false)
    }
    const onDisagreeToAOB = () => {
      setValue('aobAcknowledged', false)
      setShowAOBDialog(false)
    }

    const submitHandler = (data: ReplacementPartsFormValues) => {
      if (data.productId === undefined) return

      onSubmit({
        productId: data.productId,
        aobAcknowledged: data.aobAcknowledged,
      })
    }

    const handleProductIdChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const newValue = Number(event.target.value)
      setValue('productId', newValue, true)
    }

    useEffect(() => {
      tracker.trackEvent({
        event: track.replacementPartsOrderFormViewed,
        storeName: STORE_NAME,
      })
    }, [])

    return (
      <Box
        bgcolor={theme.palette.background.contrast}
        flexGrow={1}
        px={{ xs: 4, sm: 0 }}
        py={8}
      >
        <Box className={classes.formContainer}>
          <ReplacementPartsAobDialog
            open={showAOBDialog}
            onAgree={onAgreeToAOB}
            onDisagree={onDisagreeToAOB}
          />

          <Typography
            className={classes.formTitle}
            color="primary"
            component="h1"
            variant="h4"
          >
            You're eligible for free replacement parts!
          </Typography>
          <Typography
            className={classes.formSubtitle}
            color="primary"
            variant="body2"
          >
            Plus, shipping is on us.
          </Typography>

          <form onSubmit={handleSubmit(submitHandler)}>
            {formErrorMessage && (
              <Alert severity="error" variant="standard">
                <span>{formErrorMessage}</span>
              </Alert>
            )}
            <div className={classes.selectInput}>
              <FormControl
                fullWidth
                error={!!errors.productId}
                variant="outlined"
              >
                <InputLabel htmlFor="productId">
                  Select your flange size:{' '}
                </InputLabel>
                <Controller
                  aria-required
                  required
                  as={(props) => (
                    <Select {...props} native onChange={handleProductIdChange}>
                      <option aria-label="None" />
                      {productOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.attributes?.size}
                        </option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  id="productId"
                  label="Select your flange size"
                  name="productId"
                />
                {errors.productId && (
                  <FormHelperText>{errors.productId.message}</FormHelperText>
                )}
              </FormControl>
            </div>

            <Typography
              className={classes.partsText}
              component="div"
              variant="body2"
            >
              If you are still using your pump, your insurance can cover the
              following items:
              <ul>
                <li>2 sets of breast flanges</li>
                <li>2 sets of tubing</li>
                <li>2 backflow protectors</li>
                <li>2 duckbill valves</li>
                <li>2 wide neck milk bottles with cap, disc, and lid</li>
              </ul>
            </Typography>

            <FormControl fullWidth className={classes.checkbox}>
              <Controller
                required
                as={Checkbox}
                control={control}
                label={
                  <Typography variant="caption">
                    I confirm that I'm still pumping and need breast pump
                    replacement parts. I also agree to the{' '}
                    {/* component=button means this is rendering a button, not an anchor */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      component="button"
                      type="button"
                      underline="hover"
                      variant="inherit"
                      onClick={() => {
                        setShowAOBDialog(true)
                      }}
                    >
                      Assignment of Benefits
                    </Link>
                    .
                  </Typography>
                }
                name="aobAcknowledged"
              />
              {errors.aobAcknowledged && (
                <FormHelperText>
                  {errors.aobAcknowledged.message}
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton
              fullWidth
              color="primary"
              disabled={
                !watchProductId ||
                !watchAobAcknowledged ||
                loading ||
                !!errors.productId
              }
              loading={loading}
              type="submit"
              variant="contained"
            >
              Continue
            </LoadingButton>
          </form>
        </Box>
      </Box>
    )
  }
)

export default ReplacementPartsForm
