import { createStyles, makeStyles } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme) =>
  createStyles({
    toastWrapper: {
      position: 'absolute',
      padding: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 9999,
      top: 0,
      maxWidth: 780,
      width: '100%',
    },
    toastContent: {
      textAlign: 'left',
      background: '#f8e9dd',
      color: theme.palette.primary.main,
    },
    centerTextOnMobile: {
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    curvedText: {
      position: 'absolute',
      left: theme.spacing(ThemeConstants.BASE_SPACING * 1.5),
      top: theme.spacing(ThemeConstants.BASE_SPACING * 1.8),
    },
    heroContainer: {
      position: 'relative',
      height: '100%',
    },
    overlay: {
      position: 'absolute',
      width: 453,
      height: '100%',
      right: 0,
      top: 0,
      background: 'rgba(74, 36, 68, 0.8)',
      backdropFilter: 'blur(10px)',
    },
    starburstContainer: {
      position: 'relative',
    },
    starburst: {
      position: 'absolute',
      bottom: -1 * theme.spacing(ThemeConstants.BASE_SPACING),
      left: 0,
      [theme.breakpoints.down('sm')]: {
        bottom: -3 * theme.spacing(ThemeConstants.BASE_SPACING),
        left: theme.spacing(ThemeConstants.BASE_SPACING * 0.5),
        width: 108,
      },
    },
    mobileFormContainer: {
      paddingBottom: theme.spacing(4),
      background: theme.palette.background.contrast,
    },
  })
)
