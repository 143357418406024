import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { parseISO } from 'date-fns'
import { Step, Tag } from './Step'
import useStyles from './styles'
import { CheckIcon, InsuranceIcon, ProviderIcon, ShippingIcon } from '../icons'

interface Props {
  order: APIResponse.DME.Order
}

export function ProgressIndicator({ order }: Props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const createdAt = parseISO(order.createdAt)
  const classes = useStyles()

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      style={{ flexGrow: 1 }}
    >
      <Step
        first
        date={createdAt}
        icon={<CheckIcon className={classes.stepIcon} />}
        id="confirmed"
        name="Confirmed"
      />
      <InsuranceStep order={order} />
      {order.prescriptionRequired && <PrescriptionStep order={order} />}
      {order.providerRequired && <ProviderStep order={order} />}
      <ShippingStep order={order} />
    </Grid>
  )
}

const insuranceTagMap: {
  [status in APIResponse.DME.Order['insuranceStatus']]: JSX.Element
} = {
  valid: <Tag variant="success">Valid</Tag>,
  validating: <Tag variant="warn">Validating</Tag>,
}

const InsuranceStep = ({ order }: Props) => {
  const tag = insuranceTagMap[order.insuranceStatus]
  const date = parseISO(order.insuranceUpdatedAt)
  const classes = useStyles()

  return (
    <Step
      date={date}
      extra={tag}
      icon={<InsuranceIcon className={classes.stepIcon} />}
      id="insurance"
      name="Insurance"
    />
  )
}

const ProviderStep = ({ order }: Props) => {
  const classes = useStyles()
  const tag = order.providerMissing ? (
    <Tag variant="warn">Info Needed</Tag>
  ) : (
    <Tag variant="success">Received</Tag>
  )
  const date = order.providerUpdatedAt
    ? parseISO(order.providerUpdatedAt as string)
    : null

  return (
    <Step
      date={date}
      extra={tag}
      icon={<ProviderIcon className={classes.stepIcon} />}
      id="provider"
      name="Provider"
      nextStepPending={shippingStepPending(order)}
    />
  )
}

const PrescriptionStep = ({ order }: Props) => {
  const classes = useStyles()
  let tag = null

  if (order.prescriptionMissing) {
    tag = (
      <Tag variant="warn">
        {order.prescriptionInvalid ? 'Invalid Rx' : 'Rx Needed'}
      </Tag>
    )
  } else {
    tag = <Tag variant="success">Rx Received</Tag>
  }
  const date = order.prescriptionUpdatedAt
    ? parseISO(order.prescriptionUpdatedAt as string)
    : null

  return (
    <Step
      date={date}
      extra={tag}
      icon={<ProviderIcon className={classes.stepIcon} />}
      id="prescription"
      name="Prescription"
      nextStepPending={shippingStepPending(order)}
    />
  )
}

const ShippingStep = ({ order }: Props) => {
  const classes = useStyles()
  const stepEnabled = !shippingStepPending(order)

  const shippingTag = (status: string): JSX.Element => {
    switch (status) {
      case 'shipped':
        return <Tag variant="info">Shipped</Tag>
      case 'delivered':
        return <Tag variant="success">Delivered</Tag>
      default:
        return <Tag variant="info">Estimated Ship</Tag>
    }
  }

  const shippingDate = (): Date | null => {
    const safeParseISO = (date: string | null) => (date ? parseISO(date) : null)

    switch (order.status) {
      case 'shipped':
        return safeParseISO(order.shippedAt)
      case 'delivered':
        return safeParseISO(order.deliveredAt)
      default:
        return order.insuranceShippingEligibilityDays
          ? safeParseISO(order.estimatedShippingDate)
          : null
    }
  }

  const stepName = (status: string): string => {
    switch (status) {
      case 'shipped':
        return 'On its way'
      case 'delivered':
        return 'Delivered'
      default:
        return 'Shipped'
    }
  }

  const date = shippingDate()
  const tag = date ? shippingTag(order.status) : null
  const name = stepName(order.status)
  return (
    <Step
      last
      date={date}
      extra={tag}
      icon={
        <ShippingIcon
          className={`${classes.stepIcon} shipping`}
          color={stepEnabled ? 'primary' : 'disabled'}
        />
      }
      id="shipping"
      name={name}
      pending={!stepEnabled}
    />
  )
}

const shippingStepPending = ({ status }: APIResponse.DME.Order) =>
  status !== 'shipped' && status !== 'delivered'
