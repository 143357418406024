import React from 'react'
import PhysicianFormFactory, {
  PhysicianFormMode,
  PhysicianFormValues,
} from './PhysicianFormFactory'
import { gridColSizeFor } from './helpers'

interface PhysicianFormHookOptions {
  defaultValues?: PhysicianFormValues
}

export default function usePhysicianForm(
  mode: PhysicianFormMode,
  options: PhysicianFormHookOptions = {}
) {
  const { defaultValues = {} } = options
  // Using a ref to avoid re-creating the inputs on every render
  const factory = React.useRef(new PhysicianFormFactory(mode, defaultValues))
  // Not using `React.useMemo` here because the feature flag is SSR-only, and
  // will not dynamically change during the lifetime of the component.
  const inputsProps = React.useRef(factory.current.createInputs())

  const formInputs = React.useMemo(
    () =>
      inputsProps.current.map((input) => ({
        props: input,
        meta: { colSize: gridColSizeFor(input) },
      })),
    []
  )

  const button = React.useRef(factory.current.createButton())

  return { submitButton: { props: button.current }, formInputs }
}
