import React from 'react'
import { Card } from '../components'
import { Box } from '@material-ui/core'
import { ContentBlock } from '../components'
import InsuranceCards from '../MemberCard/InsuranceCards'
import { ContentBlockProps } from 'bl-health/views/OrderView/components/ContentBlock/ContentBlock'

interface InsuranceUploadCardProps {
  order: APIResponse.DME.Order
}

const InsuranceUploadCard: React.FC<InsuranceUploadCardProps> = ({
  order,
}: InsuranceUploadCardProps) => {
  if (!order.hasNikoHealthOrder) return null

  const areCardsPresent = order.memberCardFront && order.memberCardBack

  let contentBlockVariant: ContentBlockProps['variant'] = undefined
  if (areCardsPresent) {
    contentBlockVariant = 'success'
  } else if (order.insuranceStatus !== 'valid') {
    contentBlockVariant = 'warn'
  }

  const contentBlockTitle: string =
    order.insuranceStatus === 'valid'
      ? 'Member ID upload (optional)'
      : 'Insurance next steps'

  return (
    <Card testId="insurance-upload-card">
      <ContentBlock title={contentBlockTitle} variant={contentBlockVariant}>
        {order.disableInsuranceCardUpload ? (
          <Box mb={2}>
            Please send in your DBN to{' '}
            <a href="mailto:health@babylist.com">health@babylist.com</a>.
          </Box>
        ) : (
          <>
            Please upload a front and back image of your insurance card in case
            we need to verify your insurance. If there are concerns with your
            insurance, we will email directly.
            <InsuranceCards order={order} />
          </>
        )}
      </ContentBlock>
    </Card>
  )
}

export default InsuranceUploadCard
