import { useState } from 'react'
import { Grid, Theme, useMediaQuery } from '@material-ui/core'
import { Control, Controller, FieldError } from 'react-hook-form'
import Autocomplete from 'baby-ui/foundation/AutoComplete'
import FullScreenPanelTableWidget from '../../../widgets/FullScreenPanelTableWidget/FullScreenPanelTableWidget'

interface InsuranceStateFieldProps {
  data: string[]
  error?: FieldError | null
  selectedValue: string | null
  onChange: (value: string | null) => void
  control: Control<{
    insuranceState: string
  }>
}

const InsuranceStateField = ({
  control,
  data,
  error,
  selectedValue,
  onChange,
}: InsuranceStateFieldProps) => {
  const [panelOpen, setPanelOpen] = useState(false)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <>
      <FullScreenPanelTableWidget
        name="insuranceState"
        open={panelOpen}
        options={data}
        selected={selectedValue}
        title="Shipping State"
        onClose={() => setPanelOpen(false)}
        onOptionSelect={(value) => {
          onChange(value)
          setPanelOpen(false)
        }}
      />
      <Grid
        item
        xs={12}
        onClick={() => {
          if (!isDesktop) {
            setPanelOpen(true)
          }
        }}
      >
        <Controller
          aria-required
          required
          as={(props) => (
            <Autocomplete<string>
              {...props}
              value={selectedValue}
              onChange={(_, newValue, _reason) => {
                onChange(newValue)
              }}
            />
          )}
          control={control}
          disableClearable={!isDesktop}
          label="Shipping State"
          name="insuranceState"
          options={data}
          textFieldProps={{
            error: !!error,
            helperText: error?.message,
            readOnly: !isDesktop,
          }}
        />
      </Grid>
    </>
  )
}

export default InsuranceStateField
