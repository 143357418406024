import { omit } from 'lodash'
import {
  DELETE_SINGLE_PRODUCT,
  DELETE_SINGLE_PRODUCT_OFFER,
  SET_SINGLE_PRODUCT,
} from '../actions'

const product = (state, action) => {
  switch (action.type) {
    case DELETE_SINGLE_PRODUCT_OFFER:
      return {
        ...state,
        productOffers: state.productOffers.filter(
          (productOffer) => productOffer.id != action.productOfferId
        ),
      }
    case SET_SINGLE_PRODUCT:
      return { ...state, ...action.product }
    default:
      return state
  }
}

export default product
