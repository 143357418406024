import Product from 'bl_admin/product-management/reducers/product'
import { createProductFilterGraph } from 'baby-ui/utils/createProductFilterGraph'

export type Product = APIResponse.HeroProduct

export class ProductFilter {
  public readonly products: Product[]

  protected readonly graph: Map<string, Set<Product>>

  constructor(products: Product[], indexes: string[]) {
    const productsInStock = products.filter(
      ({ isAvailableOnBabylist }) => isAvailableOnBabylist
    )

    this.products = productsInStock
    this.graph = createProductFilterGraph(productsInStock, indexes)
  }

  /**
   * Accepts an array of index keys. Each key should only belong to a single index.
   *
   * @param keyVector An array of index keys.
   * @returns An array of unique products that has a matching key in the given key vector.
   */
  union(keyVector: string[]): Product[] {
    const productSet = new Set<Product>()
    keyVector.forEach((key) => {
      this.graph.get(key)?.forEach((product) => productSet.add(product))
    })
    return Array.from(productSet)
  }

  /**
   * Accepts a two dimensional array representing a matrix of index keys. Each
   * array should only have keys that belong to a single index.
   * This method will take the union of each vector in the matrix, and then the intersection
   * of those unions.
   *
   * @param keyMatrix: A two dimensional array of index keys.
   * @returns An array of unique products that has a matching key for each vector in the matrix.
   */
  intersection(keyMatrix: string[][]): Product[] {
    let productSet = new Set<Product>()
    keyMatrix.forEach((keyVector) => {
      const products = this.union(keyVector)

      if (productSet.size === 0) {
        productSet = new Set(products)
      } else {
        const intersection = new Set<Product>()
        products.forEach((product) => {
          if (productSet.has(product)) {
            intersection.add(product)
          }
        })
        productSet.clear()
        intersection.forEach((product) => productSet.add(product))
      }
    })

    return Array.from(productSet)
  }
}
