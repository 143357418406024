import { Factory } from 'fishery'
import faker from 'faker'
import { CurrentUser } from '../global'

export default Factory.define<CurrentUser>(() => ({
  id: 1,
  createdAt: '2023-10-10 18:23:42 UTC',
  addedBabylistButton: true,
  availableCredit: 0.0,
  currentDevicePlatform: 'web',
  email: faker.internet.email(),
  emailPreferences: {
    babyRegistry101: true,
    leadsDrip: false,
    newsletter: true,
    pregnancyWeekly: false,
    sponsored: true,
    announcements: true,
    priceStockAlerts: true,
    reservationAlerts: true,
    giftGiverPartnerEmail: false,
    giftGiverGiftAdvice: false,
    id: 1,
  },
  pushNotificationPreferences: {
    welcomeSeries: true,
  },
  events: [
    {
      createdAt: '2023-10-24 17:59:43 UTC',
      name: 'added_babylist_button',
      updatedAt: '2023-10-24 17:59:43 UTC',
      userId: 1,
      value: true,
    },
  ],
  family: {
    expectingChildren: [],
    hasPartner: false,
    planningStage: 'Trying to conceive',
  },
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  admin: true,
  hasMultipleRegistries: true,
  registries: [
    {
      id: 1,
      role: 'owner',
      regItemsCount: 27,
      title: 'Test Baby Registry',
      urlSlug: 'test-registry',
    },
  ],
  isGiftGiver: false,
  isGiftGiverOnly: false,
  giftGiverRegistries: [],
  registryId: 1,
  registryAddress: {
    city: faker.address.city(),
    country: faker.address.country(),
    id: 1,
    avsConfirmed: false,
    userConfirmed: false,
    name: faker.name.findName(),
    needsConfirmation: false,
    phone: faker.phone.phoneNumber(),
    state: faker.address.stateAbbr(),
    streetAddress1: faker.address.streetAddress(),
    taxRate: 0.0,
    validationMetadata: null,
    zip: faker.address.zipCode(),
    privateAddress: null,
  },
  registryUrlSlug: 'test-registry',
  currentRegistry: {
    id: 1,
    address: {
      city: faker.address.city(),
      country: faker.address.country(),
      id: 1,
      avsConfirmed: false,
      userConfirmed: false,
      name: faker.name.findName(),
      needsConfirmation: false,
      phone: faker.phone.phoneNumber(),
      state: faker.address.stateAbbr(),
      streetAddress1: faker.address.streetAddress(),
      taxRate: 0.0,
      validationMetadata: null,
      zip: faker.address.zipCode(),
      privateAddress: null,
    },
    cashFundPreferences: null,
    currentUserRole: 'owner',
    ownerId: 1,
    ownerHasBankAccount: false,
    title: 'Test Baby Registry',
    url: 'https://www.babylist.com/test-registry',
    urlSlug: 'test-registry',
    type: null,
  },
  turnToJwt: faker.random.alphaNumeric(),
  coregistrations: [],
  consentedToPromotionalOffers: false,
  experiments: [],
  isBLDMECustomer: false,
}))
