import React, { useState } from 'react'
import { Divider } from '@material-ui/core'
import FilterButton from 'baby-ui/compounds/FilterButton/FilterButton'
import FilterMenu from 'baby-ui/compounds/FilterMenu/FilterMenu'
import { FilterItem } from 'baby-ui/types'
import FilterPanel from 'baby-ui/compounds/FilterPanel/FilterPanel'
import FilterBar from 'baby-ui/compounds/FilterBar/FilterBar'
import { useStateManager } from 'baby-ui/hooks/useStateManager'
import { BRAND_INDEX, FEATURES_INDEX } from 'baby-ui/shared/constants'
import { FilterOperation } from 'baby-ui/lib/FilterOperation'
import { createProductFilterMatrix } from 'baby-ui/utils/createProductFilterMatrix'
import { useTracking } from 'lib/babylistHealthAnalytics'
import { createCheckedItemLabelList } from './EnhancedProductFilterWidget.utils'
import useStyles from './EnhancedProductFilterWidget.styles'
import {
  filtersAppliedEvent,
  FiltersAppliedEventMetadata,
} from '../../events/filtersAppliedEvent'
import { FilterSettings } from '../../utils/FilterSettings'
import ProductFilterMenu from './ProductFilterMenu'
import ProductFilterSortCategory from '../../utils/ProductFilterSortCategory'

type Product = APIResponse.HeroProduct

export interface EnhancedProductFilterWidgetProps {
  allProducts: Product[]
  applyFilter: (matrix: string[][]) => void
  applySort: (criteria: string) => void
  initialSettings?: FilterSettings
}

export default function EnhancedProductFilterWidget({
  allProducts,
  applyFilter,
  applySort,
  initialSettings,
}: EnhancedProductFilterWidgetProps) {
  const pfMenu = new ProductFilterMenu(allProducts)
  const menuStates = React.useRef(pfMenu.createStates(initialSettings))
  const sortItemsState = useStateManager({ sort: menuStates.current.sort })
  const filterItems = useStateManager({
    brand: menuStates.current.brand,
    features: menuStates.current.features,
    included: menuStates.current.included,
  })

  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const filters = useStateManager({
    [BRAND_INDEX]: [] as string[],
    [FEATURES_INDEX]: [] as string[],
    included: [] as string[],
  })
  const sortButtonLabel = pfMenu
    .checkedMenuItemsFrom(sortItemsState.value.sort)
    .pop()!.label
  const checkedFilterItemsCount = pfMenu.checkedMenuItemsFrom(
    filterItems.value[BRAND_INDEX],
    filterItems.value[FEATURES_INDEX],
    filterItems.value.included
  ).length
  const indexes = [BRAND_INDEX, FEATURES_INDEX]

  const clearFilter = () => {
    applyFilter([])
  }

  const getFilters = (filters: Record<string, string[]>) => {
    const featureVector = filters[FEATURES_INDEX].map((f) => [f])
    const includedVector = filters.included.map((f) => [f])
    // Spreading the features filter items because we want to get an intersection of the features
    return createProductFilterMatrix(
      filters[BRAND_INDEX],
      ...featureVector,
      ...includedVector
    )
  }

  const tracker = useTracking()

  const fireFiltersAppliedEvent = (metadata: FiltersAppliedEventMetadata) => {
    filtersAppliedEvent(tracker)(metadata)
  }

  const handleFilterButtonChecked =
    (indexName: string) => (state: FilterItem[]) => {
      const checkedItems = createCheckedItemLabelList(state)
      const update = { ...filters.value, [indexName]: checkedItems }
      filterItems.overwrite({ ...filterItems.value, [indexName]: state })
      filters.overwrite(update)
      applyFilter(getFilters(update))
      fireFiltersAppliedEvent({
        filters: update as any,
        sortCriteria: sortButtonLabel,
        sortOrder: new ProductFilterSortCategory(
          sortButtonLabel
        ).properties.order.toUpperCase(),
      })
    }

  const handleFilterMenuChecked =
    (indexName: string) => (state: FilterItem[]) => {
      const checkedItems = createCheckedItemLabelList(state)
      filterItems.update({ ...filterItems.value, [indexName]: state })
      filters.update({ ...filters.value, [indexName]: checkedItems })
    }

  return (
    <>
      <FilterBar
        clearableCount={checkedFilterItemsCount}
        currentFilterLabel={sortButtonLabel}
        end={
          <FilterButton
            inputType="radio"
            items={sortItemsState.value.sort}
            onCheck={(state, { checkedItem }) => {
              sortItemsState.overwrite({ sort: state })
              applySort(checkedItem.label)
              fireFiltersAppliedEvent({
                filters: filters as any,
                sortCriteria: checkedItem.label,
                sortOrder: new ProductFilterSortCategory(
                  sortButtonLabel
                ).properties.order.toUpperCase(),
              })
            }}
          >
            Sort
          </FilterButton>
        }
        onClear={() => {
          const { features, brand, included } = pfMenu.createStates()
          filterItems.overwrite({ features, brand, included })
          filters.reset()
          clearFilter()
        }}
        onSmallScreenClick={() => setDrawerOpen(true)}
      >
        <FilterButton
          allIndexes={indexes}
          className={classes.mr2}
          filters={filters.value}
          indexName={BRAND_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value[BRAND_INDEX]}
          onCheck={handleFilterButtonChecked(BRAND_INDEX)}
        >
          Brands
        </FilterButton>

        <FilterButton
          allIndexes={indexes}
          className={classes.mr2}
          filters={filters.value}
          indexName={FEATURES_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value[FEATURES_INDEX]}
          operationType={FilterOperation.Intersection}
          onCheck={handleFilterButtonChecked(FEATURES_INDEX)}
        >
          Features
        </FilterButton>

        <FilterButton
          allIndexes={indexes}
          className={classes.mr2}
          filters={filters.value}
          indexName={FEATURES_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value.included}
          operationType={FilterOperation.Intersection}
          onCheck={handleFilterButtonChecked('included')}
        >
          Included
        </FilterButton>
      </FilterBar>

      <FilterPanel
        anchor="bottom"
        clearableCount={checkedFilterItemsCount}
        open={drawerOpen}
        title="Sort & Filter"
        onApply={() => {
          // Saving only when the user confirms. This allows them to abandon changes when they click on the close button.
          filterItems.save()
          sortItemsState.save()
          filters.save()
          applyFilter(getFilters(filters.value))
          applySort(sortButtonLabel)
          setDrawerOpen(false)
          fireFiltersAppliedEvent({
            filters: filters as any,
            sortCriteria: sortButtonLabel,
            sortOrder: new ProductFilterSortCategory(
              sortButtonLabel
            ).properties.order.toUpperCase(),
          })
        }}
        onClear={() => {
          // We don't want to clear sort items
          const { features, brand, included } = pfMenu.createStates()
          filterItems.update({ features, brand, included })
          filters.clear()
          clearFilter()
        }}
        onClose={() => {
          filterItems.revert()
          sortItemsState.revert()
          filters.revert()
          setDrawerOpen(false)
        }}
      >
        <FilterMenu
          description="sort"
          inputType="radio"
          items={sortItemsState.value.sort}
          onCheck={(state) => {
            sortItemsState.update({ sort: state })
          }}
        >
          Sort by:
        </FilterMenu>

        <Divider />

        <FilterMenu
          allIndexes={indexes}
          description="filter by brand"
          filters={filters.value}
          indexName={BRAND_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value[BRAND_INDEX]}
          onCheck={handleFilterMenuChecked(BRAND_INDEX)}
        >
          Brand
        </FilterMenu>

        <Divider />

        <FilterMenu
          allIndexes={indexes}
          description="filter by feature"
          filters={filters.value}
          indexName={FEATURES_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value[FEATURES_INDEX]}
          operationType={FilterOperation.Intersection}
          onCheck={handleFilterMenuChecked(FEATURES_INDEX)}
        >
          Feature
        </FilterMenu>

        <Divider />

        <FilterMenu
          allIndexes={indexes}
          description="filter by feature"
          filters={filters.value}
          indexName={FEATURES_INDEX}
          initialProducts={allProducts}
          inputType="checkbox"
          items={filterItems.value.included}
          operationType={FilterOperation.Intersection}
          onCheck={handleFilterMenuChecked('included')}
        >
          Included
        </FilterMenu>
      </FilterPanel>
    </>
  )
}
