import Autocomplete, {
  AutocompleteProps,
} from 'baby-ui/foundation/AutoComplete'

export interface AutocompleteTextFieldProps<Options = any>
  extends Omit<AutocompleteProps<Options>, 'onChange'> {
  onChange: (value: string) => void
  required?: boolean
}

export default function AutocompleteTextField({
  onChange,
  required = false,
  ...rest
}: AutocompleteTextFieldProps) {
  return (
    <Autocomplete
      textFieldProps={{ required }}
      onChange={(_e, selected) => {
        onChange(selected)
      }}
      {...rest}
    />
  )
}
