import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ThemeConstants } from '../../../styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto',
      maxWidth: 400,
    },
    title: {
      fontSize: '1.85em',
      lineHeight: '1.33',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING / 2),
      fontWeight: 500,
    },
    subtitle: {
      fontSize: '1.85em',
      lineHeight: '1.33',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING / 2),
      marginTop: theme.spacing(ThemeConstants.BASE_SPACING * 2),
      fontWeight: 500,
    },
    body: {
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING),
    },
    linkButton: {
      width: '100%',
      borderRadius: '50px',
      marginBottom: theme.spacing(ThemeConstants.BASE_SPACING * 2),
      textAlign: 'center',
      textTransform: 'none',
    },
    emailLink: {
      fontWeight: 'bold',
    },
  })
)
