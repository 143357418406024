import * as React from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core'
import classnames from 'classnames'
import useStyles from './styles'

export interface ButtonProps extends MuiButtonProps {
  component?: React.ComponentType
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ classes, ...rest }, ref) => {
    const babyUIClasses = useStyles()
    const { root, text, ...remainingClasses } = classes || {}

    return (
      <MuiButton
        classes={{
          root: classnames(root, babyUIClasses.root),
          text: classnames(text, babyUIClasses.textOverrides),
          label: classnames(text, babyUIClasses.textOverrides),
          ...remainingClasses,
        }}
        ref={ref}
        {...rest}
      />
    )
  }
)

export default Button
