import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(() =>
  createStyles({
    stepIcon: {
      display: 'block',
      margin: 'auto',
      width: 15,

      '&.shipping': {
        width: 18,
      },
    },
  })
)
