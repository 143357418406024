import { Grid } from '@material-ui/core'
import TextField from 'baby-ui/foundation/TextField'
import { Control, Controller, FieldError } from 'react-hook-form'

interface LastNameFieldProps {
  control: Control<{
    lastName: string
  }>
  error?: FieldError | null
  helperText?: string
}

// TODO: This is duplicated from CustomerInformationForm.tsx. Should we centralize it?

const LastNameField = ({ control, error, helperText }: LastNameFieldProps) => (
  <Grid item xs={12}>
    <Controller
      fullWidth
      as={TextField}
      control={control}
      error={!!error}
      helperText={helperText}
      label="Patient's Last Name"
      name="lastName"
      type="text"
    />
  </Grid>
)

export default LastNameField
