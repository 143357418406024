import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    confirmationButton: {
      paddingTop:
        '2px' /* Irregular padding added to match position of submit button */,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },
    confirmationButtonContainer: {
      padding: theme.spacing(4, 0),
    },
    confirmationContainer: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        marginTop:
          '45px' /* Irregular margin added to match position of submit button */,
        maxWidth: 300,
      },
    },
    confirmationText: {
      lineHeight: '175%',
    },
    confirmationTextBox: {
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
      },
    },
    emailField: {
      background: 'white',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 350,
      },
    },
    fineCopy: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },
    formHeader: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.8rem',
      },
    },
    privacyText: {
      color: theme.palette.text.secondary,
    },
    submitButton: {
      padding: theme.spacing(4, 2, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 2, 2, 0),
        width: 300,
      },
    },
    text: {
      padding: theme.spacing(2, 0),
      lineHeight: '28px',
      [theme.breakpoints.up('sm')]: {
        fontWeight: 500,
        maxWidth: 500,
      },
    },
  })
)
