import { useEffect } from 'react'
import { Box, Dialog, Typography } from '@material-ui/core'
import Image from 'components/image'
import ShipDateCopy from 'bl-health/widgets/PreCheckoutWidget/ShipDateCopy'
import LoadingButton from '../../../baby-ui/compounds/LoadingButton'
import { useDMEStore } from '../../store'
import useStyles from './styles'
import { preCheckoutModalViewedEvent } from '../../events/preCheckoutModalViewedEvent'
import { useTracking } from '../../../lib/babylistHealthAnalytics'

interface PreCheckoutWidgetProps {
  open: boolean
  onConfirm: () => void
  finalCheckoutLoading: boolean
}

const PreCheckoutWidget = ({
  open,
  onConfirm,
  finalCheckoutLoading,
}: PreCheckoutWidgetProps) => {
  const classes = useStyles()
  const store = useDMEStore()
  const tracker = useTracking()
  const showMRRWarning = !!store.insurance.manualReviewRequired

  useEffect(() => {
    preCheckoutModalViewedEvent(tracker)({
      manualReviewRequired: store.insurance.manualReviewRequired,
    })
  }, [])

  return (
    <Dialog
      fullScreen
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      open={open}
    >
      <div className={classes.dialogContent}>
        <div>
          <Typography
            gutterBottom
            className={classes.headerText}
            color="primary"
            variant="h6"
          >
            Some important things before you check out...
          </Typography>
          {showMRRWarning && (
            <Box
              className={classes.detailBox}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Image
                className={classes.headerImage}
                height={45}
                src="//images.babylist.com/image/upload/v1685493034/DME/paperwork_med.svg"
                width={60}
              />
              <Typography
                gutterBottom
                className={classes.subHeaderText}
                color="primary"
                variant="h5"
              >
                Your insurance requires some additional checks.
              </Typography>
              <Typography
                gutterBottom
                className={classes.detailText}
                variant="body1"
              >
                Not to worry, it's likely you're covered! We'll email you within
                5-7 business days with an update.
                <br />
                <br />
                If we cannot support your insurance, you'll receive a full
                refund for any upgrade fees.
              </Typography>
            </Box>
          )}

          <Box
            className={classes.detailBox}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Image
              className={classes.headerImage}
              height={64}
              src="//images.babylist.com/image/upload/v1685493034/DME/package_med.svg"
              width={43}
            />
            <ShipDateCopy />
          </Box>

          <Box
            className={classes.detailBox}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Image
              className={classes.headerImage}
              height={70}
              src="//images.babylist.com/image/upload/v1705946177/DME/Pump-med.svg"
              width={85}
            />
            <Typography
              gutterBottom
              className={classes.subHeaderText}
              color="primary"
              variant="h5"
            >
              Insurance only covers one pump per pregnancy.
            </Typography>
            <Typography
              gutterBottom
              className={classes.detailText}
              variant="body1"
            >
              By placing your order, you are stating that this is the only
              breast pump you will order through insurance for this pregnancy.
            </Typography>
          </Box>

          <LoadingButton
            fullWidth
            className={classes.checkoutBttn}
            color="primary"
            loading={finalCheckoutLoading}
            variant="contained"
            onClick={onConfirm}
          >
            Got It
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  )
}

export default PreCheckoutWidget
