import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import SubscriptionLinkRequestForm from 'bl-health/forms/SubscriptionLinkRequestForm'
import { Box, useTheme } from '@material-ui/core'
import { EmailConfirmation } from './components'

export default observer(() => {
  const theme = useTheme()

  const [showForm, setShowForm] = React.useState(true)
  const [email, setEmail] = React.useState('')

  const onSuccess = useCallback((_email: string) => {
    setEmail(_email)
    setShowForm(false)
  }, [])

  const onUpdateEmail = useCallback(() => {
    setEmail('')
    setShowForm(true)
  }, [])

  return (
    <Box
      bgcolor={theme.palette.background.contrast}
      flexGrow={1}
      px={{ xs: 4, sm: 0 }}
      py={8}
    >
      <Box margin="auto" maxWidth={400}>
        {showForm ? (
          <SubscriptionLinkRequestForm
            onSuccess={onSuccess}
            title="Hello, again!"
            subtitle="To qualify for breast pump replacement parts, you must wait 30+ days after your baby arrives"
            body="If you've already passed this milestone, enter the email you used for your breast pump order."
          />
        ) : (
          <EmailConfirmation email={email} onUpdateEmail={onUpdateEmail} />
        )}
      </Box>
    </Box>
  )
})
