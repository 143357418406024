import * as React from 'react'
import { CardContent, CardMedia, Typography } from '@material-ui/core'
import Card from 'baby-ui/foundation/Card'
import { CardProps } from 'baby-ui/foundation/Card/Card'
import truncate from 'lodash/truncate'
import classnames from 'classnames'
import useStyles from './styles'

export interface GuideCardProps extends Pick<CardProps, 'className'> {
  /**
   * Provides alternative information for an image if a user for some reason
   * cannot view it (because of slow connection, an error in the src attribute,
   * or if the user uses a screen reader).
   */
  alt: string
  /**
   * A preview of the post limited to 300 characters.
   */
  description: string
  /**
   * Image for the preview.
   */
  imageUrl: string
  /**
   * URL to the full post.
   */
  href: string
  /**
   * Where to display the linked URL, as the name for a browsing context
   * (a tab, window, or <iframe>).
   *
   * @default _self
   */
  linkTarget?: string
  /**
   * The title of the post.
   */
  title: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export default function GuideCard({
  alt,
  className,
  description,
  href,
  imageUrl,
  linkTarget = '_self',
  title,
  onClick,
}: GuideCardProps) {
  const truncatedDescription = truncate(description, { length: 150 })
  const classes = useStyles()
  const anchorAttr = { rel: linkTarget === '_blank' ? 'noreferrer' : undefined }

  return (
    <a
      className={classes.cardLinkWrapper}
      href={href}
      target={linkTarget}
      onClick={onClick}
      {...anchorAttr}
    >
      <Card className={classnames(classes.cardRoot, className)}>
        <CardMedia alt={alt} component="img" height={250} image={imageUrl} />
        <CardContent>
          <Typography gutterBottom component="h2" variant="h5">
            {title}
          </Typography>
          <Typography color="textSecondary" component="p" variant="body2">
            {truncatedDescription}
          </Typography>
        </CardContent>
      </Card>
    </a>
  )
}
