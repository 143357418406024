import { useState } from 'react'
import { Card, CardMedia } from '@material-ui/core'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { TrackVisibility } from 'lib/babylistHealthAnalytics/hooks'
import EmailForm from '../../forms/EmailForm/EmailForm'
import EmailFormConfirmation from '../../forms/EmailForm/EmailFormConfirmation'
import useStyles from './EmailCollectionWidget.styles'
import { SUBMIT_DELAY } from './EmailCollectionWidget.constants'
import { STORE_NAME } from '../../constants'
import { emailCapturedEvent } from '../../events/emailCapturedEvent'

export const EmailCollectionWidget = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const tracker = useTracking()
  const eventProperties = {
    event: track.emailCaptureViewed,
    storeName: STORE_NAME,
  }

  const submitEmail = ({ email }: { email: string }) => {
    setIsLoading(true)
    // Adding time delay so there is a transition to the confirmation screen
    setTimeout(() => {
      completeEmailCapture(email)
    }, SUBMIT_DELAY)
  }

  const completeEmailCapture = (email: string) => {
    setIsLoading(false)
    setShowConfirmation(true)
    emailCapturedEvent(tracker)({ email })
  }

  return (
    <TrackVisibility eventProperties={eventProperties}>
      <Card className={classes.card}>
        <div className={classes.image}>
          <CardMedia
            alt="Pregnant woman sitting on couch and working on a laptop"
            component="img"
            height={320}
            image="//images.babylist.com/image/upload/f_auto,q_auto/v1684157876/DME/email-collection-crop2.jpg"
          />
        </div>
        <div className={classes.cardContent}>
          {showConfirmation ? (
            <EmailFormConfirmation disabled cta="You're All Set!" />
          ) : (
            <EmailForm isLoading={isLoading} onSubmit={submitEmail} />
          )}
        </div>
      </Card>
    </TrackVisibility>
  )
}

export default EmailCollectionWidget
