import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    navHeading: {
      fontSize: '14px',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'left',
      },
    },
    navHeadingButton: {
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'block',
      textAlign: 'left',
      padding: 0,
      width: '100%',
    },
    navLink: {
      color: 'white',
    },
    socialIcon: {
      color: 'white',
      marginRight: theme.spacing(2),
    },
    socialIconsWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    downloadButton: {
      color: 'white',
      border: '1px solid',
      borderRadius: 0,
      width: 150,
      height: 50,
      flexShrink: 0,
    },
    downloadButtonsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginTop: 0,
      },
    },
    copyright: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    supportLink: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    phoneHours: {
      color: 'white',
      fontSize: '14px',
    },
    supportGroup: {
      [theme.breakpoints.down('sm')]: {
        order: 9,
        marginTop: theme.spacing(1),
      },
    },
    copyrightGroup: {
      [theme.breakpoints.down('sm')]: {
        order: 10,
        textAlign: 'center',
      },
    },
    collapseIcon: {
      textAlign: 'right',
    },
  })
)
