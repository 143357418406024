import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },

    orderStatus: {
      flex: 1,
      height: '100%',
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    orderDetails: {
      backgroundColor: theme.palette.grey[100],
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      flex: 1,
    },
  })
)
