import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      background:
        'radial-gradient(83.26% 83.26% at 50% 50%, #F9F6F3 0%, rgba(249, 246, 243, 0.9) 100%)',
      [theme.breakpoints.down('sm')]: {
        background: '#F9F6F3',
      },
    },
    dialogContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 1 auto',
      padding: theme.spacing(2),
    },
    checkoutBttn: {
      margin: theme.spacing(2, 'auto', 2, 'auto'),
      width: 336,
    },
    headerText: {
      fontWeight: 'normal',
      fontSize: 34,
      textAlign: 'center',
      maxWidth: 468,
      margin: 'auto',
      paddingBottom: theme.spacing(4),
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        maxWidth: 330,
        lineHeight: '32px',
      },
    },
    detailBox: {
      paddingBottom: theme.spacing(3),
    },
    headerImage: {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
    },
    subHeaderText: {
      textAlign: 'center',
      fontSize: 24,
      maxWidth: 366,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        maxWidth: 250,
      },
    },
    detailText: {
      fontSize: 14,
      textAlign: 'center',
      maxWidth: 430,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        maxWidth: 250,
      },
    },
  })
)
