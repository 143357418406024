import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { getReplacementPartsCatalog } from 'bl-health/requests'
import {
  ReplacementPartsCatalog,
  ReplacementPartsCatalogCategoryProduct,
  ReplacementPartsHeroProduct,
} from 'bl-health/types'
import useProductReplacementParts from './useProductReplacementParts'
import { pumpBrandSelectedEvent } from 'bl-health/events'
import { useTracking } from 'lib/babylistHealthAnalytics'

export interface FragmentController<Data> {
  data?: Data[]
  error?: string
  isLoading?: boolean
  select: (data?: Data) => void
  selected?: Data
  setError: (error?: string) => void
}

export default function useReplacementPartsCatalogStrategy() {
  const catalogResponse = useQuery({
    queryKey: ['getReplacementPartsCatalog'],
    queryFn: getReplacementPartsCatalog,
    refetchOnWindowFocus: false,
  })

  const [brandSelected, setBrandSelected] =
    React.useState<ReplacementPartsCatalog>()
  const [productSelected, setProductSelected] =
    React.useState<ReplacementPartsHeroProduct>()
  const [partSelected, setPartSelected] =
    React.useState<ReplacementPartsCatalogCategoryProduct>()
  const partsResponse = useProductReplacementParts(productSelected?.id)
  const [brandError, setBrandError] = React.useState<string>()
  const [productError, setProductError] = React.useState<string>()
  const [partError, setPartError] = React.useState<string>()
  const tracker = useTracking()

  function selectBrand(brand?: ReplacementPartsCatalog) {
    if (brandSelected?.brand !== brand?.brand) {
      setProductError(undefined)
      setPartError(undefined)
      if (brand?.pumps.length === 0) {
        setBrandError(
          'The brand you selected is not currently available through Babylist Health. Contact your insurance company to learn about your options.'
        )
        setBrandSelected(undefined)
      } else {
        setBrandError(undefined)
        setBrandSelected(brand)
      }
      pumpBrandSelectedEvent(tracker)({ brandName: brand?.brand })
      setProductSelected(undefined)
      setPartSelected(undefined)
    }
  }

  function selectProduct(product?: ReplacementPartsHeroProduct) {
    if (productSelected?.id !== product?.id) {
      setPartError(undefined)
      setProductError(undefined)
      setPartSelected(undefined)
      setProductSelected(product)
    }
  }

  function selectPart(part?: ReplacementPartsCatalogCategoryProduct) {
    setPartError(undefined)

    setPartSelected(part)
  }

  return {
    brandsController: {
      data: catalogResponse.data?.data,
      error: brandError,
      isLoading: catalogResponse.isFetching,
      select: selectBrand,
      selected: brandSelected,
      setError: setBrandError,
    },
    productsController: {
      data: brandSelected?.pumps,
      error: productError,
      select: selectProduct,
      isLoading: catalogResponse.isFetching,
      selected: productSelected,
      setError: setProductError,
    },
    partsController: {
      data: partsResponse.data,
      error: partError,
      isLoading: partsResponse.isFetching,
      select: selectPart,
      selected: partSelected,
      setError: setPartError,
    },
    showErrorBlock: catalogResponse.data?.data.length === 0,
  }
}
