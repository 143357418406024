import { useEffect, useState } from 'react'

// Number of seconds until the user can request a new code.
const RESEND_DELAY_SECONDS = 60

// Number of seconds remaining in the delay when we will
// start showing a live countdown.
const RESEND_COUNTDOWN_SECONDS = 30

export const displayTimeRemaining = (seconds: number) => {
  const countdownSeconds =
    seconds > RESEND_COUNTDOWN_SECONDS ? RESEND_DELAY_SECONDS : seconds

  return countdownSeconds === 1 ? '1 second' : `${countdownSeconds} seconds`
}

export const useResendCountdown = () => {
  const [timeRemaining, setTimeRemaining] = useState<number | undefined>(
    undefined
  )

  const resetCountdown = () => setTimeRemaining(RESEND_DELAY_SECONDS)

  useEffect(() => {
    const timer = timeRemaining
      ? setTimeout(() => {
          setTimeRemaining(timeRemaining - 1)
        }, 1000)
      : null

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timeRemaining, setTimeRemaining])

  return {
    timeRemaining,
    resetCountdown,
  }
}
