import { Factory } from 'fishery'
import * as faker from 'faker'
import * as fns from 'date-fns'
import { DME } from '../form-types'

export const InsuranceStatus = {
  INACTIVE: 0,
  INVALID_INSURANCE_INFORMATION: 4,
  MRR: 8,
  TIME_OUT: 1,
  UNKNOWN: 7,
  UNSUPPORTED_MEMBER_ID: 5,
  UNSUPPORTED_PLAN_TYPE: 2,
  VALID: 9,
} as const

export interface CustomerInformationFormFactoryTransientParams {
  insuranceStatus: typeof InsuranceStatus
}

export default Factory.define<
  DME.CustomerInformationFormInputs,
  CustomerInformationFormFactoryTransientParams
>(({ transientParams: { insuranceStatus = InsuranceStatus.VALID } }) => {
  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()
  const email = faker.internet.email(firstName, lastName, 'example.com')
  const birthDate = fns.format(fns.sub(new Date(), { years: 18 }), 'yyyy-MM-dd')
  const dueDate = fns.format(fns.add(new Date(), { months: 3 }), 'yyyy-MM-dd')

  return {
    birthDate,
    dueDate,
    email,
    firstName,
    lastName,
    memberId: `BBLABC-${fns.format(
      new Date(),
      'yyMMddHHmmss'
    )}-${insuranceStatus}`,
    phoneNumber: faker.phone.phoneNumber('(###) ###-####'),
    smsAllowed: false,
  }
})
