import { invert } from 'lodash'
import { US_STATE_NAMES } from 'shared/constants'

export const useStateOptions = () => {
  const stateNames = Object.values(US_STATE_NAMES)
  const nameToAbbr = invert(US_STATE_NAMES)
  const getStateAbbr = (name: string): string | undefined => nameToAbbr[name]

  return {
    stateNames,
    getStateAbbr,
  }
}
