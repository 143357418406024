import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { CURRENT_CUSTOMER_ENDPOINT } from './constants'

const getCurrentCustomer = async (): Promise<APIResponse.CustomerIdentifier> =>
  fetch(CURRENT_CUSTOMER_ENDPOINT, {
    method: RequestMethod.GET,
  })

export default getCurrentCustomer
