import { ButtonProps, createStyles, makeStyles } from '@material-ui/core'
import { sizeMap } from './LoadingButton'

interface StyleProps {
  color: ButtonProps['color']
}

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      width: '100%',
    },
    buttonProgressRoot: {
      color: ({ color }: StyleProps) => {
        if (color === 'primary') {
          return theme.palette.primary.main
        }

        if (color === 'secondary') {
          return theme.palette.secondary.main
        }

        if (color === 'inherit') {
          return color
        }

        // If we get here, color is most likely `default` which not a valid value
        return undefined
      },
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    buttonProgressMedium: {
      marginTop: -sizeMap.medium / 2,
      marginLeft: -sizeMap.medium / 2,
    },
    buttonProgressSmall: {
      marginTop: -sizeMap.small / 2,
      marginLeft: -sizeMap.small / 2,
    },
    buttonProgressLarge: {
      marginTop: -sizeMap.large / 2,
      marginLeft: -sizeMap.large / 2,
    },
  })
)
