import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { formFieldClickedEvent } from '../../events/formFieldClickedEvent'
import InsurancePickerForm from '../../forms/InsurancePickerForm/InsurancePickerForm'

export interface InsuranceMetadata {
  insuranceState: string
  insuranceProvider: APIResponse.Insurance
}

export interface InsurancePickerProps {
  submitButtonText?: string
}

export interface FormValues {
  email?: string
}

export default function InsurancePickerWidget({
  submitButtonText,
}: InsurancePickerProps) {
  const tracker = useTracking()

  const fireFormFieldClickedEvent = (formFieldName: string) => {
    formFieldClickedEvent(tracker)({
      formName: track.FormName.INSURANCE_FORM,
      formFieldName,
    })
  }

  return (
    <InsurancePickerForm
      onInsuranceCarrierFieldFocus={() =>
        fireFormFieldClickedEvent(track.FormFieldName.INSURANCE_PROVIDER)
      }
      onInsuranceStateFieldFocus={() =>
        fireFormFieldClickedEvent(track.FormFieldName.INSURANCE_STATE)
      }
      submitButtonText={submitButtonText}
    />
  )
}
