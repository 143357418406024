import TextField, { TextFieldProps } from 'baby-ui/foundation/TextField'
import PhoneNumberInputMask from './PhoneNumberInputMask'

export type MaskedPhoneNumberTextFieldProps = TextFieldProps

export default function MaskedPhoneNumberTextField(
  props: MaskedPhoneNumberTextFieldProps
) {
  return (
    <TextField
      {...(props as any)}
      InputProps={{ inputComponent: PhoneNumberInputMask as any }}
    />
  )
}
