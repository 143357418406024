import {
  cityInputSetting,
  firstNameInputSetting,
  lastNameInputSetting,
  phoneNumberInputSetting,
  stateInputSetting,
} from './inputs'

const inputSetting = {
  firstName: firstNameInputSetting,
  lastName: lastNameInputSetting,
  city: cityInputSetting,
  stateName: stateInputSetting,
  phoneNumber: phoneNumberInputSetting,
}

export default inputSetting

export * from './inputs'
