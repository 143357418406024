import React from 'react'
import { Card } from '../components'
import {
  PrescriptionInvalid,
  PrescriptionInvalidReasons,
} from 'bl-health/views/OrderView/cards/index'

interface PrescriptionInvalidCardProps {
  order: APIResponse.DME.Order
}
const PrescriptionInvalidCard: React.FC<PrescriptionInvalidCardProps> = ({
  order,
}: PrescriptionInvalidCardProps) => {
  const {
    prescriptionRequired,
    prescriptionInvalid,
    prescriptionInvalidReasons,
  } = order

  const prescriptionCardContent = () => {
    if (prescriptionInvalid && prescriptionInvalidReasons.length) {
      return (
        <PrescriptionInvalidReasons
          prescriptionInvalidReasons={prescriptionInvalidReasons}
        />
      ) // Prescription is invalid and there are reasons
    } else if (prescriptionInvalid) {
      return <PrescriptionInvalid /> // The prescription is invalid
    }
  }

  if (!prescriptionRequired || !prescriptionInvalid) return null

  return (
    <Card testId="prescription-invalid-card">{prescriptionCardContent()}</Card>
  )
}

export default PrescriptionInvalidCard
