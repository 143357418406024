import { Typography } from '@material-ui/core'
import AlertDialog from 'baby-ui/compounds/AlertDialog'
import Link from 'baby-ui/foundation/Link/Link'

interface ReplacementPartsModalProps {
  open: boolean
  onClose(): void
}
export const ReplacementPartsModal = ({
  open,
  onClose,
}: ReplacementPartsModalProps) => {
  return (
    <AlertDialog open={open} onClose={onClose} closeOnBackdropClick title="">
      <Typography variant="h6" style={{ color: '#6E3264' }} gutterBottom>
        Get FREE replacement parts through insurance for up to a year!
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 500 }} gutterBottom>
        Old parts can negatively affect the safety, efficiency, and reliability
        of your pump. When you order your pump through Babylist:
      </Typography>
      <ul>
        <Typography component="li" gutterBottom variant="body2">
          We'll remind you when it's time to replace your parts
        </Typography>
        <Typography component="li" gutterBottom variant="body2">
          If you're still pumping, you can select your flange size
        </Typography>
        <Typography component="li" gutterBottom variant="body2">
          We'll bill insurance on your behalf and your order is always free
        </Typography>
      </ul>
      <Link
        style={{ fontSize: 12, color: '#666666' }}
        underline="always"
        href="https://healthbabylist.zendesk.com/hc/en-us/articles/22819765227291-Can-I-receive-replacement-parts"
        target="_blank"
      >
        Terms and conditions apply
      </Link>
    </AlertDialog>
  )
}
