import { Box, Grid, useTheme } from '@material-ui/core'
import { formatAsUSD } from 'baby-ui/utils'
import OrderItem from './OrderItem'
import PriceBreakdown from './PriceBreakdown'

interface OrderSummaryProps {
  orderItems: Array<APIResponse.DME.OrderItem>
  shippingAmount: APIResponse.DME.Order['shippingAmount']
  subtotal: APIResponse.DME.Order['orderSubtotal']
  taxAmount: APIResponse.DME.Order['taxAmount']
  total: APIResponse.DME.Order['orderTotal']
}

const OrderSummary = ({
  orderItems,
  shippingAmount,
  subtotal,
  taxAmount,
  total,
}: OrderSummaryProps) => {
  const theme = useTheme()

  if (orderItems.length === 0) {
    return null
  }

  return (
    <Box maxWidth={450} mx={{ xs: 0, md: 5 }} mt={{ xs: 0, md: 6 }}>
      <Box borderBottom={`1px solid ${theme.palette.grey[400]}`}>
        {orderItems.map((orderItem) => (
          <Grid
            container
            direction="row"
            key={orderItemKey(orderItem)}
            style={{ marginBottom: theme.spacing(2) }}
          >
            <OrderItem orderItem={orderItem} />
          </Grid>
        ))}
      </Box>
      <PriceBreakdown label="Subtotal" value={formatAmount(subtotal)} />
      <PriceBreakdown
        label="Shipping"
        value={formatAmount(shippingAmount, 'Free')}
      />
      <PriceBreakdown label="Taxes" value={formatAmount(taxAmount)} />
      <Box borderTop={`1px solid ${theme.palette.grey[400]}`} mt={2}>
        <PriceBreakdown
          bold
          label="Total"
          value={formatAmount(total)}
          variant="subtitle1"
        />
      </Box>
    </Box>
  )
}

const orderItemKey = (orderItem: APIResponse.DME.OrderItem) =>
  `${orderItem.name}_${orderItem.price}_${orderItem.quantity}`

const formatAmount = (amount: string | null, zeroText?: string) => {
  if (amount === null) {
    return ''
  }

  const floatAmount = parseFloat(amount)
  if (floatAmount === 0 && zeroText !== undefined) {
    return zeroText
  }

  return formatAsUSD(floatAmount)
}

export default OrderSummary
