import { Card, ContactLink, ContentBlock } from '../components'

export default function HelpCard() {
  return (
    <Card>
      <ContentBlock title="Need help?">
        If your insurance has changed or you need other assistance, reach out to{' '}
        <ContactLink />.
      </ContentBlock>
    </Card>
  )
}
