import { observer } from 'mobx-react-lite'

import { configureView } from 'bl-health/utils/ViewConfiguration'
import { INELIGIBLE_PARTS_PATH } from 'bl-health/constants'
import { UI } from '../../../global'
import ReplacementPartsOrderBlocker from 'bl-health/views/ReplacementPartsView/ReplacementPartsOrderBlocker'

interface ReplacementPartsIneligibleViewProps {
  error: APIResponse.DME.SubscriptionIneligibilityErrorType
}

const ReplacementPartsIneligibleView: UI.ViewComponent<ReplacementPartsIneligibleViewProps> =
  observer(({ error }: ReplacementPartsIneligibleViewProps) => {
    return (
      <ReplacementPartsOrderBlocker error={error} subscription={{} as any} />
    )
  })

export default configureView(ReplacementPartsIneligibleView)({
  pathname: INELIGIBLE_PARTS_PATH,
})
