import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { MemberCardTag } from './MemberCard'

interface StyleProps {
  tag: MemberCardTag
}

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
    },
    cardLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 12,
      paddingBottom: 8,
    },
    fileSelect: {
      width: 200,
      height: 122,
      [theme.breakpoints.down('sm')]: {
        height: 106,
      },
      alignItems: 'end',
      display: 'flex',
      justifyContent: 'center',
      border: '1px dashed #C4B2B2',
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        border: '1px dashed #6F3365',
        background: ({ tag }: StyleProps) => {
          if (tag === MemberCardTag.Front) {
            return `top ${theme.spacing(
              2
            )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681235823/DME/insurance_icon_hover.svg"), #E2D6D6`
          }

          return `top ${theme.spacing(
            2
          )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681861209/DME/insurance_back_hover.svg"), #E2D6D6`
        },
      },
      background: ({ tag }: StyleProps) => {
        if (tag === MemberCardTag.Front) {
          return `top ${theme.spacing(
            2
          )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681235813/DME/insurance_icon.svg"), #FCFBF9`
        }

        return `top ${theme.spacing(
          2
        )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681861203/DME/insurance_back.svg"), #FCFBF9`
      },
    },
    fileSelectDragState: {
      border: '1px dashed #6F3365',
      background: ({ tag }: StyleProps) => {
        if (tag === MemberCardTag.Front) {
          return `top ${theme.spacing(
            2
          )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681235823/DME/insurance_icon_hover.svg"), #E2D6D6`
        }

        return `top ${theme.spacing(
          2
        )}px center no-repeat url("//res.cloudinary.com/babylist/image/upload/v1681861209/DME/insurance_back_hover.svg"), #E2D6D6`
      },
    },
    replaceCard: {
      width: 200,
      height: 64,
      alignItems: 'end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      background: '#FCFBF9',
      border: '1px dashed #C4B2B2',
      borderRadius: theme.shape.borderRadius,
      backgroundPosition: 'center',
      '&:hover': {
        backgroundColor: '#E2D6D6',
        border: '1px dashed #6F3365',
      },
    },
    replaceCardDragState: {
      backgroundColor: '#E2D6D6',
      border: '1px dashed #6F3365',
    },
    fileInput: {
      height: 100,
      cursor: 'pointer',
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: 200,
    },
    cardCaption: {
      marginBottom: theme.spacing(1),
    },
    status: {
      display: 'flex',
      marginTop: theme.spacing(1),
      width: 200,
      height: 43,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
    },
    statusFailed: {
      background: 'rgba(224, 95, 49, 0.1)',
      border: `0.5px solid #E05F31`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      marginTop: theme.spacing(1),
      width: 200,
      height: 43,
    },
    statusDiv: {
      flex: 4,
      paddingLeft: 5,
    },
    fileName: {
      paddingTop: 5,
      fontSize: 12,
      display: 'block',
    },
    statusText: {
      fontSize: 10,
      color: theme.palette.grey[600],
      display: 'block',
    },
    loadingIconDiv: {
      flex: 1,
      display: 'flex',
    },
  })
)
