import React from 'react'
import { Box } from '@material-ui/core'
import { Card } from '../components'
import { ProgressIndicator } from '../ProgressIndicator'

interface OrderProgressCardProps {
  order: APIResponse.DME.Order
}

const OrderProgressCard: React.FC<OrderProgressCardProps> = ({
  order,
}: OrderProgressCardProps) => {
  return (
    <Card>
      <Box mb={2}>
        <ProgressIndicator order={order} />
      </Box>
    </Card>
  )
}

export default OrderProgressCard
