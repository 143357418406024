import { Typography } from '@material-ui/core'
import { formattedEstimate } from 'bl-health/views/ShippingEstimate.utils'
import useStyles from 'bl-health/widgets/PreCheckoutWidget/styles'
import { useDMEStore } from 'bl-health/store'

const ShipDateCopy = () => {
  const classes = useStyles()
  const store = useDMEStore()

  return store.insurance.shippingEligibilityDays ? (
    <>
      <Typography
        gutterBottom
        className={classes.subHeaderText}
        color="primary"
        variant="h5"
      >
        Your pump will ship{' '}
        {formattedEstimate(store.insurance.shippingEligibilityDays)} your due
        date.
      </Typography>
      <Typography gutterBottom className={classes.detailText} variant="body1">
        Your insurance plan determines when we can ship your pump and shipping
        is always FREE.
      </Typography>
    </>
  ) : (
    <>
      <Typography
        gutterBottom
        className={classes.subHeaderText}
        color="primary"
        variant="h5"
      >
        When to expect your pump.
      </Typography>
      <Typography gutterBottom className={classes.detailText} variant="body1">
        Your pump will ship within 2 business days of us receiving your
        prescription from your provider.
      </Typography>
    </>
  )
}

export default ShipDateCopy
