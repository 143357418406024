import {
  Box,
  Grid,
  Typography,
  TypographyProps,
  useTheme,
} from '@material-ui/core'

interface PriceBreakdownProps {
  bold?: boolean
  label: string
  value: string
  variant?: TypographyProps['variant']
}

const PriceBreakdown = ({
  bold = false,
  label,
  value,
  variant = 'body2',
}: PriceBreakdownProps) => {
  const theme = useTheme()
  const typographyStyle = {
    fontWeight: bold ? theme.typography.fontWeightBold : undefined,
  }

  return (
    <Box mt={2}>
      <Grid container direction="row" justifyContent="space-between">
        <Typography style={typographyStyle} variant={variant}>
          {label}
        </Typography>
        <Typography style={typographyStyle} variant={variant}>
          {value}
        </Typography>
      </Grid>
    </Box>
  )
}

export default PriceBreakdown
