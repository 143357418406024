import TermsAndConditions from 'baby-ui/compounds/TermsAndConditions'
import { useDMEStore } from 'bl-health/store'
import { AoBDialogProps } from './AobDialog'

export default function BreastPumpAobDialog({
  open,
  onAgree,
  onDisagree,
}: AoBDialogProps) {
  const store = useDMEStore()

  return (
    <TermsAndConditions
      clauses={[
        {
          title: (
            <p>
              Before we ship your product(s), we need you to authorize Baby List
              Health, LLC, a division of Babylist, to provide products or
              services to you.
            </p>
          ),
          type: 'unordered',
          terms: [],
        },
        {
          title: (
            <p>
              By agreeing below, you authorize Baby List Health, LLC or members
              of Babylist's Preferred Durable Medical Equipment Network such as
              Medequip, Inc. and OrthoSource, Inc. to submit claims to your
              insurance company on your behalf and request on your behalf all
              insurance information, prescriptions and benefits for products
              provided as well as to release your medical records to any person,
              organization, company and/or agency which may be involved in
              providing your care.
            </p>
          ),
          type: 'unordered',
          terms: [],
        },
        {
          title: (
            <p>
              By agreeing below, you also acknowledge that you have not ordered
              another breast pump or milk storage bags through your insurance
              for this pregnancy.
            </p>
          ),
          type: 'unordered',
          terms: [],
        },
        {
          title:
            store.context.upgradeFee == true ? (
              <p>
                This breast pump is an upgraded item that requires an additional
                out of pocket cost.
              </p>
            ) : null,
          type: 'unordered',
          terms: [],
        },
      ]}
      open={open}
      title="Assignment of Benefits"
      onAgree={onAgree}
      onDisagree={onDisagree}
    />
  )
}
