import { useState } from 'react'
import { Grid, Theme, Box, Link, useMediaQuery } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import { Control, FieldError, Controller } from 'react-hook-form'
import Autocomplete from 'baby-ui/foundation/AutoComplete'
import FullScreenPanelTableWidget from '../../../widgets/FullScreenPanelTableWidget/FullScreenPanelTableWidget'
import { SelectableInsuranceOption } from '../InsuranceForm'
import { INSURANCE_NOT_FOUND_TEXT } from '../../../widgets/UnsupportedInsuranceDialog/constants'

const insuranceNotFoundStub = {
  company: INSURANCE_NOT_FOUND_TEXT,
  enabled: false,
}

const filter = createFilterOptions<SelectableInsuranceOption>()

interface InsuranceProviderFieldProps {
  control: Control<{
    insuranceProvider: string
  }>
  error?: FieldError
  disabled: boolean
  selectedValue: SelectableInsuranceOption | null
  onChange: (value: SelectableInsuranceOption | null) => void
  data: SelectableInsuranceOption[]
  promotedOptions: SelectableInsuranceOption[]
}

const InsuranceProviderField = ({
  control,
  error,
  data,
  promotedOptions,
  disabled,
  selectedValue,
  onChange,
}: InsuranceProviderFieldProps) => {
  const [panelOpen, setPanelOpen] = useState(false)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  let helperText: unknown
  if (error?.message) {
    helperText = error.message
  } else if (disabled) {
    helperText = 'Please select your shipping state first'
  }

  return (
    <>
      <FullScreenPanelTableWidget
        featuredOptions={promotedOptions}
        featuredOptionsSubheader="Top plans:"
        footer={
          <Box display="flex" justifyContent="end" width="100%">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setPanelOpen(false)
                onChange(insuranceNotFoundStub)
              }}
            >
              {INSURANCE_NOT_FOUND_TEXT}
            </Link>
          </Box>
        }
        getOptionLabel={(item) => item?.company as string}
        name="insuranceProvider"
        open={panelOpen}
        options={data}
        optionsSubheader="All plans:"
        selected={selectedValue}
        title="Insurance Carrier"
        onClose={() => setPanelOpen(false)}
        onOptionSelect={(newValue) => {
          setPanelOpen(false)
          onChange(newValue)
        }}
      />
      <Grid
        item
        xs={12}
        onClick={() => {
          if (!isDesktop && !disabled) {
            setPanelOpen(true)
          }
        }}
      >
        <Controller
          aria-required
          required
          as={(props) => (
            <Autocomplete<SelectableInsuranceOption>
              {...props}
              filterOptions={(options, params) =>
                filter(options, params).concat(insuranceNotFoundStub)
              }
              getOptionLabel={(option) => option.company}
              value={selectedValue}
              onChange={(_, newValue, _reason) => onChange(newValue)}
            />
          )}
          control={control}
          disabled={disabled}
          label="Insurance Carrier"
          name="insuranceProvider"
          options={data}
          textFieldProps={{
            error: !!error,
            helperText,
            readOnly: true,
          }}
        />
      </Grid>
    </>
  )
}

export default InsuranceProviderField
