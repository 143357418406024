import React from 'react'
import { Link } from '@material-ui/core'
import { PRESCRIPTION_FORM } from '../../constants'
import { DME_PRESCRIPTION_INVALID_URL } from 'bl-health/constants'
import { ContentBlock, ContactLink } from '../components'
import css from './PrescriptionInvalid.styles.scss'

const PrescriptionInvalid: React.FC = () => {
  return (
    <ContentBlock
      title="Invalid Rx"
      variant="warn"
      testId="prescription-invalid-generic"
    >
      <p className={css.prescription_invalid__p}>
        We have received an invalid prescription and are working with your
        healthcare provider to get it corrected. If we can't get this sorted
        with your provider, we will need you to bring our{' '}
        <Link
          className={css.prescription_invalid__link}
          download
          href={PRESCRIPTION_FORM}
        >
          prescription form
        </Link>{' '}
        to an upcoming appointment. All fields need to be filled in by your
        provider and you can send the completed form to{' '}
        <ContactLink variant="rx" />.
      </p>
      You can view reasons why it might be invalid{' '}
      <Link
        className={css.prescription_invalid__link}
        href={DME_PRESCRIPTION_INVALID_URL}
        target="_blank"
      >
        here
      </Link>
      .
    </ContentBlock>
  )
}

export default PrescriptionInvalid
