import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    bannerContainer: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.contrast,
      textAlign: 'center',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        marginBottom: 0,
      },
    },
    dropdownContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      cursor: 'pointer',
    },
    dropdownPrompt: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: theme.typography.fontWeightMedium,
      paddingRight: theme.spacing(1),
    },
    heart: {
      padding: theme.spacing(1, 1, 0),
    },
    heading: {
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        maxWidth: 200,
        margin: '0 auto',
      },
    },
    howItWorksBox: {
      backgroundColor: 'white',
      borderRadius: 4,
      margin: 4,
      padding: `${theme.spacing(1, 2)} !important`,
    },
    howItWorksContainer: {
      padding: theme.spacing(0, 1, 3),
      maxWidth: 360,
      margin: '0 auto',
    },
    howItWorksGrid: {
      marginTop: theme.spacing(2),
      flexWrap: 'nowrap',
    },
    howItWorksText: {
      fontSize: 12,
      color: '#757070',
    },
    subText: {
      maxWidth: 400,
      margin: '0 auto',
      lineHeight: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '20px',
        padding: theme.spacing(0, 4),
      },
    },
  })
)
