interface Breadcrumb {
  name: string
  current: boolean
  visited: boolean
}

export interface BreadcrumbConfig {
  key: string
  displayName: string
}

export const useBreadcrumbs = (
  breadcrumbsConfig: BreadcrumbConfig[],
  currentKey: string
) => {
  const currentStepIndex = breadcrumbsConfig.findIndex((bc) => {
    return bc.key === currentKey
  })

  return breadcrumbsConfig.map<Breadcrumb>((breadcrumbConfig, i) => {
    return {
      name: breadcrumbConfig.displayName,
      visited: i < currentStepIndex,
      current: i === currentStepIndex,
    }
  })
}
