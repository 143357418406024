import { Card, ContentBlock } from '../components'

export default function OtherPumpCard() {
  return (
    <Card>
      <ContentBlock title="Other updates">
        If you are looking for status updates for a different pump order, please
        click on the order status link from any of the emails specific to that
        order.
      </ContentBlock>
    </Card>
  )
}
