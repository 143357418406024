import getValue from 'lodash/get'
import isString from 'lodash/isString'
import { Product } from 'baby-ui/lib/ProductFilter'

export const createProductFilterGraph = (
  products: Product[],
  indexes: string[]
) => {
  const graph = new Map<string, Set<Product>>()
  products.forEach((product) => {
    indexes.forEach((index) => {
      const key = getValue(product, index)

      if (!key) {
        return
      }

      if (Array.isArray(key)) {
        key.forEach((k) => {
          if (!isString(k)) {
            throw new Error(
              `Expected index (${index}) key to be a string, but got ${typeof key} instead.`
            )
          }

          if (!graph.has(k)) {
            graph.set(k, new Set([product]))
          } else {
            graph.get(k)?.add(product)
          }
        })

        return
      }

      if (!isString(key)) {
        throw new Error(
          `Expected index (${index}) key to be a string, but got ${typeof key} instead.`
        )
      }

      if (!graph.has(key)) {
        graph.set(key, new Set([product]))
        return
      }

      graph.get(key)?.add(product)
    })
  })

  return graph
}
