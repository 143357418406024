import { Container, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { createNameSpacedPath } from 'bl-health/utils'
import browserHistory from 'bl-health/utils/browserHistory'
import { ApplicationStrategy } from 'bl-health/types'
import { useDMEStore } from '../../store'
import useStyles from './styles'
import PhysicianSearchForm from '../../forms/PhysicianSearchForm'
import { UI } from '../../../global'
import {
  CUSTOMER_INFORMATION_PATH,
  PHYSICIAN_RESULT_PATH,
  SEARCH_PHYSICIAN_STEP,
} from '../../constants'
import CheckoutJourneyStepsWidget from '../../widgets/CheckoutJourneyStepsWidget'
import CheckoutJourneyWrapperWidget from '../../widgets/CheckoutJourneyWrapperWidget'

const PhysicianSearchView: UI.ViewComponent<UI.LifeCycle> = observer(
  ({ onComponentDidMount }) => {
    const store = useDMEStore()
    const classes = useStyles()

    const navigateToResults = () => {
      browserHistory.push(
        createNameSpacedPath(
          PHYSICIAN_RESULT_PATH,
          store.context?.strategy as ApplicationStrategy
        )
      )
    }

    if (!store.user.present) {
      browserHistory.push(
        createNameSpacedPath(
          CUSTOMER_INFORMATION_PATH,
          store.context?.strategy as ApplicationStrategy
        )
      )
      return null
    }

    useEffect(() => {
      window.scrollTo(0, 0)
      onComponentDidMount()
    }, [])

    return (
      <Container className={classes.viewContainer}>
        <CheckoutJourneyWrapperWidget>
          <CheckoutJourneyStepsWidget currentStep={SEARCH_PHYSICIAN_STEP} />
          <Typography
            gutterBottom
            className={classes.disclaimer}
            variant="body1"
          >
            To make it easier for you, we can send a prescription request
            directly to your pregnancy care provider.
          </Typography>
          <PhysicianSearchForm nextAction={navigateToResults} />
        </CheckoutJourneyWrapperWidget>
      </Container>
    )
  }
)

export default PhysicianSearchView
