import Button, { ButtonProps } from 'baby-ui/foundation/Button'
import classnames from 'classnames'
import { CircularProgress } from '@material-ui/core'
import useStyles from './styles'

export interface LoadingButtonProps extends ButtonProps {
  /**
   * If `true` button will be disabled and show spinner.
   * @default false
   */
  loading?: boolean
}

export const sizeMap = {
  small: 16, // 8 * 2
  medium: 24, // 8 * 3
  large: 32, // 8 * 4
}

export default function LoadingButton({
  disabled,
  loading = false,
  size = 'medium',
  color,
  className,
  ...rest
}: LoadingButtonProps) {
  const classes = useStyles({ color })

  return (
    <div className={classnames(classes.wrapper, className)}>
      <Button
        disableElevation
        color={color}
        disabled={loading || disabled}
        size={size}
        {...rest}
      />
      {loading && (
        <CircularProgress
          className={classnames(classes.buttonProgressRoot, {
            [classes.buttonProgressLarge]: size === 'large',
            [classes.buttonProgressMedium]: size === 'medium',
            [classes.buttonProgressSmall]: size === 'small',
          })}
          size={sizeMap[size]}
        />
      )}
    </div>
  )
}
