import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    disclaimer: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    viewContainer: {
      paddingBottom: theme.spacing(ThemeConstants.BASE_SPACING),
      paddingTop: theme.spacing(ThemeConstants.BASE_SPACING),
    },
  })
)
