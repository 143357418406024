import { useForm, Controller } from 'react-hook-form'
import { Box, TextField, Typography } from '@material-ui/core'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import useStyles from './EmailForm.styles'
import { PRIVACY_URL } from '../../constants'

interface FormValues {
  email: string
}

interface EmailFormProps {
  onSubmit: ({ email }: FormValues) => void
  isLoading?: boolean
}

export const EmailForm = ({ onSubmit, isLoading = false }: EmailFormProps) => {
  const classes = useStyles()
  const { handleSubmit, control, errors } = useForm<FormValues>({
    mode: 'onChange',
  })

  return (
    <>
      <Typography className={classes.formHeader} variant="h5">
        <strong>Not ready to decide today?</strong>
      </Typography>
      <Typography className={classes.text}>
        We've done the research for you! Let us help by sending you expert info
        like breast pump guides and pumping tips.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          fullWidth
          as={TextField}
          className={classes.emailField}
          control={control}
          disabled={isLoading}
          error={!!errors.email}
          label="Enter your email"
          name="email"
          rules={{ required: true }}
          type="email"
          variant="outlined"
        />
        <Box className={classes.submitButton}>
          <LoadingButton
            fullWidth
            color="secondary"
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            Send Me Pump Guidance
          </LoadingButton>
        </Box>
      </form>
      <div className={classes.fineCopy}>
        <Typography color="textSecondary" variant="caption">
          Please see our{' '}
          <a
            className={classes.privacyText}
            href={PRIVACY_URL}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>{' '}
          for terms.
        </Typography>
      </div>
    </>
  )
}

export default EmailForm
