import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.speedDial,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    stylesWhenPinned: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    endElement: { marginLeft: 'auto' },
  })
)
