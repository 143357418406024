import { ReactElement } from 'react'
import {
  Tooltip,
  useTheme,
  useMediaQuery,
  TooltipProps,
} from '@material-ui/core'
import useStyles from './EnhancedProductCard.styles'

interface DescriptionProps extends Pick<TooltipProps, 'onClose'> {
  children: ReactElement
  description: string
}

const HoverDescription = ({
  description,
  children,
  onClose,
}: DescriptionProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) {
    return children
  }

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
        tooltipPlacementTop: classes.tooltipPlacement,
      }}
      enterDelay={250}
      enterNextDelay={250}
      placement="top"
      title={description}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  )
}

export default HoverDescription
