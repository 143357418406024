import { useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { formatAsUSD } from 'baby-ui/utils'
import { ShoppingCartIcon } from '../icons'
import useStyles from './styles'
import OrderSummary from '../OrderSummary/OrderSummary'

interface OrderSummaryBarProps {
  order: APIResponse.DME.Order
}

const OrderSummaryBar = ({ order }: OrderSummaryBarProps) => {
  const classes = useStyles()
  const [summaryVisible, setSummaryVisibility] = useState(false)

  if (order.orderItems.length === 0) {
    return null
  }

  const total = displayTotal(order.orderTotal)

  const toggleSummary = () => setSummaryVisibility(!summaryVisible)
  const buttonText = summaryVisible
    ? 'Hide order summary'
    : 'Show order summary'

  return (
    <Box className={classes.root}>
      <Grid
        container
        alignContent="center"
        className={classes.bar}
        direction="row"
        justifyContent="space-between"
      >
        <Button
          className={classes.toggleButton}
          color="primary"
          endIcon={summaryVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          size="small"
          startIcon={<ShoppingCartIcon />}
          variant="text"
          onClick={() => toggleSummary()}
        >
          {buttonText}
        </Button>
        <Typography className={classes.orderTotal} variant="subtitle1">
          {total}
        </Typography>
      </Grid>
      <Box className={classes.summary} hidden={!summaryVisible}>
        <OrderSummary
          orderItems={order.orderItems}
          shippingAmount={order.shippingAmount}
          subtotal={order.orderSubtotal}
          taxAmount={order.taxAmount}
          total={order.orderTotal}
        />
      </Box>
    </Box>
  )
}

const displayTotal = (value: string | null) => {
  if (!value) {
    return ''
  }
  const floatValue = parseFloat(value)
  return formatAsUSD(floatValue)
}

export default OrderSummaryBar
