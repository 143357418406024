import * as React from 'react'
import { Typography } from '@material-ui/core'
import useStyles from './CountIndicatorBadge.styles'

export interface CountIndicatorBadgeProps {
  children: React.ReactNode
}

const CountIndicatorBadge = ({ children }: CountIndicatorBadgeProps) => {
  const classes = useStyles()

  return (
    <Typography className={classes.root} component="span" variant="body2">
      {children}
    </Typography>
  )
}

export default CountIndicatorBadge
