import { GridSize } from '@material-ui/core/Grid/Grid'
import { InputSetting } from '../input-config'

export function gridColSizeFor(inputSetting: InputSetting): GridSize {
  if (inputSetting.name === 'firstName' || inputSetting.name === 'lastName') {
    return 6
  }

  return 12
}
