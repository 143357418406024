import { useState } from 'react'

export interface ManagedState<T> {
  clear: () => void
  overwrite: (value: T) => void
  reset: () => void
  revert: () => void
  save: () => void
  update: (value: T) => void
  value: T
}

export const useStateManager = <T>(initialValue: T) => {
  const [snapshot, setSnapshot] = useState<T>(initialValue)
  const [current, setCurrent] = useState<T>(initialValue)

  const revert = () => {
    setCurrent(snapshot)
  }

  const save = () => {
    setSnapshot(current)
  }

  const clear = () => {
    setCurrent(initialValue)
  }

  const overwrite = (value: T) => {
    setCurrent(value)
    setSnapshot(value)
  }

  const reset = () => {
    setCurrent(initialValue)
    setSnapshot(initialValue)
  }

  return {
    clear,
    overwrite,
    reset,
    revert,
    save,
    update: setCurrent,
    value: current,
  } as ManagedState<T>
}
