export const BackIcon = () => (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 13 13"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.62082 6.5C4.62082 6.58347 4.65329 6.66288 4.70991 6.71785L9.36637 11.239C9.52901 11.3969 9.54549 11.6712 9.40318 11.8517C9.26087 12.0322 9.01366 12.0505 8.85102 11.8926L3.63363 6.82678C3.54871 6.74432 3.5 6.62521 3.5 6.5C3.5 6.37479 3.54871 6.25567 3.63363 6.17322L8.85102 1.10744C9.01366 0.949525 9.26087 0.967813 9.40318 1.14829C9.54549 1.32876 9.52901 1.60308 9.36637 1.76099L4.70991 6.28215C4.65329 6.33712 4.62082 6.41653 4.62082 6.5Z"
      fill="#333333"
      fillRule="evenodd"
    />
    <path
      d="M3.28533 7.18539L8.50271 12.2512C8.88348 12.6209 9.47464 12.5685 9.7958 12.1612C10.0933 11.7839 10.0651 11.2205 9.71467 10.8802L5.2033 6.49989L9.71467 2.11961C10.0651 1.77932 10.0933 1.21588 9.7958 0.838581C9.47464 0.431294 8.88348 0.3789 8.50271 0.748604L3.28533 5.81439C3.09734 5.99691 3 6.24824 3 6.49989C3 6.75154 3.09735 7.00288 3.28533 7.18539Z"
      fill="#6E3264"
    />
  </svg>
)
