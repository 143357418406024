import { useState } from 'react'
import * as React from 'react'
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from 'baby-ui/foundation/Button'
import { useMutation } from '@tanstack/react-query'
import createMemberCard from '../../../requests/createMemberCard'
import useStyles from './styles'
import { apiErrorResponseAdapter } from '../../../utils'
import { Error } from '../../../utils/apiErrorResponseAdapter'
import { SuccessIcon } from './icons'
import { formatStatusText } from './MemberCard.utils'

export enum MemberCardTag {
  Front = 'front',
  Back = 'back',
}

interface MemberCardProps {
  orderId: string
  tag: MemberCardTag
  memberCard: APIResponse.DME.MemberCard | null
}

const cardLabelMap: {
  [cardTag in MemberCardProps['tag']]: string
} = {
  front: 'Front of card',
  back: 'Back of card',
}

const MemberCard = ({ orderId, tag, memberCard }: MemberCardProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ tag })

  const [isDraggedState, setIsDraggedState] = useState(false)
  const [wasSuccess, setWasSuccess] = useState(true)
  const [fileName, setFileName] = React.useState(memberCard?.fileName || '')
  const [statusText, setStatusText] = useState(
    memberCard ? formatStatusText(memberCard) : ''
  )

  const isDragged = () => {
    setIsDraggedState(true)
  }

  const leftDragged = () => {
    setIsDraggedState(false)
  }

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusText('Uploading...')
    const reader = new FileReader()
    if (event.target.files) {
      const image = event.target.files[0]

      reader.onloadend = (readerEvent) => {
        if (readerEvent?.target?.result) {
          setFileName(
            image.name.length > 20 ? `...${image.name.slice(-20)}` : image.name
          )
          createMemberCardMutation({
            orderId,
            name: image.name,
            tag,
            memberCardFile: readerEvent.target.result,
          })
        }
      }

      if (image instanceof Blob) {
        reader.readAsDataURL(image)
      }
    } else {
      setWasSuccess(false)
      setStatusText('Upload failed.')
    }
  }

  const cardUploadSucceeded = (card: APIResponse.DME.MemberCard) => {
    setWasSuccess(true)
    setStatusText(formatStatusText(card))
  }

  const cardUploadFailed = (e: Error) => {
    const formattedError = apiErrorResponseAdapter(e as Error)
    setWasSuccess(false)
    setStatusText(formattedError.message || 'Upload failed.')
  }

  const { mutate: createMemberCardMutation, isLoading } = useMutation({
    mutationFn: createMemberCard,
    onSuccess: cardUploadSucceeded,
    onError: cardUploadFailed,
  })

  const cardIsLoadingOrSubmitted = isLoading || fileName.length > 0
  const selectText = `Select a ${
    wasSuccess && fileName.length > 0 && !isLoading ? 'replacement' : 'file'
  }`
  const draggedClass = cardIsLoadingOrSubmitted
    ? classes.replaceCardDragState
    : classes.fileSelectDragState

  return (
    <Box className={classes.root}>
      <Typography className={classes.cardLabel}>{cardLabelMap[tag]}</Typography>
      <Button
        className={`${
          cardIsLoadingOrSubmitted ? classes.replaceCard : classes.fileSelect
        } ${isDraggedState ? draggedClass : undefined}`}
        onDragEnter={isDragged}
        onDragLeave={leftDragged}
        onDrop={leftDragged}
      >
        <span className={classes.cardCaption}>
          <Typography
            color="primary"
            component="p"
            style={{ fontWeight: 'bold', lineHeight: '16px' }}
            variant="caption"
          >
            {selectText}
          </Typography>
          {!smallScreen && (
            <Typography
              color="textSecondary"
              component="p"
              style={{ lineHeight: '16px' }}
              variant="caption"
            >
              or drag it here
            </Typography>
          )}
        </span>
        <input
          accept=".png, .jpg, .jpeg, .pdf, .heic, .heif"
          className={classes.fileInput}
          id={`${tag}FileUploadInput`}
          data-testid={`${tag}FileUploadInput`}
          type="file"
          onChange={handleSubmit}
        />
      </Button>

      <div hidden={!cardIsLoadingOrSubmitted}>
        <Box
          className={
            wasSuccess || isLoading ? classes.status : classes.statusFailed
          }
        >
          <div className={classes.statusDiv}>
            <Typography className={classes.fileName} component="span">
              {fileName}
            </Typography>
            <Typography className={classes.statusText} component="span">
              {statusText}
            </Typography>
          </div>
          <div className={classes.loadingIconDiv}>
            {isLoading && (
              <CircularProgress size={19} style={{ margin: 'auto' }} />
            )}
            {!isLoading && wasSuccess && (
              <SuccessIcon style={{ margin: 'auto' }} />
            )}
          </div>
        </Box>
      </div>
    </Box>
  )
}

export default MemberCard
