import * as React from 'react'
import { Box, useTheme } from '@material-ui/core'

interface Props {
  children: React.ReactNode
  testId?: string
}

export const Card: React.FC<Props> = ({ testId = 'card', children }: Props) => {
  const theme = useTheme()
  const borderColor = theme.palette.grey[300]

  return (
    <div data-testid={testId}>
      <Box border={`1px solid ${borderColor}`} borderRadius={4} mb={2} p={2}>
        {children}
      </Box>
    </div>
  )
}
