import * as yup from 'yup'
import { getAvailabilityErrorMessage } from './ReplacementPartsForm.utils'

export const ReplacementPartsFormSchema = yup.object().shape({
  productId: yup
    .number()
    .required('Select your flange size.')
    .test((value, ctx) => {
      const availabilityMessage = getAvailabilityErrorMessage(
        value,
        ctx.options.context?.productOptions || []
      )
      if (availabilityMessage) {
        return ctx.createError({ message: availabilityMessage })
      }
      return true
    }),
  aobAcknowledged: yup
    .boolean()
    .oneOf([true], 'This acknowledgment is required'),
})
