import { useEffect, useMemo, useState } from 'react'
import { ProductFilter } from 'baby-ui/lib/ProductFilter'
import ProductSorter from 'baby-ui/lib/ProductSorter'
import { SortOrder } from 'baby-ui/lib/SortOrder'
import { BRAND_INDEX, FEATURES_INDEX } from 'baby-ui/shared/constants'
import { FilterSettings } from '../../dme/utils/FilterSettings'
import ProductFilterSortCategory from '../../dme/utils/ProductFilterSortCategory'

type Product = APIResponse.HeroProduct

export interface ProductFilterControls {
  applyFilter: (matrix: string[][]) => void
  applySort: (criteria: string) => void
  allProducts: Product[]
}

export type ProductFilterHook = [Product[], ProductFilterControls]

interface ProductFilterHookOpts {
  onChange?: (settings: FilterSettings) => void
  initialSettings?: FilterSettings
}

const category = new ProductFilterSortCategory()

export const useProductFilter = (
  rawProducts: Product[],
  options?: ProductFilterHookOpts
) => {
  const { initialSettings, onChange } = options || {}

  const [products, setProducts] = useState(rawProducts)
  const filter = useMemo(
    () => new ProductFilter(rawProducts, [BRAND_INDEX, FEATURES_INDEX]),
    [rawProducts.length]
  )

  const [sortPath, setSortPath] = useState(
    initialSettings?.sort[0] || category.properties?.path
  )
  const [sortOrder, setSortOrder] = useState(
    initialSettings?.sort[1] || category.properties?.order
  )
  const [filterMatrix, setFilterMatrix] = useState<string[][]>(
    initialSettings?.filter || []
  )

  useEffect(() => {
    if (rawProducts.length) {
      const filteredProducts = getFilteredProducts(filterMatrix)
      const sortedProducts = getSortedProducts(
        filteredProducts,
        sortPath,
        sortOrder
      )
      setProducts(sortedProducts)
    }
  }, [
    JSON.stringify(filterMatrix),
    filter.products.length,
    sortOrder,
    sortPath,
  ])

  const getSortedProducts = (
    products: Product[],
    by: string,
    order: SortOrder
  ) => new ProductSorter(products).sort({ by, order })

  const getFilteredProducts = (filterMatrix: string[][]) => {
    const shouldFilter = filterMatrix && filterMatrix.length > 0
    return shouldFilter ? filter.intersection(filterMatrix) : filter.products
  }

  const applyFilter = (matrix: string[][]) => {
    setFilterMatrix(matrix)
  }

  const applySort = (criteria: string) => {
    const sortCategory = new ProductFilterSortCategory(criteria)
    setSortOrder(sortCategory.properties.order)
    setSortPath(sortCategory.properties.path)
  }

  useEffect(() => {
    onChange?.({
      filter: filterMatrix,
      sort: [sortPath, sortOrder],
    })
  }, [JSON.stringify(filterMatrix), sortOrder, sortPath])

  return [
    products,
    { allProducts: rawProducts, applyFilter, applySort },
  ] as ProductFilterHook
}
