import { Factory } from 'fishery'
import faker from 'faker'
import insuranceFactory from './insuranceFactory'

export default Factory.define<APIResponse.InsuranceCatalog>(({ sequence }) => ({
  id: sequence,
  stateName: faker.address.state(),
  stateAbbreviation: faker.address.stateAbbr(),
  enabled: true,
  insurances: insuranceFactory.buildList(3),
}))
