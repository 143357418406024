export const insuranceVerificationErrorTypeAdapter = (
  apiError?: APIResponse.StandardError
): APIResponse.DME.SubscriptionErrorType => {
  if (apiError?.error?.fields?.insuranceStatus === 'INACTIVE') {
    return 'INSURANCE_VERIFICATION_FAILED_INACTIVE'
  }

  if (apiError?.error?.fields?.insuranceStatus === 'INVALID') {
    return 'INSURANCE_VERIFICATION_FAILED_INVALID'
  }

  return 'INSURANCE_UNSUPPORTED'
}

export const getAvailabilityErrorMessage = (
  productId: number,
  productOptions: APIResponse.CatalogCategoryProduct[]
): null | string => {
  const product = productOptions.find((product) => product.id === productId)
  if (!product || product.availableOnBabylist) return null
  return product.availabilityErrorMessage ?? null
}
