import { ContentBlock, ContactLink } from '../components'

const PrescriptionProviderFaxMissing = () => (
  <ContentBlock title="Rx next steps" variant="warn">
    We're in the process of trying to locate your provider's fax number with the
    information you provided. If you know their fax number, please email us at{' '}
    <ContactLink variant="health" />. Please include your order number in your
    email.
  </ContentBlock>
)

export default PrescriptionProviderFaxMissing
