import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    /**
     * Because the "Maax" font gives the illusion that the text is 1px below the
     * center on the button element. We apply a margin to the bottom of the text
     * to make the button stretch by 1px which corrects the illusion.
     */
    textOverrides: { textTransform: 'none', marginBottom: 1 },
    root: {
      textTransform: 'none',
      borderRadius: theme.shape.buttonRadius,
      '&.MuiButton-containedSecondary.Mui-disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        opacity: 0.6,
      },
    },
  })
)
