import { UI } from '../../global'
import browserHistory from './browserHistory'

export default class ViewConfiguration {
  private readonly pathname: string

  constructor(settings: { pathname: string }) {
    this.pathname = settings.pathname
  }

  navigate(options?: UI.NavigationOptions) {
    const { withReactRouter = true, search = '' } = options || {}

    if (withReactRouter) {
      browserHistory.push(this.pathname + search)
    } else {
      window.location.assign(this.pathname + search)
    }
  }
}

export const configureView =
  <P = unknown>(component: UI.ViewComponent<P>) =>
  (options: { pathname: string }) => {
    const viewConfig = new ViewConfiguration(options)

    Object.assign(component, {
      navigate: (navOpts?: UI.NavigationOptions) =>
        viewConfig.navigate(navOpts),
    })

    return component as UI.PageComponent<P>
  }
