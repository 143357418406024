import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import MemberCard, { MemberCardTag } from './MemberCard'
import { ThemeConstants } from '../../../styles'

interface InsuranceCardsProps {
  order: APIResponse.DME.Order
}

const InsuranceCards = ({ order }: InsuranceCardsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      alignItems="center"
      direction={isMobile ? 'column' : 'row'}
      justifyContent="center"
      spacing={ThemeConstants.BASE_SPACING}
    >
      <Grid item>
        <MemberCard
          memberCard={order.memberCardFront}
          orderId={order.id}
          tag={MemberCardTag.Front}
        />
      </Grid>
      <Grid item>
        <MemberCard
          memberCard={order.memberCardBack}
          orderId={order.id}
          tag={MemberCardTag.Back}
        />
      </Grid>
    </Grid>
  )
}

export default InsuranceCards
