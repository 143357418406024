import {
  Rating as MuiRating,
  RatingProps as MuiRatingProps,
} from '@material-ui/lab'
import FavoriteIcon from '@material-ui/icons/Favorite'
import useStyles from './styles'

export type RatingProps = MuiRatingProps

export default function Rating(props: RatingProps) {
  const classes = useStyles()

  return (
    <MuiRating
      classes={{ root: classes.ratingRootColor }}
      defaultValue={0}
      icon={<FavoriteIcon fontSize="inherit" />}
      precision={0.5}
      readOnly
      {...props}
    />
  )
}
