import { Box, Link } from '@material-ui/core'
import { PRESCRIPTION_FORM } from '../../constants'
import { ContentBlock, ContactLink } from '../components'

const PrescriptionProviderMissing = () => (
  <ContentBlock title="Rx next steps" variant="warn">
    <Box mb={2}>
      We are missing your healthcare provider information. Please take our{' '}
      <Link download href={PRESCRIPTION_FORM} style={{ fontWeight: 'bold' }}>
        prescription form
      </Link>{' '}
      to an upcoming appointment. You can send the completed form to{' '}
      <ContactLink variant="rx" />.
    </Box>
    <span style={{ fontStyle: 'italic' }}>Helpful tip!</span> Make sure your
    provider fills in ALL fields on the form and dates it for quick approval.
  </ContentBlock>
)

export default PrescriptionProviderMissing
