import { InvalidPartsOrderCopyProps } from './types'
import { SimpleOrderBlocker } from './SimpleOrderBlocker'

export const InsurancePartsUnsupportedCopy = (
  _props: InvalidPartsOrderCopyProps
) => {
  return (
    <SimpleOrderBlocker
      title="We're sorry, we don't currently support replacement parts for your insurance."
      content="It's likely your insurance covers replacement parts! Please contact them to discuss your options."
    />
  )
}
