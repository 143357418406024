import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    root: {
      height: 20,
      minWidth: 20,
      borderRadius: 10,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'inline-flex',
      padding: '0 6px',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 0,
      paddingBottom: 2,
    },
  })
)
