import * as fns from 'date-fns'
import { MAXIMUM_PATIENT_AGE, MINIMUM_PATIENT_AGE } from 'shared/constants'

export const isBirthdayWithinRange = (birthday: string) => {
  const now = new Date()
  const sixteenYearsInThePast = fns.startOfDay(
    fns.sub(now, { years: MINIMUM_PATIENT_AGE })
  )
  const sixtyYearsInThePast = fns.startOfDay(
    fns.sub(now, { years: MAXIMUM_PATIENT_AGE })
  )
  const parsedBirthday = fns.parseISO(birthday)
  return fns.isWithinInterval(parsedBirthday, {
    start: sixtyYearsInThePast,
    end: sixteenYearsInThePast,
  })
}
