import { Link, Typography } from '@material-ui/core'
import useStyles from './styles'
import { InvalidPartsOrderCopyProps } from './types'
import { HEALTH_CONTACT_EMAIL } from '../../../constants'

export const OrderInIntervalCopy = ({
  subscription: { interval },
}: InvalidPartsOrderCopyProps) => {
  const classes = useStyles()
  return (
    <>
      <Typography
        className={classes.title}
        color="primary"
        component="h1"
        variant="h4"
      >
        Looks like you've placed a recent order for replacement parts. Insurance
        only covers free replacement parts every {interval} days.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        We will email you as soon as you are eligible for another set of
        replacement parts.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        Still have questions? Email{' '}
        <Link
          className={classes.emailLink}
          href={`mailto:${HEALTH_CONTACT_EMAIL}`}
        >
          {HEALTH_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  )
}
