import { Typography } from '@material-ui/core'
import { PRIVACY_URL } from '../../constants'

export default function TermsAndConditionsWidget() {
  return (
    <>
      <Typography gutterBottom>
        These Terms and conditions of use ("<strong>Terms</strong>") apply to
        the Site ("<strong>Site</strong>") and services provided by Baby List
        Inc. ("
        <strong>Babylist</strong>," "<strong>we</strong>," "<strong>us</strong>
        ," or "<strong>our</strong>") in connection with the offering and
        servicing of the durable medical equipment identified on the Site (the "
        <strong>Product(s)</strong>"), together with the Site and related
        services, the "Services"). Please read these Terms carefully. In
        addition to these Terms, Your access to and use of the Services are
        governed by the Babylist Privacy Policy, which can be found at{' '}
        <a href={PRIVACY_URL} target="_blank">
          {PRIVACY_URL}
        </a>{' '}
        (the "<strong>Privacy Policy</strong>"), and which is incorporated into
        these Terms by reference. Please read these Terms and the Privacy Policy
        carefully.
      </Typography>
      <Typography gutterBottom>
        These Terms govern Your use of the Services and constitute a binding
        agreement between You and Babylist, and are deemed accepted by You and
        Babylist. Your access to or use of the Services indicates Your
        acceptance to these Terms. If You do not accept these Terms, do not use
        the Services. Babylist reserves the right to revise these Terms at any
        time, at its sole discretion, by posting an updated version to the Site.
        You should visit this page periodically to review the most current
        Terms, because they are binding on You. By continuing to access or use
        the Services after updated terms have been posted, You agree to be bound
        by the updated Terms.
      </Typography>
      <Typography gutterBottom>
        Babylist reserves the right, without notice and in its sole discretion,
        to restrict, refuse or terminate your ability to use the Site and
        Services, and to limit, block or prevent access to and use of the Site
        and Services without any liability to You.
      </Typography>
      <Typography component="h3" variant="h6" gutterBottom>
        I. Eligibility and Account
      </Typography>
      <Typography gutterBottom>
        You certify that You are legally permitted to use and access the
        Services and take full responsibility for the selection and use of and
        access to the Services. You are responsible for ensuring that all
        persons who access the Site through Your internet connection are aware
        of these Terms and that they comply with them. This includes any person
        who is authorized to access Your account via Your login, and any
        additional logins associated with Your account. These Terms are void
        where prohibited by law, and the right to access the Services is revoked
        in such jurisdictions.
      </Typography>
      <Typography gutterBottom>
        You must be 18 years of age or older and have a valid credit card to
        purchase any Babylist Products or to create an account on the Site.
      </Typography>
      <Typography gutterBottom>
        Some Services require You to create an account to participate in such
        Service. By creating an account, You represent that (i) You are over the
        age of 18, (ii) You are responsible for maintaining the confidentiality
        of Your account and password, and (iii) You accept responsibility for
        all activities that occur under Your account, password and any logins
        associated with Your account. You hereby represent that all registration
        information You submit is accurate, current and complete and that You
        are solely responsible for the accuracy of such information. You agree
        to update Your account information to keep it current and accurate.
      </Typography>
      <Typography gutterBottom>
        You agree not to impersonate or misrepresent Your affiliation with any
        person or entity, including using another person's username, password or
        other account information, another person's name or likeness or provide
        false details, unless and only in the circumstance that You have been
        authorized to create a login associated with another User's account.
      </Typography>
      <Typography gutterBottom>
        Babylist reserves the right to accept or refuse Your application for an
        account (in our absolute and sole discretion).
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        II. Usage
      </Typography>
      <Typography gutterBottom>
        You agree and warrant that You will not use the Services in a manner
        that is prohibited, illegal or otherwise inconsistent with these Terms.
        In addition, You will not use the Services in a manner that Babylist
        deems, in its sole discretion, objectionable.
      </Typography>
      <Typography gutterBottom>
        You agree that You will access the Services only through the interfaces
        provided.
      </Typography>
      <Typography gutterBottom>
        You agree not to do any of the following:
      </Typography>
      <Typography gutterBottom>
        <ul>
          <li>
            attempt to decipher, decompile, disassemble or reverse engineer or
            otherwise hack the Site or any software, network or servers used to
            provide the Services, or to damage the Site, services or other
            property in any way;
          </li>
          <li>
            attempt to breach any security or authentication measures of the
            Site or the Services;
          </li>
          <li>
            avoid, bypass, remove, deactivate, impair, descramble or otherwise
            circumvent any technological measure implemented by Babylist or any
            other third party to protect the Services or Site Content (for
            purposes of these Terms, "<u>Content</u>" means all content
            contained in the Site, including all text, images, audio content,
            videos, designs, graphics, information, logos, downloadable content,
            software, and any other content contained therein and all related
            patents, copyrights, trademarks, service marks, intellectual
            property and/or other proprietary information of Babylist);
          </li>
          <li>
            use the Services, Your account, or communications with other Users
            for any commercial purpose or solicitation;
          </li>
          <li>
            interfere or attempt to interfere with other Users' use of the
            Services;
          </li>
          <li>impersonate or misrepresent Your affiliation with any person;</li>
          <li>violate any applicable law of regulation; or</li>
          <li>attempt, encourage or facilitate any of the above.</li>
        </ul>
      </Typography>
      <Typography gutterBottom>
        Babylist will have the right to investigate and prosecute violations of
        any of the above to the fullest extent of the law. Babylist may involve
        and cooperate with law enforcement authorities in prosecuting Users who
        violate these Terms. You acknowledge that Babylist has no obligation to
        monitor Your access to or use of the Services or the Content, but has
        the right to do so for the purpose of operating the Services, to ensure
        Your compliance with these Terms, or to comply with applicable law or
        the order or requirement of a court, administrative agency or other
        governmental body.
      </Typography>
      <Typography component="h2" variant="h6" gutterBottom>
        III. Purchases of Products on Site
      </Typography>
      <Typography gutterBottom>
        Customers who purchase Products through the Site must be over the age of
        18 with a valid credit card or other permitted payment method accepted
        by Babylist.
      </Typography>
      <Typography gutterBottom>
        <ol type="A">
          <li>
            <u>Availability</u>: We cannot guarantee availability of Products,
            even if we show that a particular Product is available online or in
            stores. We may not have all Products
          </li>
          <li>
            <u>Pricing</u>: Prices for Products may change at any time and
            Babylist does not provide price protection or refunds in the event
            of a price reduction or promotional offering unless specifically
            stated in the terms and conditions of that promotional offering.
            Babylist reserves the right to cancel an order for a Product in the
            event of a typographical or other error in the price listed for the
            Product. The price of a Babylist Product generally does not include
            delivery charges. We will charge taxes on Products purchased when
            taxes are applicable. The list price displayed for any Product
            generally does not include delivery charges or sales or other taxes
            unless specifically noted.
          </li>
          <li>
            <u>Delivery</u>: Babylist shall deliver the Products that You
            purchase to the place of delivery designated by You. If You choose
            to accept any type of express courier services for shipping, then
            You shall pay any additional shipping fees.
          </li>
          <li>
            <u>Transactions</u>: We reserve the right to refuse any order You
            place with us. We may, in Our sole discretion, limit or cancel
            quantities purchased per person, per household or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing and/or shipping address. We reserve the right to limit or
            prohibit orders that, in Our sole discretion, appear to be placed by
            dealers, resellers, or distributors.
          </li>
          <li>
            <u>Risk of Loss</u>: All items purchased from Babylist are made
            pursuant to a shipment contract. This means that title and the risk
            of loss for such items pass to You upon Our delivery to the carrier.
          </li>
          <li>
            <u>Warranty Information</u>: Warranty information for Products can
            be found in the warranty provided by the third-party manufacturer,
            if any, included with Your Product.
          </li>
          <li>
            <u>
              Payment Card Industry Data Security Standard and Related Standards
              and Regulations
            </u>
            : You acknowledge that Babylist has no control over its partners,
            merchants, and any third party sites which appear or are linked to
            from the personal registries on the Site. Babylist has no knowledge
            and makes no representation, warranty, or guarantee that such
            partners, merchants, or third parties are compliant with the Payment
            Card Industry Data Security Standard and related standards and
            regulations.
          </li>
          <li>
            <u>Currency</u>. We are based in the United States and operate with
            US dollars as Our primary currency. We issue payments in US dollars.
            If You are purchasing Products with a non-US credit card, You may be
            subject to currency exchange fees levied by Your credit card
            company. Any concerns about exchange fees and rates should be
            directed to Your credit card company or bank.
          </li>
          <li>
            <u>Taxation Consequences</u>. You acknowledge that We make no claims
            or promises regarding any and all tax obligations or consequences
            that may result from Your activities on the Site or use of the
            Services. You shall have full responsibility for applicable taxes
            for all gift funds paid to or received by You. You agree to
            indemnify, defend and hold Us harmless from any liability for, or
            assessment of, any claims or penalties with respect to such taxes,
            including any liability for, or assessment of, withholding taxes
            imposed on Us by the relevant taxing authorities with respect to any
            funds paid to You.
          </li>
          <li>
            <u>Payments</u>. We use Stripe as our payments processor. Sending or
            receiving payments for transactions made with or through the Site or
            Services is subject to each payment processor's Terms of Service
            (the "
            <a href="https://stripe.com/legal/ssa">Stripe Terms of Service</a>
            "). By agreeing to these terms, continuing to use the Services or
            purchasing Babylist Products, You agree to the additional terms set
            forth in the selected payment processor's Terms of Service, as the
            same may be modified by Stripe from time to time. As a condition of
            Babylist enabling payment processing services through Stripe, You
            agree to provide Us accurate and complete information about You and
            Your business, and You authorize Us to share such information and
            transaction information related to Your use of the payment
            processing services provided by Stripe.
          </li>
          <li>
            <u>Purchaser Representations</u>. By ordering a Product on the Site,
            you, as the purchaser:
            <ol>
              <li>
                Authorize SourceMD LLC and the Babylist Preferred Durable
                Medical Equipment Network (herein referred to as "
                <strong>Provider</strong>") to provide the purchased equipment
                and understand that Provider is an independent company and not
                part of any other medical practice, hospital or any other
                company.
              </li>
              <li>
                Certify that the information provided by you in applying for
                payment under title XVII (Medicare) of the Social Security Act
                or any other insurance benefits is true and correct.
              </li>
              <li>
                Are personally responsible to SourceMD LLC, and the Babylist
                Preferred Durable Medical Equipment Network, for charges not
                paid in full by insurance coverage, deductible or co-pay
                responsibilities or, in the absence of insurance coverage, the
                full balance. The portions of any bill for which you are
                responsible are due upon invoice receipt from SourceMD.
              </li>
              <li>
                Understand that you are authorizing SourceMD and the Babylist
                Preferred DME Network to provide breast pumps, supplies, and
                accessories to you.
              </li>
              <li>
                Understand that you are giving SourceMD permission to ask your
                insurance for payments for my medical care, including supplies
                and equipment.
              </li>
              <li>
                Certify that the information provided by you in applying for
                payment under title XVIII (Medicare) of the Social Security Act
                or any other insurance benefits is true and correct.
              </li>
              <li>
                Understand that Provider may need information about your medical
                condition to determine benefits related to Provider services,
                and thus you give permission for the release of medical or other
                information necessary to process the Provider payment request.
              </li>
              <li>
                Ask that payment of authorized Health Care Benefits be made on
                your behalf to SourceMD for any services or items furnished to
                you.
              </li>
              <li>
                Understand that if your insurance denies or challenges a claim
                for the prescribed supplies, you will be solely responsible for
                the claim.
              </li>
              <li>
                Understand and agree that Medicaid, Medicare or any other health
                insurance company will only pay for equipment and services that
                it determines to be "reasonable and necessary" (Section
                1862(a)(I) of the Medicare law for Medicare). If your insurance
                determines that a particular service or piece of equipment is
                not "reasonable and necessary" under your insurance standards,
                your insurance company may deny payment for it. In the event
                they do deny payment, you will be responsible for the reasonable
                and customary cost of the equipment or service.
              </li>
              <li>
                Certify that you HAVE NOT RENTED SAME OR SIMILAR EQUIPMENT
                through Medicaid, Medicare or any other insurance, or if SAME OR
                SIMILAR equipment was rented, the equipment has been returned to
                the DME supplier. You also certify that Medicare is not paying
                service or maintenance charges for SAME OR SIMILAR equipment you
                use.
              </li>
              <li>
                Certify that you HAVE NOT PURCHASED SAME OR SIMILAR equipment
                through Medicare, Medicaid or any other Insurance, or if you
                have purchased any equipment, documentation has been provided to
                SourceMD.
              </li>
              <li>
                Will be solely responsible for this claim if your insurance
                denies payment for any reason other than the above stated
                reason.
              </li>
              <li>
                Will cover any and all additional charges involved in having the
                equipment returned.
              </li>
              <li>
                Agree that SourceMD reserves the right to have this agreement
                transferred over to another Medicare Supply or Pharmacy provider
                to provide the service. You understand that any annual
                deductible or co-payments from your insurance are your
                responsibility unless covered by a secondary insurance policy.
              </li>
            </ol>
          </li>
        </ol>
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        IV. Intellectual Property
      </Typography>
      <Typography gutterBottom>
        The Services are protected under patent, copyright, trademark, and/or
        other laws. Subject to Your compliance with the Terms, Babylist and/or
        its licensors provide You with a limited, non exclusive,
        non-transferable, non-sublicensable license to access and make personal
        and non commercial use of the Services. The Services and all Content,
        including, without limitation, any User Content, remain the property of
        Babylist or its licensors and We do not transfer title to any portion of
        the Services to You. The compilation, collection, arrangement, or
        assembly of all Content is the exclusive property of Babylist and
        protected as well. Any code or software code that Babylist creates,
        generates or displays in the Services is also protected and You may not
        copy or adapt such code.
      </Typography>
      <Typography gutterBottom>
        Unauthorized use of the Services may violate applicable laws and is
        strictly prohibited. You may not copy the Services or any part thereof
        without Babylist's prior written authorization. Any code or software
        code that Babylist creates to generate or display the Services are also
        protected by Babylist's copyrights, and You may not copy or adapt such
        code.
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        V. Limited Warranty
      </Typography>
      <Typography gutterBottom>
        To the fullest extent possible by law, Babylist makes no representations
        or warranties that the services or the Products will operate error-free,
        be available, or that the services and Babylist's servers are free of
        computer viruses or other harmful mechanisms. If your use of the
        services results in the need for servicing or replacing equipment or
        data or any other costs, Babylist is not responsible for those costs.
        Except for any limited warranties provided by third-party manufacturers
        that are included with your Babylist products at the time of purchase,
        the services are provided "as is" without any warranties of any kind and
        Babylist, to the fullest extent permitted by law, disclaims all
        warranties, whether express or implied, including the warranty of
        merchantability, fitness for particular purpose, and non-infringement.
        Babylist makes no warranties about the accuracy, reliability,
        completeness, or timeliness of the content in the services. Babylist
        makes no warranty with regard to any of the Babylist products.
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        VI. Limitation of Liability
      </Typography>
      <Typography gutterBottom>
        You acknowledge and agree that, to the fullest extent permitted by law,
        the entire risk of using the Services and Products remains with you and
        in no event shall Babylist be liable for any damages whatsoever
        (including, without limitation, direct, indirect, special, incidental
        and consequential damages, lost profits, or damages resulting from lost
        data, lost profits, lost employment opportunity or business
        interruption, or for any damages to property of any kind or for personal
        or bodily injury or emotional distress) resulting from the use or
        inability to use the Services or Products, whether based on contract,
        warranty, tort (including negligence), or any other legal theory, and
        whether or not Babylist is advised of the possibility of such damages,
        even if a limited remedy set forth herein is found to have failed its
        essential purpose.
      </Typography>
      <Typography gutterBottom>
        In no event will Babylist's aggregate liability arising out of or in
        connection with these Terms or from the use of or inability to use the
        Services or Products exceed the amounts you have actually paid to
        Babylist for use of the Services and the Products. The limitations of
        damages set forth above are fundamental elements of the basis of the
        bargain between Babylist and you. Some jurisdictions do not allow the
        exclusion or limitation of liability for consequential or incidental
        damages, so the above limitation may not apply to you.
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        VII. Indemnity
      </Typography>
      <Typography gutterBottom>
        You agree to defend, indemnify, and hold harmless Babylist, its parent,
        subsidiaries, affiliates, and their respective officers, directors,
        successors, subsidiaries, contractors, technology providers,
        advertisers, employees and agents, from and against any claims, actions,
        losses, costs, liability or demands, including without limitation
        reasonable legal and accounting fees, alleging or resulting from (i)
        Your use of the Services, (ii) Your breach of these Terms, including
        Your representations and warranties herein or (iii) Your violation of
        any applicable laws. Babylist reserves the right, at Your expense, to
        assume the exclusive defense and control of any matter for which You are
        required to indemnify Us and You agree to cooperate with Our defense of
        these claims. You agree not to settle any matter without the prior
        written consent of Babylist. Babylist will use reasonable efforts to
        notify You of any such claim, action or proceeding upon becoming aware
        of it.
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        VIII. Term and Termination
      </Typography>
      <Typography gutterBottom>
        These Terms will remain in full force and effect while You are a User of
        the Services. Babylist reserves the right, at its sole discretion, to
        pursue all of its legal remedies, including but not limited to
        suspending, disabling or terminating Your account and/or Your access to
        and ability to use the Services and/or any other services provided to
        You by Babylist, at any time for any or no reason and without any
        notice. Even after You are no longer a User of the Services, the
        provisions of these Terms will remain in effect.
      </Typography>
      <Typography gutterBottom>
        You may terminate this Agreement at any time by ceasing all use of the
        Services. All sections which by their nature should survive the
        expiration or termination of this Agreement shall continue in full force
        and effect subsequent to and notwithstanding the expiration or
        termination of this Agreement.
      </Typography>

      <Typography component="h2" variant="h6" gutterBottom>
        IX. Miscellaneous
      </Typography>
      <Typography gutterBottom>
        <ol type="A">
          <li>
            <u>Privacy</u>: See the{' '}
            <a href={PRIVACY_URL} target="_blank">
              Privacy Policy
            </a>{' '}
            for information and notices concerning Babylist's collection and use
            of Your personal information.
          </li>
          <li>
            <u>Commercial Use</u>: Any and all commercial use of the Services is
            expressly prohibited without the written consent of Babylist. This
            includes but is not limited to collecting names, information,
            contacting Users, lead generation, and all other commercial
            activities.
          </li>
          <li>
            <u>Third-Party Links</u>: The Services may contain links to
            third-party Sites. These links are provided solely as a convenience
            and not as an endorsement by Babylist of the contents on such
            third-party Sites. Babylist is not responsible for the content of
            linked third-party Sites and does not make any representations
            regarding the content or accuracy of materials on such third-party
            Sites. If You decide to access linked third-party Sites, You do so
            at Your own risk.
          </li>
          <li>
            <u>Access Outside the United States</u>: Babylist makes no claims
            that the Services may be lawfully viewed, downloaded, or accessed
            outside of the United States. Access to the Services may not be
            legal by certain persons or in certain countries. If You access the
            Services You do so at Your own risk and are responsible for
            compliance with the laws of Your jurisdiction. When You access the
            Services You acknowledge and agree that information You provide may
            be transferred to a jurisdiction other than Your country of
            residence and that the laws, including what is determined to be
            "personal data and/or information," are different and may be less
            protective than those applicable to You in Your country of
            residence. By accepting these Terms You acknowledge, agree and
            consent to (1) the transfer to and processing of personal
            information on servers located outside of the country where You
            reside, (2) BabyList's collection and use of Your personal
            information as described in Our Privacy Policy found here and in
            accordance with the laws of the United States that may be different
            and may be less protective than those in Your country and (3) that
            You are taking a risk by using the Site and Services.
          </li>
          <li>
            <u>Governing Law</u>: These Terms are governed by the internal
            substantive laws of the State of California. Jurisdiction for any
            claims arising under this agreement shall lie exclusively with the
            state or federal courts within San Francisco, California. If any
            provision of these Terms is found to be invalid by any court having
            competent jurisdiction, the invalidity of such provision shall not
            affect the validity of the remaining provisions of these Terms,
            which shall remain in full force and effect. No waiver of any term
            of these Terms shall be deemed a further or continuing waiver of
            such term or any other term. In addition, Babylist's failure to
            enforce any term of these Terms shall not be deemed as a waiver of
            such term or otherwise affect Babylist's ability to enforce such
            term at any point in the future.
          </li>
          <li>
            <u>Dispute Resolution</u>: In the event a dispute arises between You
            and Babylist, please contact Babylist. Any dispute arising from or
            relating to the subject matter of these Terms shall be finally
            settled by arbitration in San Francisco, California, using the
            English language in accordance with the Arbitration Rules and
            Procedures of Judicial Arbitration and Mediation Services, Inc.
            ("JAMS") then in effect, by one commercial arbitrator with
            substantial experience in resolving intellectual property and
            commercial contract disputes, who shall be selected from the
            appropriate list of JAMS arbitrators in accordance with the
            Arbitration Rules and Procedures of JAMS. The prevailing party in
            any arbitration or other proceeding arising under this Agreement
            shall be entitled to receive reimbursement of its reasonable
            expenses (including reasonable attorneys' fees, expert witness fees
            and all other expenses) incurred in connection therewith. Judgment
            upon the award so rendered may be entered in a court having
            jurisdiction or application may be made to such court for judicial
            acceptance of any award and an order of enforcement, as the case may
            be. Notwithstanding the foregoing, each party shall have the right
            to institute an action in a court of proper jurisdiction for
            injunctive or other equitable relief pending a final decision by the
            arbitrator. You and Babylist agree that any cause of action arising
            out of or related to the Site or the Services or these Terms must
            commence within one (1) year after the cause of action arose;
            otherwise, such cause of action is permanently barred.
          </li>
          <li>
            RIGHT TO OPT OUT OF ARBITRATION WAIVER. You may opt out of the
            foregoing arbitration provision of these Terms by{' '}
            <strong>
              notifying Babylist in writing within 30 days of the date You first
              registered for the Services or 30 days from the date these Terms
              were last updated
            </strong>
            . To opt out, You must send a written notification to Babylist at
            Babylist, Inc., 1900 Powell St, Suite 150, Emeryville, CA 94608,
            Attention: Legal, that includes (i) Your name, account username,
            address, telephone number and email address, and (ii) a clear
            statement indicating that You do not wish to resolve claims through
            arbitration and demonstrating compliance with the 30-day time limit
            to opt out of the above arbitration provision.
          </li>
          <li>
            <u>Additional Terms</u>: If You purchase and/or use any mobile
            applications provided by Babylist or any Babylist Product,
            additional terms apply, in addition to these Terms.
          </li>
          <li>
            <u>Waiver</u>: No waiver of any term of these Terms shall be deemed
            a further or continuing waiver of such term or any other term. In
            addition, Babylist's failure to enforce any term of these Terms
            shall not be deemed as a waiver of such term or otherwise affect
            Babylist's ability to enforce such term at any point in the future.
          </li>
          <li>
            <u>Assignment</u>: You may not assign or transfer these Terms, by
            operation of law or otherwise, without Babylist's prior written
            consent. Any attempt by You to assign or transfer these Terms
            without such consent will be null and of no effect. Babylist may
            assign or transfer these Terms, in its sole discretion, without
            restriction. Subject to the foregoing, these Terms will bind and
            inure to the benefit of the parties, their successors and permitted
            assigns.
          </li>
          <li>
            <u>Headings</u>: Headings provided in these Terms are included for
            reference purposes only and are not to be given any substantive
            effect.
          </li>
        </ol>
      </Typography>

      <Typography gutterBottom>
        Contact Information: If there are any questions regarding Babylist or
        these Terms, please contact Babylist at:
      </Typography>
      <Typography gutterBottom>
        Babylist, Inc.
        <br />
        Copyright Agent
        <br />
        1900 Powell St, Suite 150
        <br />
        Emeryville, CA 94608
        <br />
        Email: <a href="mailto:support@babylist.com">support@babylist.com</a>
      </Typography>
      <Typography gutterBottom>© Babylist, Inc. 2022.</Typography>
    </>
  )
}
