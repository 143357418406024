import * as React from 'react'
import { Box } from '@material-ui/core'

export interface CheckoutJourneyWrapperWidgetProps {
  children: React.ReactNode
}

const CheckoutJourneyWrapperWidget = ({
  children,
}: CheckoutJourneyWrapperWidgetProps) => (
  <Box m="auto" maxWidth={600} pb={10}>
    {children}
  </Box>
)

export default CheckoutJourneyWrapperWidget
