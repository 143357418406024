import { Fragment } from 'react'
import classnames from 'classnames'
import {
  Box,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { startCase } from 'lodash'
import { useScoredAttributes } from 'baby-ui/hooks/useScoredAttributes'
import { useTracking } from 'lib/babylistHealthAnalytics'
import Button from 'baby-ui/foundation/Button'
import useStyles from './EnhancedProductCard.styles'
import { normalizeScore } from './ScoredAttributes.utils'
import HoverDescription from './HoverDescription'
import { interactedWithScoredAttributeEvent } from '../../../dme/events/interactedWithScoredAttributeEvent'

interface ScoredAttributeProps {
  scoredAttributes: APIResponse.ScoredAttribute[]
  productId: number
  displayFeatureIndicator?: boolean
  onFeatureIndicatorClose?: () => void
}

export const ScoredAttributes = ({
  scoredAttributes,
  productId,
  displayFeatureIndicator = false,
  onFeatureIndicatorClose,
}: ScoredAttributeProps) => {
  const classes = useStyles()
  const { snackbar, openSnackbar, closeSnackbar } = useScoredAttributes()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const tracker = useTracking()

  const fireInteractedWithScoredAttributeEvent = (attribute: string) => {
    interactedWithScoredAttributeEvent(tracker)({ attribute })
  }

  return (
    <Box className={classes.scoredAttributes}>
      {scoredAttributes.map((attribute: APIResponse.ScoredAttribute, i) => {
        const { attributeName, description, score } = attribute
        const adjustedScore = normalizeScore(score)
        const key = `${productId}-${attributeName}`

        return (
          <Fragment key={key}>
            {isMobile && (
              <Snackbar
                autoHideDuration={10000}
                open={!!snackbar[key]}
                onClose={() => closeSnackbar(key)}
              >
                <Alert className={classes.alert} icon={false}>
                  <AlertTitle>{startCase(attributeName)}</AlertTitle>
                  {description}
                </Alert>
              </Snackbar>
            )}
            <Box
              alignItems="center"
              display="flex"
              mx={4}
              onClick={() => {
                openSnackbar(key)
                onFeatureIndicatorClose?.()
                fireInteractedWithScoredAttributeEvent(attributeName)
              }}
            >
              <HoverDescription
                description={description}
                onClose={() => {
                  fireInteractedWithScoredAttributeEvent(attributeName)
                }}
              >
                <Box
                  alignItems="center"
                  display="inline-flex"
                  minWidth={152}
                  width="100%"
                >
                  <Typography
                    className={classnames(
                      classes.attributeName,
                      classes.attributeText
                    )}
                    color="textSecondary"
                    component="span"
                    variant="caption"
                  >
                    {attributeName}
                  </Typography>
                  {isMobile ? (
                    <Tooltip
                      arrow
                      classes={{
                        tooltip: classes.featureIndicatorTooltip,
                        arrow: classes.featureIndicatorArrow,
                        popper: classes.featureIndicatorPopper,
                        tooltipPlacementTop: classes.featureIndicatorMargin,
                        tooltipPlacementBottom: classes.featureIndicatorMargin,
                      }}
                      open={i === 0 && displayFeatureIndicator}
                      placement="top"
                      title={
                        <div>
                          <Typography variant="body2">
                            <b>Discover more about this pump</b>
                          </Typography>
                          <Typography variant="caption">
                            Tap the info icon to learn if this pump will fit
                            your lifestyle and needs.
                          </Typography>
                          <Box display="flex" justifyContent="end" mt={1}>
                            <Button
                              classes={{
                                contained:
                                  classes.featureIndicatorDismissButton,
                              }}
                              size="small"
                              variant="contained"
                              onClick={(e) => {
                                // Prevents further propagation of the current event to the element behind it and bubbling up
                                e.stopPropagation()
                                onFeatureIndicatorClose?.()
                              }}
                            >
                              Done
                            </Button>
                          </Box>
                        </div>
                      }
                    >
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      fontSize="small"
                    />
                  )}
                </Box>
              </HoverDescription>
              <HoverDescription
                description={description}
                onClose={() => {
                  fireInteractedWithScoredAttributeEvent(attributeName)
                }}
              >
                <Box width="100%">
                  <LinearProgress
                    classes={{
                      root: classes.attributeMeter,
                      bar: classes.attributeMeterBar,
                    }}
                    value={adjustedScore}
                    variant="determinate"
                  />
                </Box>
              </HoverDescription>
            </Box>
          </Fragment>
        )
      })}
    </Box>
  )
}

export default ScoredAttributes
