import { BRAND_INDEX, FEATURES_INDEX } from 'baby-ui/shared/constants'
import { camelCase } from 'lodash'
import ProductFilterSortCategory from '../../utils/ProductFilterSortCategory'
import {
  FilterMatrix,
  FilterSettings,
  SortVector,
} from '../../utils/FilterSettings'

type Product = APIResponse.HeroProduct

interface MenuItemState {
  name: string
  label: string
  checked: boolean
}

export default class ProductFilterMenu {
  private products: Product[]

  constructor(products: Product[]) {
    this.products = products
  }

  checkedMenuItemsFrom(...menuState: MenuItemState[][]) {
    return menuState
      .map((menuItems) => menuItems.filter((menuItem) => menuItem.checked))
      .flat()
  }

  createStates(settings?: FilterSettings) {
    const sortCategory = new ProductFilterSortCategory()
    const { sort, filter } = settings || {
      filter: [],
      sort: [sortCategory.properties.path, sortCategory.properties.order],
    }
    const featuresLabels = this.labelsFor(FEATURES_INDEX)
    const { subtracted, remainder } = this.segment(featuresLabels, [
      'app',
      'bag included',
    ])
    const brandLabels = this.labelsFor(BRAND_INDEX)
    return {
      brand: this.createFilterMenuItemStates(brandLabels, filter),
      features: this.createFilterMenuItemStates(remainder, filter),
      included: this.createFilterMenuItemStates(subtracted, filter),
      sort: this.createSortMenuItemStates(sort),
    }
  }

  private createSortMenuItemStates(sortVector: SortVector) {
    return ProductFilterSortCategory.all.map((sortCategory) => {
      const menuItemState: MenuItemState = {
        name: camelCase(sortCategory.name),
        label: sortCategory.name,
        checked: false,
      }

      if (sortCategory.matches(sortVector)) {
        menuItemState.checked = true
      }

      return menuItemState
    })
  }

  private createFilterMenuItemStates(
    labels: string[],
    filterMatrix: FilterMatrix
  ) {
    return labels.map((label) => {
      const menuItemState: MenuItemState = {
        name: camelCase(label),
        label,
        checked: false,
      }

      if (filterMatrix.flat().includes(label)) {
        menuItemState.checked = true
      }

      return menuItemState
    })
  }

  private labelsFor(key: keyof Product) {
    const set = new Set(this.products.map((p) => p[key]).flat())
    return Array.from(set) as string[]
  }

  private segment(labels: string[], subtract: string[]) {
    const subtracted = [] as string[]
    const remainder = [] as string[]

    labels.forEach((label) => {
      const lowerCaseLabel = label.toLowerCase()
      if (subtract.includes(lowerCaseLabel)) {
        subtracted.push(label)
      } else {
        remainder.push(label)
      }
    })

    return { subtracted, remainder }
  }
}
