import * as React from 'react'
import { Container, Grid } from '@material-ui/core'
import { GridSize } from '@material-ui/core/Grid/Grid'
import { ComposableComponent } from 'baby-ui/types'
import { ThemeConstants } from '../../../dme/styles/theme'

type GridBreakpoint = boolean | GridSize

export interface DisplayGridProps<D> extends ComposableComponent<D> {
  breakpoints?: {
    lg?: GridBreakpoint
    md?: GridBreakpoint
    sm?: GridBreakpoint
    xl?: GridBreakpoint
    xs?: GridBreakpoint
  }
  containerProps?: {
    style?: React.CSSProperties
  }
  disableContainer?: boolean
  itemProps?: {
    style?: React.CSSProperties
  }
}

export default function DisplayGrid<P = any>({
  breakpoints = { xs: 6, md: 4, lg: 3 },
  component: Comp,
  containerProps,
  data,
  every,
  itemProps,
  disableContainer = false,
}: DisplayGridProps<P>) {
  // There is one limitation with the negative margin used to implement the
  // spacing between items. A horizontal scroll will appear if a negative
  // margin goes beyond the <body>
  // The workaround is to apply padding to the parent with at least half the
  // spacing value applied to the child. In this case `Container` takes care of that.
  // However there might certain cases where a Container is not necessary.
  const C = disableContainer ? React.Fragment : Container

  return (
    <C>
      <Grid container spacing={ThemeConstants.BASE_SPACING} {...containerProps}>
        {data.map((datum, i) => {
          const { key, ...localProps } = every(datum, i)

          return (
            <Grid item key={key} {...breakpoints} {...itemProps}>
              <Comp {...datum} {...localProps} />
            </Grid>
          )
        })}
      </Grid>
    </C>
  )
}
