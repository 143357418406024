import { FormHelperText, Grid, Link, Typography } from '@material-ui/core'
import { Control, Controller, FieldError } from 'react-hook-form'
import Checkbox from 'baby-ui/foundation/CheckBox'
import { useState } from 'react'
import { ReplacementPartsAobDialog } from 'bl-health/components/AobDialog'

interface AOBFieldProps {
  control: Control<{
    insuranceMemberId: string
  }>
  error?: FieldError | null
  onChange: (agreed: boolean) => void
}

const AOBField = ({ control, error, onChange }: AOBFieldProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ReplacementPartsAobDialog
        open={open}
        onAgree={() => {
          onChange(true)
          setOpen(false)
        }}
        onDisagree={() => {
          onChange(false)
          setOpen(false)
        }}
      />
      <Grid item xs={12}>
        <Controller
          required
          as={Checkbox}
          control={control}
          label={
            <Typography variant="body2">
              I agree to the{' '}
              {/* component=button means this is rendering a button, not an anchor */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                // Styling tweak to make the link vertically inline with the containing text.
                // TODO: Does this necessary tweak tell us something is amiss with default typography and/or link spacing?
                style={{ marginBottom: 2 }}
                type="button"
                underline="hover"
                onClick={() => {
                  setOpen(true)
                }}
              >
                Assignment of Benefits
              </Link>
              .
            </Typography>
          }
          name="aobAcknowledged"
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </Grid>
    </>
  )
}

export default AOBField
