import {
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { BreadcrumbConfig, useBreadcrumbs } from 'baby-ui/hooks'
import {
  CUSTOMER_INFORMATION_STEP,
  SEARCH_PHYSICIAN_STEP,
  PHYSICIAN_RESULT_STEP,
  PATIENT_TO_CHECKOUT_STEP,
} from '../../constants'

export interface CheckoutJourneyStepsWidgetProps {
  currentStep: string
}

const breadcrumbsConfig: BreadcrumbConfig[] = [
  {
    key: CUSTOMER_INFORMATION_STEP,
    displayName: 'Patient info',
  },
  {
    key: SEARCH_PHYSICIAN_STEP,
    displayName: 'Provider info',
  },
  {
    key: PHYSICIAN_RESULT_STEP,
    displayName: 'Select provider',
  },
  {
    key: PATIENT_TO_CHECKOUT_STEP,
    displayName: 'Check out',
  },
]

const CheckoutJourneyStepsWidget = ({
  currentStep,
}: CheckoutJourneyStepsWidgetProps) => {
  const theme = useTheme()
  const isMobilePortrait = useMediaQuery(theme.breakpoints.down('xs'))

  const breadcrumbs = useBreadcrumbs(breadcrumbsConfig, currentStep)

  return (
    <Stepper alternativeLabel={isMobilePortrait}>
      {breadcrumbs.map((breadcrumb) => (
        <Step
          active={breadcrumb.current}
          completed={breadcrumb.visited}
          key={breadcrumb.name}
        >
          <StepLabel
            style={{ textAlign: isMobilePortrait ? 'center' : undefined }}
          >
            {breadcrumb.name}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default CheckoutJourneyStepsWidget
