import InsurancePickerWidget from 'bl-health/widgets/InsurancePickerWidget'
import { Box, Container, Theme, useMediaQuery } from '@material-ui/core'
import { HeroCopy, HeroImage } from '../../components'
import useStyles from './styles'
import { ReplacementPartsLink } from './components'
import { PARTS_HERO_IMAGE_URL } from 'bl-health/views/constants'
import { useLayout } from 'bl-health/widgets/Layout'

export default function PartsHeroCTAWidget() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const layout = useLayout()
  const heroCopyProps = {
    title: layout?.milkBagsForPartsBuyers
      ? ['Get your FREE*', 'replacement parts and milk storage bags!']
      : ['Get your FREE pump', 'replacement parts!'],
    subtitle: [
      "We'll take care of contacting your provider",
      'and billing your insurance.',
    ],
  }

  const insurancePickerProps = {
    submitButtonText: layout?.milkBagsForPartsBuyers
      ? 'Get started'
      : 'Select my replacement parts',
  }

  return (
    <div>
      {isDesktop ? (
        <HeroImage heroImageUrl={PARTS_HERO_IMAGE_URL}>
          <Container className={classes.heroContainer}>
            <Box className={classes.overlay}>
              <Box className={classes.cta}>
                <Box>
                  <HeroCopy {...heroCopyProps} />
                </Box>
                <Box py={3}>
                  <InsurancePickerWidget {...insurancePickerProps} />
                </Box>
                <ReplacementPartsLink />
              </Box>
            </Box>
          </Container>
        </HeroImage>
      ) : (
        <Container className={classes.mobileFormContainer}>
          <Box pt={4}>
            <HeroCopy {...heroCopyProps} />
          </Box>
          <Box pt={3}>
            <InsurancePickerWidget {...insurancePickerProps} />
          </Box>
          <Box pt={3}>
            <ReplacementPartsLink />
          </Box>
        </Container>
      )}
    </div>
  )
}
