import { Link, Typography } from '@material-ui/core'
import useStyles from './styles'
import { InvalidPartsOrderCopyProps } from './types'
import { HEALTH_CONTACT_EMAIL } from '../../../constants'

export const SubscriptionEndedCopy = (_props: InvalidPartsOrderCopyProps) => {
  const classes = useStyles()
  return (
    <>
      <Typography
        className={classes.title}
        color="primary"
        component="h1"
        variant="h4"
      >
        This benefit has ended.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        We currently offer free replacement parts for up to one year after
        baby's arrival.
      </Typography>
      <Typography className={classes.body} color="primary" variant="body2">
        Still have questions or think there's been an error? Email{' '}
        <Link
          className={classes.emailLink}
          href={`mailto:${HEALTH_CONTACT_EMAIL}`}
        >
          {HEALTH_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  )
}
