import { Factory } from 'fishery'
import faker from 'faker'

export default Factory.define<APIResponse.CatalogCategoryProduct>(
  ({ sequence }) => {
    const imgUrl = faker.image.imageUrl()

    return {
      id: sequence,
      attributes: { color: faker.lorem.word() },
      availableOnBabylist: true,
      image: {
        thumbnail: imgUrl,
        url: imgUrl,
      },
      name: faker.commerce.productName(),
      price: faker.commerce.price(),
    }
  }
)
