import { Box, Link } from '@material-ui/core'
import { HOME_PATH } from '../../constants'

const LOGO_URL =
  '//res.cloudinary.com/babylist/image/upload/v1662486788/DME/logo_babylist-health.svg'

export default function Header() {
  return (
    <Box mx={{ xs: 2, md: 8 }} pb={{ xs: 2, md: 4.5 }} pt={{ xs: 2, md: 7 }}>
      <Link href={HOME_PATH}>
        <img alt="Babylist Health" src={LOGO_URL} />
      </Link>
    </Box>
  )
}
