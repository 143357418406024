import React from 'react'
import { Card } from '../components'
import {
  PrescriptionFaxing,
  PrescriptionProviderFaxMissing,
  PrescriptionProviderMissing,
} from 'bl-health/views/OrderView/cards/index'

interface PrescriptionNextStepsCardProps {
  order: APIResponse.DME.Order
}
const PrescriptionNextStepsCard: React.FC<PrescriptionNextStepsCardProps> = ({
  order,
}: PrescriptionNextStepsCardProps) => {
  const {
    prescriptionRequired,
    prescriptionMissing,
    providerMissing,
    providerFaxMissing,
  } = order

  const prescriptionCardContent = () => {
    if (prescriptionMissing && !providerMissing && providerFaxMissing) {
      return <PrescriptionProviderFaxMissing /> // We have the provider but no prescription and no fax
    } else if (prescriptionMissing && providerMissing) {
      return <PrescriptionProviderMissing /> // We have no provider and no prescription
    } else if (prescriptionMissing) {
      return <PrescriptionFaxing /> // We have no prescription
    }
    return null
  }

  if (!prescriptionRequired || prescriptionCardContent() === null) return null

  return (
    <Card testId="prescription-next-steps-card">
      {prescriptionCardContent()}
    </Card>
  )
}

export default PrescriptionNextStepsCard
