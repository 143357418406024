import { Card, ContentBlock } from '../components'

export default function ShippingUpdatesCard() {
  return (
    <Card>
      <ContentBlock title="Other updates">
        You'll get shipping and delivery updates by email.
      </ContentBlock>
    </Card>
  )
}
