import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(() =>
  createStyles({
    chevronIconVariantRoot: {
      display: 'inline-flex',
      alignItems: 'center',
    },
  })
)
